import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Stack } from '@mui/material';
import * as React from 'react';
import { BlueButton, GreyButton } from '../components/button';
import { LableText, NoteTextField } from '../components/const';
import { PrintReportButton } from './PrintReport1Type1';
import { PrintSingleReportButton } from './PrintReport1Type2';

export const AirType = { BOTH: '가압/감압', PRE: '가압', REDU: '감압' };
export const CheckWidth = { En: 320, BUILD: 575, SUB: 880, GOV: 1085 };

export function NoteComponent({ tab, type, setTab, step }) {
  // eslint-disable-next-line
  const [airType, setAirType] = React.useState(AirType.BOTH);
  const isEnv = tab === 'env';
  const isAir = tab === 'air';
  const isNormal = type === 'normal';
  return (
    <>
      <Stack sx={{ mt: 6, ml: 10, mr: 10 }}>
        <LableText sx={{ pb: 2 }}>기타 기록 사항</LableText>
        <NoteTextField placeholder=" - 확인 단계에서 기타 측정과 관련된 특이 사항 등을 기록" multiline rows={8}></NoteTextField>
      </Stack>

      {/* <Stack
        direction="row"
        justifyContent="center"
        spacing={2}
        sx={{ pt: 7, pb: 8 }}
      >
        <GreyButton
          variant="contained"
          startIcon={<ArrowBackIosIcon />}
          onClick={() => {
            if (isAir) {
              setTab('env');
            } else {
              window.location.pathname = `/main/${step - 1}/`;
              setTab('air');
            }
          }}
          disabled={step === 1 && isEnv}
        >
          이전으로
        </GreyButton>
        <BlueButton
          variant="contained"
          endIcon={<ArrowForwardIosIcon />}
          onClick={() => {
            if (isEnv) {
              setTab('air');
            } else {
              window.location.pathname = `/main/${step + 1}/`;
            }
          }}
          disabled={isNormal ? step === 6 && isAir : step === 5 && isAir}
        >
          다음으로
        </BlueButton>
        {airType === AirType.BOTH ? (
          <PrintReportButton />
        ) : (
          <PrintSingleReportButton />
        )}
      </Stack> */}
    </>
  );
}
