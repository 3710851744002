// step3 > tab2 Airtightness 의 계산식 - 이덕현
// 계산식을 다른페이지로 옮겨도 됨
// 입력값, is_required = true 인 데이터 또는 space.volume과 space.outside_area
// 계산값, is_required = false 인 데이터

// volume = current_space.volume
// Q50r = airtightnessTable.Rows['Q50r']
export const handleCalc1_ACH50 = (volume, Q50r) => {
    return Q50r / volume;
}

// outside_area = current_space.outside_area
// Q50r = airtightnessTable.Rows['Q50r']
export const handleCalc2_AirPermeability = (outside_area, Q50r) => {
    return Q50r / outside_area;
}

// n = airtightnessTable.Rows['n']
// c = airtightnessTable.Rows['c']
export const handleCalc3_ELA = (n, c) => {
    return 10000*((1/3600)*(c)*(Math.pow(0.6, 0.5))*(Math.pow(4, (n-0.5))));
}

// n = airtightnessTable.Rows['n']
// c = airtightnessTable.Rows['c']
export const handleCalc4_EqLA = (n, c) => {
    return (10000*((1/3600)*(c)*(Math.pow(0.6, 0.5))*(Math.pow(10, (n-0.5)))))/0.61
}

// n = airtightnessTable.Rows['n']
// c = airtightnessTable.Rows['c']
// outside_area = current_space.outside_area
export const handleCalc5_normalized_leakage = (n, c, outside_area) => {
    return handleCalc4_EqLA(n, c) / outside_area
}

// [가압/감압] toggle 버튼 눌렀을때만 평균계산 필요함
// pressure = 가압 값
// decomp = 감압 값
export const handleAverage = (pressure, decomp)=> {
    return (pressure + decomp) / 2;
}