import { TableBody, TableCell, TableHead, TableRow, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { BorderTable, environmentCondition, EnvironmentConditionHead } from '../components/const';
import { GetMeasureEnvCondition, GetCurrentBuilding, GetCurrentSpace, EditMeasureEnvCondition } from '../data/Data';

export function EnvironmentConditionTable(props) {
  const [envRows, setEnvRows] = React.useState([]);
  const [isUpdated, setIsUpdated] = React.useState(true);
  const [rowChanged, setRowChanged] = React.useState(false);

  const isNumber = (number) => {
    const regex = /^[+-]?\d*(\.?\d*)?$/;
    return number === '' || regex.test(number);
  };

  const handleValueChange = (e, row, isBefore) => {
    if (isNumber(e.target.value)) {
      envRows.find((r) => {
        if (r.label === row.label) {
          isBefore ? (r.value_before = e.target.value) : (r.value_after = e.target.value);
        }
      });
      setRowChanged(!rowChanged);
    }
  };

  const handleBlur = (e) => {
    var newRows = envRows;
    if (e.target.value === '' || e.target.value.slice(-1) === '.' || e.target.value.slice(0) === '-') {
      setEnvRows(newRows);
      setRowChanged(true);
    } else {
      setEnvRows(newRows);
      EditMeasureEnvCondition(newRows);
      setIsUpdated(!isUpdated);
    }
  };

  const calculateAverage = (row) => {
    var before = row.value_before;
    var after = row.value_after;

    if (before === '') {
      before = 0;
    }
    if (after === '') {
      after = 0;
    }

    if (row.name.includes('pressure')) {
      return ((parseFloat(before) + parseFloat(after)) / 2).toFixed(0).toLocaleString('ko-KR');
    } else {
      return ((parseFloat(before) + parseFloat(after)) / 2).toFixed(1).toLocaleString('ko-KR');
    }
  };

  // const calculateAverage = (before, after) => {
  //   if (before === "") {
  //     before = 0;
  //   }
  //   if (after === "") {
  //     after = 0;
  //   }
  //   return (parseFloat(before) + parseFloat(after)) / 2;
  // };

  const loadData = () => {
    GetCurrentBuilding(props.userId).then((currentBuilding) => {
      GetCurrentSpace(currentBuilding.data[0].seq).then((currentSpace) => {
        if (currentSpace.data.length > 0) {
          let buildingName = currentBuilding.data[0].name;
          let spaceDongName = currentSpace.data[0].name_dong;
          let spaceDongNumber = currentSpace.data[0].name_number;
          if (buildingName === null || buildingName === undefined || buildingName === '') {
            buildingName = '-';
          }
          if (spaceDongName === null || spaceDongName === undefined || spaceDongName === '') {
            spaceDongName = '-';
          }
          if (spaceDongNumber === null || spaceDongNumber === undefined || spaceDongNumber === '') {
            spaceDongNumber = '-';
          }

          props.setTitle(buildingName + ' ' + spaceDongName + ' ' + spaceDongNumber);
          GetMeasureEnvCondition(currentSpace.data[0].seq).then((response) => {
            setEnvRows(response.data);
          });
        }
      });
    });
  };

  React.useEffect(() => {
    if (isUpdated) {
      loadData();
      setIsUpdated(false);
    }
    if (rowChanged) {
      setRowChanged(false);
    }
  }, [isUpdated, rowChanged]);

  if (props.isResult) {
    return (
      <BorderTable>
        <TableHead>
          <TableRow>
            {EnvironmentConditionHead.map((it) => (
              <TableCell key={it.id} align={it.alignment} width={it.width}>
                {it.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {envRows.map((row, idx) => (
            <TableRow key={idx}>
              <TableCell style={{ paddingLeft: 30 }} align="left">
                {row.label}
              </TableCell>
              <TableCell align="center">{row.unit}</TableCell>
              <TableCell align="center" style={{ paddingRight: 0 }}>
                {row.value_before}
              </TableCell>
              <TableCell align="center" style={{ paddingRight: 0 }}>
                {row.value_after}
              </TableCell>
              <TableCell align="center" style={{ paddingRight: 0 }}>
                {calculateAverage(row)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </BorderTable>
    );
  } else if (props.isResult === false && props.state) {
    return (
      <BorderTable>
        <TableHead>
          <TableRow>
            {EnvironmentConditionHead.map((it) => (
              <TableCell key={it.id} align={it.alignment} width={it.width}>
                {it.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {envRows.map((row, idx) => (
            <TableRow key={idx}>
              {row.is_required ? (
                <TableCell align="left" style={{ paddingLeft: 16 }}>
                  <Stack direction="row" justifyContent="left" alignItems="center" spacing={1}>
                    <img src={require('../media/Vector.png')} style={{ width: 14, height: 10 }} alt="check img" />
                    <Typography>{row.label}</Typography>
                  </Stack>
                </TableCell>
              ) : (
                <TableCell align="left" style={{ paddingLeft: 40 }}>
                  {row.label}
                </TableCell>
              )}
              <TableCell align="center">{row.unit}</TableCell>

              <TableCell align="center" style={{ paddingRight: 0 }}>
                {row.value_before}
              </TableCell>
              <TableCell align="center" style={{ paddingRight: 0 }}>
                {row.value_after}
              </TableCell>
              <TableCell align="center" style={{ paddingRight: 0 }}>
                {calculateAverage(row)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </BorderTable>
    );
  } else {
    return (
      <BorderTable>
        <TableHead>
          <TableRow>
            {EnvironmentConditionHead.map((it) => (
              <TableCell key={it.id} align={it.alignment} width={it.width}>
                {it.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {envRows.map((row, idx) => (
            <TableRow key={idx}>
              {row.is_required ? (
                <TableCell align="left" style={{ paddingLeft: 16 }}>
                  <Stack direction="row" justifyContent="left" alignItems="center" spacing={1}>
                    <img src={require('../media/Vector.png')} style={{ width: 14, height: 10 }} alt="check img" />
                    <Typography>{row.label}</Typography>
                  </Stack>
                </TableCell>
              ) : (
                <TableCell align="left" style={{ paddingLeft: 40 }}>
                  {row.label}
                </TableCell>
              )}
              <TableCell align="center">{row.unit}</TableCell>

              <TableCell align="center" style={{ paddingRight: 0 }}>
                <input
                  style={{
                    fontSize: '16px',
                    textAlign: 'center',
                    fontWeight: '100',
                    color: '#666666',
                    width: '100px',
                    height: '33px',
                    padding: 0,
                  }}
                  value={row.value_before}
                  onChange={(e) => {
                    handleValueChange(e, row, true);
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                />
              </TableCell>
              <TableCell align="center" style={{ paddingRight: 0 }}>
                <input
                  style={{
                    fontSize: '16px',
                    textAlign: 'center',
                    fontWeight: '100',
                    color: '#666666',
                    width: '100px',
                    height: '33px',
                    padding: 0,
                  }}
                  value={row.value_after}
                  onChange={(e) => {
                    handleValueChange(e, row, false);
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                />
              </TableCell>
              <TableCell align="center" style={{ paddingRight: 0 }}>
                {calculateAverage(row)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </BorderTable>
    );
  }
}

function CreateEnvData(space_id = '', name = '', label = '', unit = '', value_before = '', value_after = '', is_required = '', average = '') {
  return {
    space_id: space_id,
    name: name,
    label: label,
    unit: unit,
    value_before: value_before,
    value_after: value_after,
    is_required: is_required,
    average: average,
  };
}
