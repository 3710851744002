import { Stack, styled, Table, Button, TextField, Typography } from '@mui/material';

export const PressureHead = [
  { id: 'section', alignment: 'center', label: '구분', width: '30%' },
  { id: 'unit', alignment: 'center', label: '단위', width: '20%' },
  { id: 'pressure', alignment: 'center', label: '가압', width: '40%' },
];

export const DecompHead = [
  { id: 'section', alignment: 'center', label: '구분', width: '30%' },
  { id: 'unit', alignment: 'center', label: '단위', width: '20%' },
  { id: 'decomp', alignment: 'center', label: '감압', width: '40%' },
];

export const PressureDecompHead = [
  { id: 'section', alignment: 'center', label: '구분', width: '20%' },
  { id: 'unit', alignment: 'center', label: '단위', width: '10%' },
  { id: 'pressure', alignment: 'center', label: '가압', width: '10%' },
  { id: 'decomp', alignment: 'center', label: '감압', width: '10%' },
  { id: 'avg', alignment: 'center', label: '평균', width: '10%' },
];

export const BuildingHead = [
  { id: 'select', alignment: 'center', label: '선택', width: '5%' },
  { id: 'no', alignment: 'center', label: 'NO', width: '5%' },
  { id: 'name', alignment: 'center', label: '건물명', width: '5%' },
  { id: 'img', alignment: 'center', label: '사진', width: '5%' },
  { id: 'type', alignment: 'center', label: '건물 유형', width: '5%' },
  { id: 'number', alignment: 'center', label: '측정 건수', width: '5%' },
  { id: 'completion', alignment: 'center', label: '준공', width: '5%' },
  { id: 'location', alignment: 'center', label: '소재지', width: '5%' },
  { id: 'note', alignment: 'center', label: '비고', width: '5%' },
];
export const BuildingHeadExpert = [
  { id: 'select', alignment: 'center', label: '선택', width: '4%' },
  { id: 'no', alignment: 'center', label: 'NO', width: '5%' },
  { id: 'name', alignment: 'center', label: '건물명', width: '20%' },
  { id: 'img', alignment: 'center', label: '사진', width: '10%' },
  { id: 'type', alignment: 'center', label: '건물 유형', width: '10%' },
  { id: 'number', alignment: 'center', label: '측정 건수', width: '10%' },
  { id: 'completion', alignment: 'center', label: '준공', width: '10%' },
  { id: 'location', alignment: 'center', label: '소재지', width: '10%' },
  { id: 'note', alignment: 'center', label: '비고', width: '10%' },
  { id: 'modify', alignment: 'center', label: '수정', width: '5%' },
  { id: 'remove', alignment: 'center', label: '삭제', width: '5%' },
];

export const BuildingInfoHead = [
  { id: 'name', alignment: 'center', label: '건물명', width: '15%' },
  { id: 'type', alignment: 'center', label: '건물 유형', width: '10%' },
  { id: 'completion', alignment: 'center', label: '준공', width: '5%' },
  { id: 'location', alignment: 'center', label: '소재지', width: '5%' },
  { id: 'total', alignment: 'center', label: '연면적 (㎡)', width: '5%' },
];

export const SpaceHead = [
  { id: 'select', alignment: 'center', label: '선택', width: '5%' },
  { id: 'no', alignment: 'center', label: 'NO', width: '5%' },
  { id: 'name', alignment: 'center', label: '동', width: '5%' },
  { id: 'img', alignment: 'center', label: '호', width: '5%' },
  { id: 'type', alignment: 'center', label: '사진', width: '5%' },
  { id: 'number', alignment: 'center', label: '측정 일자', width: '5%' },
  { id: 'completion', alignment: 'center', label: '측정 표준', width: '5%' },
  { id: 'location', alignment: 'center', label: '측정 시점', width: '5%' },
  { id: 'note', alignment: 'center', label: '바닥 면적', width: '5%' },
];
export const SpaceHeadExpert = [
  { id: 'select', alignment: 'center', label: '선택', width: '4%' },
  { id: 'no', alignment: 'center', label: 'NO', width: '5%' },
  { id: 'name', alignment: 'center', label: '동', width: '10%' },
  { id: 'img', alignment: 'center', label: '호', width: '10%' },
  { id: 'type', alignment: 'center', label: '사진', width: '5%' },
  { id: 'number', alignment: 'center', label: '측정 일자', width: '10%' },
  { id: 'completion', alignment: 'center', label: '측정 표준', width: '10%' },
  { id: 'location', alignment: 'center', label: '측정 시점', width: '10%' },
  { id: 'floor', alignment: 'center', label: '바닥 면적', width: '10%' },
  { id: 'note', alignment: 'center', label: '비고', width: '10%' },
  { id: 'modify', alignment: 'center', label: '수정', width: '5%' },
  { id: 'remove', alignment: 'center', label: '삭제', width: '5%' },
];

export const SpaceInfoHead = [
  { id: 'section', alignment: 'center', label: '구분', width: '40%' },
  { id: 'note', alignment: 'center', label: '내용', width: '60%' },
];

export const EnvironmentConditionHead = [
  { id: 'section', alignment: 'center', label: '구분', width: '5%' },
  { id: 'unit', alignment: 'center', label: '단위', width: '5%' },
  { id: 'value_before', alignment: 'center', label: '측정 전', width: '5%' },
  { id: 'value_after', alignment: 'center', label: '측정 후', width: '5%' },
  { id: 'average', alignment: 'center', label: '평균', width: '5%' },
];

export const AirtightnessHead_P = [
  { id: 'section', alignment: 'center', label: '구분', width: '5%' },
  { id: 'unit', alignment: 'center', label: '단위', width: '5%' },
  { id: 'value_before', alignment: 'center', label: '가압', width: '5%' },
];

export const AirtightnessHead_D = [
  { id: 'section', alignment: 'center', label: '구분', width: '5%' },
  { id: 'unit', alignment: 'center', label: '단위', width: '5%' },
  { id: 'value_before', alignment: 'center', label: '감압', width: '5%' },
];

export const AirtightnessHead_PD = [
  { id: 'section', alignment: 'center', label: '구분', width: '5%' },
  { id: 'unit', alignment: 'center', label: '단위', width: '5%' },
  { id: 'value_before', alignment: 'center', label: '가압', width: '5%' },
  { id: 'value_before', alignment: 'center', label: '감압', width: '5%' },
  { id: 'average', alignment: 'center', label: '평균', width: '5%' },
];

export const MeasuringHead = [
  { id: 'select', alignment: 'center', label: '선택', width: '5%' },
  { id: 'no', alignment: 'center', label: 'NO', width: '5%' },
  { id: 'company', alignment: 'center', label: '제조사', width: '10%' },
  { id: 'product', alignment: 'center', label: '모델명', width: '15%' },
  { id: 'serial', alignment: 'center', label: '시리얼 번호', width: '10%' },
  { id: 'expire', alignment: 'center', label: '교정 만료 기간', width: '10%' },
  { id: 'note', alignment: 'center', label: '비고', width: '10%' },
  { id: 'modify', alignment: 'center', label: '수정', width: '5%' },
  { id: 'delete', alignment: 'center', label: '삭제', width: '5%' },
];

export const MeasuringResultHead = [
  { id: 'no', alignment: 'center', label: 'NO', width: '5%' },
  { id: 'equipment', alignment: 'center', label: '장비', width: '10%' },
  { id: 'company', alignment: 'center', label: '제조사', width: '10%' },
  { id: 'product', alignment: 'center', label: '모델명', width: '10%' },
  { id: 'serial', alignment: 'center', label: '시리얼 번호', width: '10%' },
  { id: 'expire', alignment: 'center', label: '교정 만료 기간', width: '10%' },
  { id: 'note', alignment: 'center', label: '비고', width: '10%' },
];

export const MeasuringInfoHead = [
  { id: 'no', alignment: 'center', label: 'NO', width: '5%' },
  { id: 'company', alignment: 'center', label: '제조사', width: '10%' },
  { id: 'product', alignment: 'center', label: '모델명', width: '10%' },
  { id: 'serial', alignment: 'center', label: '시리얼 번호', width: '10%' },
  { id: 'expire', alignment: 'center', label: '교정 만료 기간', width: '10%' },
  { id: 'note', alignment: 'center', label: '비고', width: '10%' },
];

export const AchHead = [
  { id: 'Group', alignment: 'center', label: 'Group', width: '5%' },
  { id: 'Color', alignment: 'center', label: 'Color', width: '5%' },
  { id: 'Title', alignment: 'center', label: 'Title', width: '5%' },
  { id: 'Value', alignment: 'center', label: 'Value', width: '5%' },
];

export const AchHeadAdmin = [
  { id: 'Group', alignment: 'center', label: 'Group', width: '5%' },
  { id: 'Color', alignment: 'center', label: 'Color', width: '5%' },
  { id: 'Title', alignment: 'center', label: 'Title', width: '5%' },
  { id: 'Value', alignment: 'center', label: 'Value', width: '5%' },
  { id: 'Edit', alignment: 'center', label: '수정', width: '5%' },
  { id: 'Delete', alignment: 'center', label: '삭제', width: '5%' },
  { id: 'IsPublic', alignment: 'center', label: '공개', width: '5%' },
];

export const AdditionalInfoAirtightnessHead = [
  { id: 'section', alignment: 'center', label: '구분', width: '30%' },
  { id: 'unit', alignment: 'center', label: '단위', width: '15%' },
  {
    id: 'building_average',
    alignment: 'center',
    label: '건물 전체 평균',
    width: '15%',
  },
  {
    id: 'facility_average',
    alignment: 'center',
    label: '대상 유형 평균',
    width: '15%',
  },
  { id: 'result', alignment: 'center', label: '측정 값', width: '15%' },
];

// eslint-disable-next-line
let id = 0;
//기밀성능 측정 결과 table data
function createData1(section, unit, pressure, decomp, avg) {
  id += 1;
  return { id, section, unit, pressure, decomp, avg };
}

export const result = [
  createData1('Airflow Exponent, n', '-', '0.590', '0.620', '0.605'),
  createData1('Air Leakage Coefficient, C', 'm3/h-m2', '56.7', '50.3', '53.5'),
  createData1('Correlation, r2', '%', '99.98', '99.98', '99.98'),
  createData1('Air Leakage Rate at 50 Pa, Q50  ', 'm3/h@50 Pa', '1020.2', '1003.3', '1011.7'),
];

//건물 table data
function createBuildingData(select, no, name, img, type, number, completion, location, note, modify, remove, total) {
  id += 1;
  return {
    id,
    select,
    no,
    name,
    img,
    type,
    number,
    completion,
    location,
    note,
    modify,
    remove,
    total,
  };
}
export const building = [
  createBuildingData('', '1', 'GreenWalk', '../media/Rectangle.png', '공동 주택', '268', '2013년', '인천', '입력 완료', '../media/penIcon.png', '../media/deleteIcon.png', '100'),
  createBuildingData('', '2', 'Office1', '../media/Rectangle.png', '업무 시설', '2', '2015년', '서울', '입력 완료', '../media/penIcon.png', '../media/deleteIcon.png', '102'),
  createBuildingData('', '3', 'Test1', '../media/Rectangle.png', '단독 주택', '1', '2015년', '경북', '입력 중', '../media/penIcon.png', '../media/deleteIcon.png', '103'),
  createBuildingData('', '4', '이당관', '../media/Rectangle.png', '교육 시설', '1', '2013년', '서울', '입력 완료', '../media/penIcon.png', '../media/deleteIcon.png', '104'),
];

//건물 table data
function createEnvironmentConditionData(section, unit, value_before, value_after, average, is_required) {
  id += 1;
  return {
    id,
    section,
    unit,
    value_before,
    value_after,
    average,
    is_required,
  };
}

export const environmentCondition = [
  createEnvironmentConditionData('실외 기압', 'Pa', '101,325', '101,325', '101,325', true),
  createEnvironmentConditionData('실외 풍속', 'm/s', '101,325', '101,325', '101,325', true),
  createEnvironmentConditionData('실외 온도', '℃', '101,325', '101,325', '101,325', false),
  createEnvironmentConditionData('실외 상대 습도', '%', '101,325', '101,325', '101,325', false),
  createEnvironmentConditionData('실내 온도', '℃', '101,325', '101,325', '101,325', false),
  createEnvironmentConditionData('실내 상대 습도', '%', '101,325', '101,325', '101,325', false),
];

//건물 table data
function createSpaceData(select, no, name_dong, name_num, type, number, completion, location, note, modify, remove, total) {
  id += 1;
  return {
    id,
    select,
    no,
    name_dong,
    name_num,
    type,
    number,
    completion,
    location,
    note,
    modify,
    remove,
    total,
  };
}

export const space = [
  createSpaceData('', '1', '1101동', '303호', '../media/Rectangle.png', '2013-10-14', '가압/감압법', 'ASTM E779', '96.92', '../media/penIcon.png', '../media/deleteIcon.png', '101'),
  createSpaceData('', '2', '1102동', '303호', '../media/Rectangle.png', '2013-10-14', '가압/감압법', 'ASTM E779', '96.92', '../media/penIcon.png', '../media/deleteIcon.png', '102'),
  createSpaceData('', '3', '1103동', '303호', '../media/Rectangle.png', '2013-10-14', '가압/감압법', 'ASTM E779', '96.92', '../media/penIcon.png', '../media/deleteIcon.png', '103'),
  createSpaceData('', '4', '1104동', '303호', '../media/Rectangle.png', '2013-10-14', '가압/감압법', 'ASTM E779', '96.92', '../media/penIcon.png', '../media/deleteIcon.png', '104'),
];

//측정 정비 table
function createMeasuringData(select, no, company, product, serial, expire, note) {
  id += 1;
  return { id, select, no, company, product, serial, expire, note };
}

export const gauge = [
  createMeasuringData('', '1', 'Minneapolis', 'DG-1002', '14123232', '2025-12-31', ''),
  createMeasuringData('', '1', 'Minneapolis', 'DG-1002', '14123232', '2025-12-31', ''),
  createMeasuringData('', '1', 'Minneapolis', 'DG-1002', '14123232', '2025-12-31', ''),
];

export const blowerDoorFan = [
  createMeasuringData('', '1', 'Minneapolis', 'DG-1002', 'model', '-', 'serial', '2025-12-31', ''),
  createMeasuringData('', '1', 'Minneapolis', 'DG-1002', 'model', '-', 'serial', '2025-12-31', ''),
  createMeasuringData('', '1', 'Minneapolis', 'DG-1002', 'model', '-', 'serial', '2025-12-31', ''),
];

function createData4(group, title, value) {
  id += 1;
  return { id, group, title, value };
}
export const ach = [
  createData4('Korea', 'Passive House criteria', 0.6),
  createData4('Korea', '친환경 설비학회 일반건물', 3),
  createData4('Korea', '친환경 설비학회 에너지효율 건물', 5),
  createData4('Japan', '00건물', 1.5),
  createData4('Japan', '**건물', 3),
];

export const LogoText = styled(Typography)`
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
`;

export const HeaderText = styled(Typography)`
  color: #ffffff;
  font-weight: 700;
  font-size: 24px;
  padding-right: 8px;
`;

export const Card = styled('div')`
  border: none;
  border-radius: 11px;
  width: 90vw;
  height: 88vh;
  overflow-y: scroll;
  margin: 0 auto;
  margin-top: 30px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const IndexCard = styled('div')`
  background-color: #ffffff;
  border-radius: 10px;
  width: 1200px;
  height: 720px;
  overflow-y: scroll;
  margin: 0 auto;
  margin-top: 120px;
  ::-webkit-scrollbar {
    display: none;
  }
  opacity: 90%;
`;

export const ModalCard = styled('div')`
  background-color: #ffffff;
  border-radius: 10px;
  width: 1200px;
  overflow-y: scroll;
  margin: 0 auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const FixedHeader = styled(Stack)`
  background-color: #f0f5ff;
  border: none;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
`;

export const Header = styled(Stack)`
  background-color: rgb(18, 31, 63);
  width: 100%;
  height: 80px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const LableText = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
`;

export const LableTextCenter = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
`;

export const LableDescriptionTextCenter = styled(Typography)`
  font-size: 16px;
  font-weight: 300;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
`;

export const LableDescriptionTextLeft = styled(Typography)`
  font-size: 16px;
  font-weight: 300;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
`;

export const LableDescriptionBoldTextCenter = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
`;

export const LableDescriptionBoldTextLeft = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
`;

const PrivacyPolicyButton = styled(Button)`
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
`;

export const UserButton = styled(Button)`
  color: #ffffff;
  font-size: 14px;
  font-weight: 300;
  height: 25px;
  letter-spacing: 0em;
  text-align: right;
  &:hover,
  &:active {
    color: #ffffff;
    background-color: transparent;
  }
`;

export const BorderTable = styled(Table)`
  border-collapse: separate;
  thead {
    tr {
      background-color: #f4f4f4;
      border: 1px solid #dddddd;
      th {
        font-size: 16px;
        font-weight: 350;
        color: #666666;
        padding: 11px 2px;
        border-top: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
      }
    }
    tr:first-type th:first-type {
      border-top-left-radius: 8px;
      border-left: 1px solid #dddddd;
    }
    tr:first-type th:last-type {
      border-top-right-radius: 8px;
      border-right: 1px solid #dddddd;
    }
  }
  tbody {
    tr {
      border: 1px solid #dddddd;
      td {
        font-size: 16px;
        font-weight: 350;
        color: #222222;
        padding: 10px 2px;
        border-bottom: 1px solid #dddddd;
      }
    }
    td:first-type {
      border-left: 1px solid #dddddd;
    }
    td:last-type {
      border-right: 1px solid #dddddd;
    }

    tr:last-type td:first-type {
      border-bottom-left-radius: 8px;
    }
    tr:last-type td:last-type {
      border-bottom-right-radius: 8px;
      border-right: 1px solid #dddddd;
    }
  }
`;

export const GraphArea = styled(Stack)`
  flex: 2;
  background-color: #f4f4f4;
  min-height: 280px;
  padding: 16px;
  border-radius: 5px;
`;

export const SecondLableText = styled(Typography)`
  font-size: 20px;
  font-weight: 350;
`;

export const NoteTextField = styled(TextField)`
  div {
    background-color: #fbfbfb;
    input {
      align-items: flex-start;
      font-size: 16px;
    }
  }
`;

export const SelectLable = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  padding-right: 12px;
`;
