import { Card, CardContent, Stack, Typography } from '@mui/material';

import _ from 'lodash';
import React from 'react';
import { BlueButton, GreyButton } from '../components/button';
import { CenterModal } from './Modal';

export function ApproveModal({ on, onClose, onConfirm = _.noop }) {
  return (
    <>
      <CenterModal open={on} onClose={onClose}>
        <Card sx={{ minWidth: 730 }}>
          <CardContent>
            <Stack direction="row" justifyContent="center" sx={{ pt: 3, pb: 5 }}>
              <Typography>보고서를 출력하시겠습니까?</Typography>
            </Stack>
            <Stack direction="row" justifyContent="center" spacing={1} sx={{ pt: 1 }}>
              <BlueButton
                variant="contained"
                color="primary"
                style={{ boxShadow: 'none' }}
                sx={{ py: 0.25, width: 120 }}
                onClick={onConfirm}>
                확인
              </BlueButton>
              <GreyButton
                variant="contained"
                color="secondary"
                style={{ boxShadow: 'none' }}
                sx={{ py: 0.25, width: 120 }}
                onClick={onClose}>
                취소
              </GreyButton>
            </Stack>
          </CardContent>
        </Card>
      </CenterModal>
    </>
  );
}
