import * as React from 'react';
import { Stack } from '@mui/material';
import { EnvironmentConditionTable } from '../tables/EnvironmentConditionTable';
import {
  LableText,
  LableTextCenter,
  LableDescriptionTextCenter,
  LableDescriptionTextLeft,
  LableDescriptionBoldTextCenter,
  LableDescriptionBoldTextLeft,
  ModalCard,
  BuildingHeadExpert,
  BorderTable,
} from '../components/const';

function EnvironmentCondition(props) {
  if (props.isResult) {
    return (
      <>
        <Stack sx={{ mt: 5, ml: 10, mr: 10 }}>
          <Stack direction="row" alignItems="center" spacing={1} sx={{ pb: 2, paddingBottom: 5 }}>
            <LableText sx={{ pr: 2 }}>측정 조건</LableText>
          </Stack>
        </Stack>
        <Stack sx={{ ml: 10, mr: 10 }}>
          <EnvironmentConditionTable userId={props.userId} setTitle={props.setTitle} state={props.state} isResult={props.isResult} />
        </Stack>
      </>
    );
  } else if (props.isResult === false && props.state) {
    return (
      <>
        <Stack sx={{ ml: 10, mr: 10 }}>
          <EnvironmentConditionTable userId={props.userId} setTitle={props.setTitle} state={props.state} isResult={props.isResult} />
        </Stack>
      </>
    );
  } else {
    return (
      <>
        <Stack sx={{ ml: 10, mr: 10 }}>
          <EnvironmentConditionTable userId={props.userId} setTitle={props.setTitle} state={props.state} isResult={props.isResult} />
        </Stack>
      </>
    );
  }
}

export default EnvironmentCondition;
