import _ from 'lodash';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import emailjs from '@emailjs/browser';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import {
  LoginButton,
  AddButton,
  BlueButton,
  RedButton,
  DarkGreyButton,
  GreyButton,
  FindPasswordButton,
  CheckButton,
} from '../components/button';
import { LableText, NoteTextField, SelectLable } from '../components/const';
import { UseSelect } from '../components/select';

import {
  Card,
  Stack,
  Typography,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';

import { BorderTable, AdditionalInfoAirtightnessHead, GraphArea, ModalCard } from '../components/const';

import { Signin, Signup, Renewal, GetVerificationKey, UserChange, UserWithdrawal } from '../data/Data';
import MenuItem from '@mui/material/MenuItem';
import ToggleButton from '@mui/material/ToggleButton';
import Box from '@mui/material/Box';
import { Biotech, Close, Delete } from '@mui/icons-material';

import {
  CardHeader,
  CenterModal,
  ModalHeadText,
  ModalInput,
  ModalLableText,
  ModalTextField,
  ToggleTextField,
  TypeButtonGroup,
  useModalToggle,
} from './Modal';

import SetAuthorizationToken from '../utils/SetAuthorizationToken';

import EmailContactForm from './EmailContactForm';

export function UserModal({ on, onClose, modalScreen, setModalScreen, onSucceed = _.noop }) {
  const navigate = useNavigate();

  const [id, setId] = React.useState('');
  const [oldPassword, setOldPassword] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [company_position, setCompany_position] = React.useState('');
  const [permission_type, setPermission_type] = React.useState('');
  const [passwordIncorrect, setPasswordIncorrect] = React.useState(false);
  const [valid, setValid] = React.useState(false);

  React.useEffect(() => {
    var userId = sessionStorage.getItem('userId');
    var email = sessionStorage.getItem('email');
    var username = sessionStorage.getItem('username');
    var phone = sessionStorage.getItem('phone');
    var company = sessionStorage.getItem('company');
    var company_position = sessionStorage.getItem('company_position');
    var role = sessionStorage.getItem('role');

    setId(userId);
    setOldPassword('');
    setPassword('');
    setPassword2('');
    setUsername(username);
    setPhone(phone);
    setCompany(company);
    setCompany_position(company_position);
    setPermission_type(role);
  }, [on]);

  React.useEffect(() => {
    if (phone.length === 10 && phone.startsWith('01') === false) {
      setPhone(phone.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3'));
    }
    if (phone.length === 10 && phone.startsWith('01')) {
      setPhone(phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
    }
    if (phone.length === 13) {
      setPhone(phone.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
    }
  }, [phone]);

  const oldPasswordChange = (e) => {
    setOldPassword(e.target.value);
  };

  const passwordChange = (e) => {
    setPassword(e.target.value);

    setPasswordIncorrect(password2 !== e.target.value);

    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    setValid(regex.test(e.target.value));
  };

  const password2Change = (e) => {
    setPassword2(e.target.value);

    setPasswordIncorrect(password !== e.target.value);
  };

  const usernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleChangePhone = (e) => {
    const regex = /^[0-9\b -]{0,15}$/;
    if (regex.test(e.target.value)) {
      setPhone(e.target.value);
    }
  };

  const companyChange = (e) => {
    setCompany(e.target.value);
  };

  const company_positionChange = (e) => {
    setCompany_position(e.target.value);
  };

  const permission_typeChange = (e) => {
    setPermission_type(e.target.value);
  };

  const handleNavigate = (path, state) => {
    navigate(path, state);
  };

  const handleChange = async (e) => {
    debugger;
    if (oldPassword.trim() === '') {
      alert('비밀번호를 입력해주세요.');
      return;
    }
    if (password.trim() !== '' && valid === false) {
      return;
    }

    var regData = UserSignupData(
      id,
      oldPassword,
      password,
      username,
      phone,
      company,
      company_position,
      permission_type
    );

    try {
      var res = await UserChange(regData);

      sessionStorage.setItem('username', username);
      sessionStorage.setItem('phone', phone);
      sessionStorage.setItem('company', company);
      sessionStorage.setItem('company_position', company_position);
      sessionStorage.setItem('role', permission_type);

      if (password.trim() !== '') {
        SetAuthorizationToken('AccessToken', null);
        SetAuthorizationToken('RefreshToken', null);
        alert('비밀번호가 변경되었습니다. 다시 로그인해주세요.');
        var path = `/`;
        handleNavigate(path, {});
      } else {
        alert(res.data.Message);
        onSucceed(res.data.role, id);
      }
    } catch (err) {
      debugger;
      console.log(err);
      alert(err.response.data.Message);
    }
  };

  const handleWithdrawal = async () => {
    var msg = '탈퇴 시 모든 회원정보는 삭제되며 되돌릴 수 없습니다.\r\n퇼퇴 하시겠습니까?';
    const confirmed = window.confirm(msg);
    if (confirmed) {
      try {
        console.log(id);
        var res = await UserWithdrawal(id);

        sessionStorage.setItem('username', null);
        sessionStorage.setItem('phone', null);
        sessionStorage.setItem('company', null);
        sessionStorage.setItem('company_position', null);
        sessionStorage.setItem('role', null);

        SetAuthorizationToken('AccessToken', null);
        SetAuthorizationToken('RefreshToken', null);
        alert('탈퇴되었습니다.');
        var path = `/`;
        handleNavigate(path, {});
      } catch (err) {
        debugger;
        console.log(err);
        alert(err.response.data.Message);
      }
    }
  };

  return (
    <>
      <CenterModal open={on} onClose={onClose}>
        <>
          {modalScreen === 0 && (
            <>
              <ModalCard style={{ width: '500px' }}>
                <CardHeader direction="row" justifyContent="space-between">
                  <ModalHeadText sx={{ pt: 1 }}>회원정보 변경</ModalHeadText>
                  <IconButton sx={{ mr: 5 }} size="small" onClick={onClose}>
                    <Close />
                  </IconButton>
                </CardHeader>
                <CardContent style={{ padding: 20 }}>
                  <Stack direction="row" justifyContent="space-between">
                    <Stack style={{ width: '800px' }}>
                      <Grid container spacing={0} justifyContent="center" sx={{ mb: 3, backgroundColor: '#F8FAFB' }}>
                        <Grid item xs={11} sx={{ mt: 2, mb: 2 }}>
                          <ModalLableText style={{ fontSize: 17, fontWeight: 400 }}>이메일 주소</ModalLableText>
                          <Stack direction="row" spacing={1}>
                            <Stack sx={{ flex: 1 }}>
                              <ModalInput disabled value={id} type="email" size="small"></ModalInput>
                            </Stack>
                          </Stack>
                          <ModalLableText style={{ fontSize: 17, fontWeight: 400 }}>비밀번호</ModalLableText>
                          <Stack direction="row" spacing={2}>
                            <Stack sx={{ flex: 1 }}>
                              <ModalTextField
                                type="password"
                                defaultValue={oldPassword}
                                onChange={(e) => {
                                  oldPasswordChange(e);
                                }}
                                size="small"></ModalTextField>
                            </Stack>
                          </Stack>
                          <ModalLableText style={{ fontSize: 17, fontWeight: 400 }}>새 비밀번호</ModalLableText>
                          <Stack direction="row" spacing={2}>
                            <Stack sx={{ flex: 1 }}>
                              <ModalTextField
                                error={password && !valid}
                                type="password"
                                helperText={
                                  password &&
                                  !valid && <p>대문자, 숫자, 특수문자 중 2개 이상 조합 및 최소 8자리 이상</p>
                                }
                                onChange={(e) => {
                                  passwordChange(e);
                                }}
                                size="small"></ModalTextField>
                            </Stack>
                            <Stack sx={{ flex: 1 }}>
                              <ModalTextField
                                error={passwordIncorrect}
                                id="outlined-error-helper-text"
                                type="password"
                                helperText={passwordIncorrect ? 'Incorrect entry.' : ''}
                                onChange={(e) => {
                                  password2Change(e);
                                }}
                                size="small"></ModalTextField>
                            </Stack>
                          </Stack>
                          <ModalLableText style={{ fontSize: 17, fontWeight: 400 }}>이름</ModalLableText>
                          <ModalInput
                            type="text"
                            defaultValue={username}
                            onChange={(e) => {
                              usernameChange(e);
                            }}
                            size="small"></ModalInput>
                          <ModalLableText style={{ fontSize: 17, fontWeight: 400 }}>연락처</ModalLableText>
                          <ModalInput
                            placeholder="휴대폰번호를 입력하세요."
                            type="text"
                            onChange={handleChangePhone}
                            defaultValue={phone}
                            //value={phone}
                            size="small"></ModalInput>
                          <ModalLableText style={{ fontSize: 17, fontWeight: 400 }}>기관</ModalLableText>
                          <ModalInput
                            type="text"
                            defaultValue={company}
                            onChange={(e) => {
                              companyChange(e);
                            }}
                            size="small"></ModalInput>
                          <ModalLableText style={{ fontSize: 17, fontWeight: 400 }}>직위</ModalLableText>
                          <ModalInput
                            type="text"
                            defaultValue={company_position}
                            onChange={(e) => {
                              company_positionChange(e);
                            }}
                            size="small"></ModalInput>
                          <ModalLableText style={{ fontSize: 17, fontWeight: 400 }}>사용자 유형</ModalLableText>
                          <RadioGroup
                            row
                            aria-labelledby="row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultChecked={permission_type}
                            value={permission_type}
                            onChange={permission_typeChange}>
                            <FormControlLabel value="normal" control={<Radio />} label="일반" />
                            <FormControlLabel value="expert" control={<Radio />} label="전문가 (승인필요)" />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                  <Stack direction="row" spacing={2} justifyContent="center">
                    <BlueButton onClick={() => handleChange()}>변경</BlueButton>
                    <RedButton onClick={() => handleWithdrawal()}>탈퇴</RedButton>
                  </Stack>
                </CardContent>
              </ModalCard>
            </>
          )}
        </>
      </CenterModal>
    </>
  );
}

function Criteria(group = '', color = '', title = '', value = '') {
  return {
    group: group,
    color: color,
    title: title,
    value: value,
  };
}

function UserSigninData(email = '', password = '') {
  return {
    email: email,
    password: password,
  };
}

function UserSignupData(
  email = '',
  old_password = '',
  password = '',
  username = '',
  phone = '',
  company = null,
  company_position = null,
  permission_type = ''
) {
  return {
    email: email,
    old_password: old_password,
    password: password,
    username: username,
    phone: phone,
    company: company,
    company_position: company_position,
    permission_type: permission_type,
  };
}
