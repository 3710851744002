import { Checkbox, List, ListItem, styled, Typography } from '@mui/material';
import _ from 'lodash';
import * as React from 'react';
import { ach, AchHead, AchHeadAdmin, ModalCard } from '../components/const';
import Scrollbar from '../components/Scrollbar';
import { DeleteAirtightnessPerformCriteria, SetAirtightnessPerformCriteria, GetAirtightnessPerformCriteria } from '../data/Data';
import { Biotech, Close, Delete } from '@mui/icons-material';

import {
  Radio,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';

import { AddButton, BlueButton, DarkGreyButton, GreyButton } from '../components/button';

import { CardHeader, CenterModal, ModalHeadText, ModalInput, ModalLableText, ModalTextField, ToggleTextField, TypeButtonGroup, useModalToggle } from '../Modal/Modal';

import { ColorPicker, useColor } from 'react-color-palette';

const BorderListItem = styled(ListItem)`
  padding-top: 8px;
  height: 45px;
  font-size: 16px;
`;
const CheckboxListItem = styled(ListItem)`
  padding: 2px 6px;
  height: 45px;
`;
const SelectableRow = styled(CheckboxListItem)`
  p {
    padding-right: 6px;
  }
  border-bottom: 1px solid #dddddd;
`;

const BorderList = styled(List)`
  padding-top: 0;
  padding-bottom: 0;
`;
const TableText = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  flex: 1;
`;

const TableHead = styled(ListItem)`
  background-color: #f4f4f4;
  width: 100%;
  height: 48px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid #dddddd;
  border-bottom: none;
  font-size: 16px;
  color: #666666;
`;

export function AchTable(props) {
  const [selectOpen, setSelectOpen] = React.useState(false);
  const [selectClose, setSelectClose] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [selectedCriteriaSeq, setSelectedCriteriaSeq] = React.useState('');

  const deleteClickOpen = (seq) => {
    setSelectedCriteriaSeq(seq);
    setDeleteOpen(true);
  };

  const selectClickOpen = (seq) => {
    setSelectedCriteriaSeq(seq);
    setSelectOpen(true);
  };

  const selectClickClose = (seq) => {
    setSelectedCriteriaSeq(seq);
    setSelectClose(true);
  };

  const handleSelect = () => {
    var criteria = props.criteria.find((c) => {
      return c.seq === parseInt(selectedCriteriaSeq);
    });
    criteria.is_open = !criteria.is_open;
    SetAirtightnessPerformCriteria(criteria);
    setSelectOpen(false);
    setSelectClose(false);
    props.setIsUpdated(true);
  };

  const handleDelete = () => {
    DeleteAirtightnessPerformCriteria(selectedCriteriaSeq);
    setDeleteOpen(false);
    props.setIsUpdated(true);
  };

  React.useEffect(() => {}, []);

  if (props.userType === 'admin') {
    return (
      <>
        <TableHead style={{ paddingRight: 0 }}>
          {AchHeadAdmin.map((it) => (
            <TableText key={it.id} align="center">
              {it.label}
            </TableText>
          ))}
        </TableHead>
        <Scrollbar sx={{ height: 603, minWidth: 500, border: '1px solid #dddddd' }}>
          <BorderList sx={{ height: 600, pl: 0 }}>
            {props.criteria.length !== undefined ? (
              props.criteria.map((it, idx) => {
                return (
                  <SelectableRow key={it.seq}>
                    <TableText align="center">{it.group}</TableText>
                    <p
                      style={{
                        backgroundColor: `rgb(${it.color})`,
                        width: 15,
                        height: 15,
                        marginLeft: '6%',
                        marginRight: '6%',
                      }}></p>
                    <TableText align="center">{it.title}</TableText>
                    <TableText align="center">{it.value}</TableText>
                    <TableText align="center">
                      <OpenModalButton selectedCriteria={props.criteria[idx]} setIsUpdated={props.setIsUpdated} />
                    </TableText>
                    <TableText align="center">
                      <img
                        src={require('../media/deleteIcon.png')}
                        style={{
                          width: 14,
                          height: 14,
                          cursor: 'pointer',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}
                        alt="delete img"
                        onClick={() => {
                          deleteClickOpen(it.seq);
                        }}
                      />
                    </TableText>
                    <TableText align="center">
                      <Checkbox
                        size="small"
                        color="secondary"
                        align="center"
                        checked={it.is_open}
                        onClick={() => {
                          if (it.is_open === false) {
                            selectClickOpen(it.seq);
                          } else {
                            selectClickClose(it.seq);
                          }
                        }}
                        sx={{
                          p: 0,
                          color: '#DDDDDD',
                          '&.Mui-checked': { color: '#222222' },
                        }}
                      />
                    </TableText>
                  </SelectableRow>
                );
              })
            ) : (
              <></>
            )}

            <Dialog
              open={selectOpen}
              onClose={() => {
                setSelectOpen(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">{'공개 하시겠습니까?'}</DialogTitle>
              <DialogContent></DialogContent>
              <DialogActions>
                <Button
                  value={selectedCriteriaSeq}
                  onClick={() => {
                    handleSelect();
                  }}>
                  공개
                </Button>
                <Button
                  onClick={() => {
                    setSelectOpen(false);
                  }}>
                  닫기
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={selectClose}
              onClose={() => {
                setSelectClose(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">{'비공개 하시겠습니까?'}</DialogTitle>
              <DialogContent></DialogContent>
              <DialogActions>
                <Button
                  value={selectedCriteriaSeq}
                  onClick={() => {
                    handleSelect();
                  }}>
                  비공개
                </Button>
                <Button
                  onClick={() => {
                    setSelectClose(false);
                  }}>
                  닫기
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={deleteOpen}
              onClose={() => {
                setDeleteOpen(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">{'선택한 건물 정보를 리스트에서 삭제하시겠습니까?'}</DialogTitle>
              <DialogContent></DialogContent>
              <DialogActions>
                <Button
                  value={selectedCriteriaSeq}
                  onClick={(e) => {
                    handleDelete();
                  }}>
                  삭제
                </Button>
                <Button
                  onClick={() => {
                    setDeleteOpen(false);
                  }}>
                  닫기
                </Button>
              </DialogActions>
            </Dialog>
            {_.times(10, (t) => (
              <BorderListItem key={t} sx={{ height: 40 }}></BorderListItem>
            ))}
          </BorderList>
        </Scrollbar>
      </>
    );
  } else {
    return (
      <>
        <TableHead style={{ paddingRight: 0 }}>
          {AchHead.map((it) => (
            <TableText key={it.id} align="center">
              {it.label}
            </TableText>
          ))}
        </TableHead>

        <Scrollbar sx={{ height: 603, minWidth: 500, border: '1px solid #dddddd' }}>
          <BorderList sx={{ height: 600, pl: 0 }}>
            {props.criteria.length !== undefined ? (
              props.criteria.map((it) => {
                return (
                  <SelectableRow key={it.seq}>
                    <TableText align="center">{it.group}</TableText>
                    <p
                      style={{
                        backgroundColor: `rgb(${it.color})`,
                        width: 15,
                        height: 15,
                        marginLeft: '11%',
                        marginRight: '12%',
                      }}></p>
                    <TableText align="center">{it.title}</TableText>
                    <TableText align="center">{it.value}</TableText>
                  </SelectableRow>
                );
              })
            ) : (
              <></>
            )}
            {_.times(10, (t) => (
              <BorderListItem key={t} sx={{ height: 40 }}></BorderListItem>
            ))}
          </BorderList>
        </Scrollbar>
      </>
    );
  }
}

export function TableCheckbox() {
  return (
    <Checkbox
      size="small"
      color="secondary"
      sx={{
        p: 0,
        color: '#DDDDDD',
        '&.Mui-checked': {
          color: '#222222',
        },
      }}
    />
  );
}

function OpenModalButton(props) {
  const { on, open, close } = useModalToggle();
  var colors = props.selectedCriteria.color.split(',');
  const [color, setColor] = useColor('hex', rgbToHex(parseInt(colors[0]), parseInt(colors[1]), parseInt(colors[2])));
  const [openPalette, setOpenPalette] = React.useState(false);
  const [newCriteria, setNewCriteria] = React.useState(props.selectedCriteria);

  const [group, setGroup] = React.useState(props.selectedCriteria.group);
  const [title, setTitle] = React.useState(props.selectedCriteria.title);
  const [value, setValue] = React.useState(props.selectedCriteria.value);

  const handleSaveButton = () => {
    if (group === undefined || title === undefined || value === undefined) {
      alert('Group과 Title은 숫자형식 입력이 불가능합니다.');
      return;
    }

    newCriteria.color = rgbToString(color);
    newCriteria.group = group;
    newCriteria.title = title;
    newCriteria.value = value;

    SetAirtightnessPerformCriteria(newCriteria);
    setOpenPalette(false);
    props.setIsUpdated(true);
    close();
  };

  const isNumber = (number) => {
    const regex = /^[+-]?\d*(\.?\d*)?$/;
    return number === '' || regex.test(number);
  };

  const handleChangeGroup = (event) => {
    if (isNumber(event.target.value)) {
      setGroup();
    } else {
      setGroup(event.target.value);
    }
  };

  const handleChangeTitle = (event) => {
    if (isNumber(event.target.value)) {
      setTitle();
    } else {
      setTitle(event.target.value);
    }
  };

  const handleChangeValue = (event) => {
    if (isNumber(event.target.value)) {
      setValue(event.target.value);
    } else {
      setValue();
    }
  };

  React.useEffect(() => {}, [color]);

  return (
    <>
      <AddButton style={{ height: '0px' }} startIcon={<img src={require('../media/penIcon.png')} style={{ width: 14, height: 14 }} alt="modify img" />} onClick={open}></AddButton>
      <CenterModal open={on} onClose={close}>
        <ModalCard style={{ width: '800px' }}>
          <CardHeader direction="row" justifyContent="space-between">
            <ModalHeadText sx={{ pt: 1 }}>ACH50 기밀성능 기준</ModalHeadText>
            <IconButton sx={{ mr: 5 }} size="small" onClick={close}>
              <Close />
            </IconButton>
          </CardHeader>
          <CardContent style={{ padding: 20 }}>
            <Stack direction="row" justifyContent="space-between">
              <Stack style={{ width: '800px' }}>
                <Grid container spacing={0} justifyContent="center" sx={{ mb: 4, backgroundColor: '#F8FAFB' }}>
                  <Grid item xs={11} sx={{ mt: 4, mb: 2 }}>
                    <ModalLableText style={{ fontSize: 17, fontWeight: 400 }}>Group</ModalLableText>
                    <Stack direction="row" spacing={2} justifyContent="center">
                      <ModalTextField
                        type="text"
                        defaultValue={newCriteria.group}
                        onChange={(e) => {
                          handleChangeGroup(e);
                          //setGroup(e.target.value);
                        }}
                        size="small"></ModalTextField>
                      <p
                        onClick={() => {
                          setOpenPalette(!openPalette);
                        }}
                        style={{
                          cursor: 'pointer',
                          backgroundColor: `rgb(${rgbToString(color)})`,
                          width: 30,
                          height: 30,
                          marginTop: 5,
                          marginLeft: 20,
                        }}></p>
                    </Stack>
                    <ModalLableText style={{ fontSize: 17, fontWeight: 400 }}>Title</ModalLableText>
                    <ModalInput
                      type="text"
                      defaultValue={newCriteria.title}
                      onChange={(e) => {
                        handleChangeTitle(e);
                        //setTitle(e.target.value);
                      }}
                      size="small"></ModalInput>
                    <ModalLableText style={{ fontSize: 17, fontWeight: 400 }}>Value</ModalLableText>
                    <ModalInput
                      type="number"
                      defaultValue={newCriteria.value}
                      onChange={(e) => {
                        handleChangeValue(e);
                        //setValue(e.target.value);
                      }}
                      size="small"></ModalInput>
                  </Grid>
                </Grid>
              </Stack>

              {openPalette === true ? (
                <Stack direction="column" spacing={2}>
                  <ColorPicker width={256} height={120} color={color} onChange={setColor} hideHSV dark />
                </Stack>
              ) : (
                <></>
              )}
            </Stack>
            <Stack direction="row" spacing={2} justifyContent="center">
              <BlueButton
                onClick={() => {
                  handleSaveButton();
                }}>
                저장
              </BlueButton>
              <GreyButton onClick={close}>닫기</GreyButton>
            </Stack>
          </CardContent>
        </ModalCard>
      </CenterModal>
    </>
  );
}

function DeleteDialog(props) {
  return (
    <>
      <Dialog open={props.selectOpen} onClose={() => {}} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'공개 하시겠습니까?'}</DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button value={it.seq} onClick={() => {}}>
            공개
          </Button>
          <Button onClick={() => {}}>닫기</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
}

function rgbToHex(r, g, b) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function rgbToString(color) {
  return color.rgb.r + ', ' + color.rgb.g + ', ' + color.rgb.b;
}

function Criteria(group = '', color = '', title = '', value = '') {
  return {
    group: group,
    color: color,
    title: title,
    value: value,
  };
}
