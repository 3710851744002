import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import Router from './routes';
import { Renewal } from './data/Data';
import SetAuthorizationToken from './utils/SetAuthorizationToken';

export function App() {
  return (
    <BrowserRouter>
      <Helmet titleTemplate="Airtightness DB" defaultTitle="Airtightness DB">
        <meta name="keywords" content="" />
      </Helmet>
      <ThemeConfig>
        <GlobalStyles />
        <Router />
      </ThemeConfig>
    </BrowserRouter>
  );
}
