import {
  Checkbox,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  Grid,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  CardContent,
} from "@mui/material";

import {
  CardHeader,
  CenterModal,
  ModalHeadText,
  ModalInput,
  ModalLableText,
  ModalTextField,
  ToggleTextField,
  TypeButtonGroup,
  useModalToggle,
  ModalDatePicker,
} from "../Modal/Modal";

import {
  AddButton,
  BlueButton,
  DarkGreyButton,
  GreyButton,
} from "../components/button";

import * as React from "react";
import { useEffect } from "react";
import {
  BorderTable,
  MeasuringHead,
  ModalCard,
  MeasuringInfoHead,
  MeasuringResultHead,
} from "../components/const";
import moment from "moment";
import { SetCurrentEquipment, EditEquitment } from "../data/Data";
import dayjs from "dayjs";
import { Close, Delete } from "@mui/icons-material";
import { DeleteEquipment, EditEquipment } from "../data/Data";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { EquipmentSelect } from "../components/select";

export function MeasuringTable(props) {
  const [isChecked, setIsChecked] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedEquipmentSeq, setSelectedEquipmentSeq] = React.useState(0);

  const handleClickOpen = (seq) => {
    setSelectedEquipmentSeq(seq);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (seq) => {
    setOpen(false);
    DeleteEquipment(seq);
    props.setIsUpdated(true);
  };

  const selectedCheckbox = (equipment) => {
    SetCurrentEquipment(
      true,
      props.userId,
      props.currentSpaceId,
      equipment.seq
    );
    props.setIsUpdated(true);
  };

  const unselectCheckbox = (equipment) => {
    SetCurrentEquipment(
      false,
      props.userId,
      props.currentSpaceId,
      equipment.seq
    );
    props.setIsUpdated(true);
  };

  const filteredEquipments = (e) => {
    return props.equipmentRows.filter((row) => row.is_current);
  };

  useEffect(() => {}, [isChecked]);

  if (props.isResult) {
    return (
      <BorderTable>
        <TableHead>
          <TableRow>
            {MeasuringResultHead.map((it) => (
              <TableCell key={it.id} align={it.alignment} width={it.width}>
                {it.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredEquipments().map((row, idx) => (
            <TableRow key={idx}>
              <TableCell align="center">{idx}</TableCell>
              <TableCell align="center">{row.equipment}</TableCell>
              <TableCell align="center" style={{ width: 300 }}>
                {row.company}
              </TableCell>
              <TableCell align="center">{row.model_name}</TableCell>
              <TableCell align="center">{row.serial_number}</TableCell>
              <TableCell align="center">
                {moment(row.calibration_expiry_period).format("YYYY-MM-DD")}
              </TableCell>
              <TableCell align="center">{row.note}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </BorderTable>
    );
  } else if (props.isResult === false && props.state) {
    return (
      <BorderTable>
        <TableHead>
          <TableRow>
            {MeasuringInfoHead.map((it) => (
              <TableCell key={it.id} align={it.alignment} width={it.width}>
                {it.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredEquipments().map((row, idx) => (
            <TableRow key={idx}>
              <TableCell align="center">{idx}</TableCell>
              <TableCell align="center" style={{ width: 300 }}>
                {row.company}
              </TableCell>
              <TableCell align="center">{row.model_name}</TableCell>
              <TableCell align="center">{row.serial_number}</TableCell>
              <TableCell align="center">
                {moment(row.calibration_expiry_period).format("YYYY-MM-DD")}
              </TableCell>
              <TableCell align="center">{row.note}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </BorderTable>
    );
  } else {
    return (
      <BorderTable>
        <TableHead>
          <TableRow>
            {MeasuringHead.map((it) => (
              <TableCell key={it.id} align={it.alignment} width={it.width}>
                {it.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.equipmentRows.map((row, idx) => (
            <TableRow key={idx}>
              <TableCell align="center">
                <Checkbox
                  size="small"
                  checked={row.is_current === true}
                  onChange={() => {
                    if (row.is_current === true) {
                      unselectCheckbox(row);
                    } else {
                      selectedCheckbox(row);
                    }
                    isChecked === true
                      ? setIsChecked(false)
                      : setIsChecked(true);
                  }}
                  color="secondary"
                  sx={{
                    p: 0,
                    color: "#DDDDDD",
                    "&.Mui-checked": {
                      color: "#222222",
                    },
                  }}
                />
              </TableCell>
              <TableCell align="center">{idx}</TableCell>
              <TableCell align="center">{row.company}</TableCell>
              <TableCell align="center">{row.model_name}</TableCell>
              <TableCell align="center">{row.serial_number}</TableCell>
              <TableCell align="center">
                <Stack direction="row" justifyContent="center">
                  <Typography>
                    {moment(row.calibration_expiry_period).format("YYYY-MM-DD")}
                  </Typography>
                  <img
                    src={require("../media/calenderIcon.png")}
                    style={{
                      width: 15,
                      height: 15,
                      marginLeft: 10,
                      marginTop: 6,
                    }}
                    alt="calender img"
                  />
                </Stack>
              </TableCell>
              <TableCell align="center" style={{ paddingLeft: 10 }}>
                {row.note}
              </TableCell>
              {
                <TableCell align="center">
                  <Stack direction="row" justifyContent="center">
                    <OpenModalButton
                      userId={props.userId}
                      setIsUpdated={props.setIsUpdated}
                      row={row}
                    />
                  </Stack>
                </TableCell>
              }
              {
                <TableCell align="center">
                  <Stack direction="row" justifyContent="center">
                    <img
                      src={require("../media/deleteIcon.png")}
                      style={{ width: 14, height: 14, cursor: "pointer" }}
                      onClick={() => {
                        handleClickOpen(row.seq);
                      }}
                      alt="delete img"
                    />
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {
                          "사용된 모든 측정정보에 영향을 받습니다. 장비를 삭제하시겠습니까?"
                        }
                      </DialogTitle>
                      <DialogContent></DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            handleDelete(selectedEquipmentSeq);
                          }}
                        >
                          삭제
                        </Button>
                        <Button onClick={handleClose}> 닫기 </Button>
                      </DialogActions>
                    </Dialog>
                  </Stack>
                </TableCell>
              }
            </TableRow>
          ))}
        </TableBody>
      </BorderTable>
    );
  }
}

function OpenModalButton(props) {
  const { on, open, close } = useModalToggle();
  const [date, setDate] = React.useState(props.row.calibration_expiry_period);
  const [equipment, setEquipment] = React.useState(props.row.equipment);
  const [newEquipment, setNewEquipment] = React.useState(props.row);

  const companyChange = (e) => {
    newEquipment.company = e.target.value;
  };
  const modelNameChange = (e) => {
    newEquipment.model_name = e.target.value;
  };
  const serialNumberChange = (e) => {
    newEquipment.serial_number = e.target.value;
  };
  const noteChange = (e) => {
    newEquipment.note = e.target.value;
  };

  const handleSaveButtonClick = () => {
    newEquipment.user_id = props.userId;
    newEquipment.equipment = equipment;
    newEquipment.calibration_expiry_period = dayjs(date).format("YYYY-MM-DD");

    EditEquipment(newEquipment).then(() => {
      props.setIsUpdated(true);
      close();
    });
  };

  useEffect(() => {}, []);

  return (
    <>
      <AddButton
        style={{ height: "0px", padding: 0, margin: 0 }}
        startIcon={
          <img
            src={require("../media/penIcon.png")}
            style={{ width: 14, height: 14 }}
            alt="modify img"
          />
        }
        onClick={open}
      ></AddButton>
      <CenterModal open={on} onClose={close}>
        <ModalCard>
          <CardHeader direction="row" justifyContent="space-between">
            <ModalHeadText sx={{ pt: 1 }}>측정 장비 추가</ModalHeadText>
            <IconButton sx={{ mr: 5 }} size="small" onClick={close}>
              <Close />
            </IconButton>
          </CardHeader>
          <CardContent style={{ padding: 20, paddingTop: 25 }}>
            <Stack sx={{ p: 4, pr: 1 }}>
              <Grid container spacing={3} sx={{ backgroundColor: "#F8FAFB" }}>
                <Grid item xs={6} sx={{ pb: 5 }}>
                  <ModalLableText>측정 장비</ModalLableText>
                  <EquipmentSelect
                    equipment={equipment}
                    setEquipment={setEquipment}
                  />
                  <ModalLableText>제조사</ModalLableText>
                  <ModalInput
                    defaultValue={props.row.company}
                    onChange={companyChange}
                    style={{ marginBottom: 50 }}
                    size="small"
                  ></ModalInput>
                  <ModalLableText>모델명</ModalLableText>
                  <ModalInput
                    defaultValue={props.row.model_name}
                    onChange={modelNameChange}
                    size="small"
                  ></ModalInput>
                </Grid>
                <Grid item xs={6} sx={{ pr: 3 }}>
                  <ModalLableText>시리얼 번호</ModalLableText>
                  <ModalInput
                    defaultValue={props.row.serial_number}
                    onChange={serialNumberChange}
                    style={{ marginBottom: 40 }}
                    size="small"
                  ></ModalInput>
                  <ModalLableText>교정 만료 기간</ModalLableText>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <ModalDatePicker
                      defaultValue={props.row.calibration_expiry_period}
                      value={date}
                      onChange={(newDate) => {
                        setDate(newDate);
                      }}
                      renderInput={(params) => (
                        <ModalTextField
                          size="small"
                          {...params}
                          style={{ paddingBottom: 0, marginBottom: 50 }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <ModalLableText>비고</ModalLableText>
                  <ModalInput
                    defaultValue={props.row.note}
                    onChange={noteChange}
                    size="small"
                  ></ModalInput>
                </Grid>
              </Grid>
            </Stack>
            <Stack direction="row" spacing={2} justifyContent="center">
              <BlueButton
                onClick={() => {
                  handleSaveButtonClick(props.spaces);
                }}
              >
                저장
              </BlueButton>
              <GreyButton onClick={close}>닫기</GreyButton>
            </Stack>
          </CardContent>
        </ModalCard>
      </CenterModal>
    </>
  );
}
