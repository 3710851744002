import { FormControl, Select, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import * as React from 'react';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import dayjs from 'dayjs';
import { GetAch50CompareChart, SetAch50CompareChart } from '../data/Data';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const variants = [
  {
    id: 1,
    name: '공동주택',
  },
  {
    id: 2,
    name: '단독주택',
  },
  {
    id: 3,
    name: '교육시설',
  },
  {
    id: 4,
    name: '업무시설',
  },
  {
    id: 5,
    name: '제1종 근린생활시설',
  },
  {
    id: 6,
    name: '제2종 근린생활시설',
  },
  {
    id: 7,
    name: '문화 및 집회시설',
  },
  {
    id: 8,
    name: '노유자시설',
  },
  {
    id: 9,
    name: '기타',
  },
];

const presureTypes = [
  {
    id: 1,
    name: '가압',
    value: 'pressure',
  },
  {
    id: 2,
    name: '감압',
    value: 'decomp',
  },
  {
    id: 3,
    name: '가압/감압',
    value: 'pressure_decomp',
  },
];

export function UseSelect(props) {
  const [variantName, setVariantName] = React.useState([]);

  const [CheckList, setCheckList] = React.useState([]);
  const [IdList, setIdList] = React.useState([]);

  // React.useEffect(() => {
  //   GetAch50CompareChart().then((res) => {
  //     //props.setUseTypes(JSON.parse(res.data[0].uses));
  //   });
  // }, []);

  // 장바구니 리스트 props로 받아왔을 때, 배열 state에 전체 id값 넣기
  React.useEffect(() => {
    if (props.useTypes !== undefined) {
      let ids = [];
      props.useTypes.map((item, i) => {
        ids[i] = item.id;
      });

      setCheckList(ids);

      let preventDuplicate = variants.filter((variant) => ids.includes(variant.id));
      setVariantName(typeof preventDuplicate === 'string' ? preventDuplicate.split(',') : preventDuplicate);

      //debugger;
    }
  }, [props.useTypes]);

  // React.useEffect(() => {
  //   let preventDuplicate = variants.filter((variant) => CheckList.includes(variant.id));
  //   props.setUseTypes(preventDuplicate);

  //   setVariantName(typeof preventDuplicate === 'string' ? preventDuplicate.split(',') : preventDuplicate);
  //   // if (preventDuplicate.length === 0) {
  //   //   return;
  //   // }

  //   SetAch50CompareChart(
  //     props.chartNumber,
  //     JSON.stringify(preventDuplicate),
  //     dayjs(props.yearStart).format('YYYY-MM-DD'),
  //     dayjs(props.yearEnd).format('YYYY-MM-DD')
  //   )
  //     .then((res) => console.log(res))
  //     .catch((log) => console.log(log));
  //   //debugger;
  // }, [CheckList]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    let preventDuplicate = value.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
    preventDuplicate.sort((a, b) => (a.id > b.id ? 1 : -1));
    setVariantName(typeof preventDuplicate === 'string' ? preventDuplicate.split(',') : preventDuplicate);
    props.setUseTypes(preventDuplicate);
    var aa = variantName;
    //debugger;

    ////let uses = preventDuplicate.map((x) => x.name).join(',');
    SetAch50CompareChart(props.chartNumber, JSON.stringify(preventDuplicate), dayjs(props.yearStart).format('YYYY-MM-DD'), dayjs(props.yearEnd).format('YYYY-MM-DD'));
  };

  const onChangeEach = (e, id) => {
    // 체크할 시 CheckList에 id값 넣기
    let list = [];
    if (e.target.checked) {
      list = [...CheckList, id];
      setCheckList([...CheckList, id]);
      // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
    } else {
      list = CheckList.filter((checkedId) => checkedId !== id);
      setCheckList(CheckList.filter((checkedId) => checkedId !== id));
    }

    let preventDuplicate = variants.filter((variant) => list.includes(variant.id));
    props.setUseTypes(preventDuplicate);
    setVariantName(typeof preventDuplicate === 'string' ? preventDuplicate.split(',') : preventDuplicate);

    SetAch50CompareChart(props.chartNumber, JSON.stringify(preventDuplicate), dayjs(props.yearStart).format('YYYY-MM-DD'), dayjs(props.yearEnd).format('YYYY-MM-DD')).then().catch();
  };

  return (
    <Stack sx={{ width: 200 }}>
      <FormControl fullWidth>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={variantName}
          //onChange={(newValue) => {
          //  handleChange(newValue);
          //  onChangeEach(newValue)
          //}}
          renderValue={(selected) => selected.map((x) => x.name).join(', ')}
          MenuProps={MenuProps}>
          {variants.map((variant, index) => (
            <MenuItem key={index}>
              <Checkbox onChange={(e) => onChangeEach(e, variant.id)} checked={CheckList.includes(variant.id)} />
              {/* checked={props.useTypes.indexOf((x) => x.id === variant.id) > -1} /> */}
              <ListItemText primary={variant.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}

export function PresureSelect({ pressureType, onChange }) {
  //const [presureType, setPresureType] = React.useState('가압');

  const handleChange = (e) => {
    console.log('🚀 ~ file: select.js:196 ~ handleChange ~ value:', e);

    onChange(e);
  };

  const onChangeEach = (e, id) => {
    // let list = [];
    // if (e.target.checked) {
    //   list = [...CheckList, id];
    //   setCheckList([...CheckList, id]);
    // } else {
    //   list = CheckList.filter((checkedId) => checkedId !== id);
    //   setCheckList(CheckList.filter((checkedId) => checkedId !== id));
    // }
    // let preventDuplicate = variants.filter((variant) => list.includes(variant.id));
    // props.setUseTypes(preventDuplicate);
    // setVariantName(typeof preventDuplicate === 'string' ? preventDuplicate.split(',') : preventDuplicate);
    // SetAch50CompareChart(
    //   props.chartNumber,
    //   JSON.stringify(preventDuplicate),
    //   dayjs(props.yearStart).format('YYYY-MM-DD'),
    //   dayjs(props.yearEnd).format('YYYY-MM-DD')
    // )
    //   .then()
    //   .catch();
  };

  return (
    <Stack sx={{ width: 200 }}>
      <FormControl fullWidth>
        <Select
          defaultValue={pressureType}
          value={pressureType}
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          size="small">
          <MenuItem value={'pressure'}>가압</MenuItem>
          <MenuItem value={'decomp'}>감압</MenuItem>
          <MenuItem value={'pressure_decomp'}>가압/감압</MenuItem>
        </Select>
      </FormControl>
    </Stack>
  );
}

export function CompletionDateSelect({ completion, setCompletion }) {
  return (
    <Box sx={{ minWidth: 180, maxWidth: 200 }}>
      <FormControl fullWidth>
        <Select
          value={completion}
          onChange={(e) => {
            setCompletion(e.target.value);
          }}
          size="small">
          <MenuItem value={2010}>2010 ...</MenuItem>
          <MenuItem value={2020}>2020 ...</MenuItem>
          <MenuItem value={2030}>2030 ...</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export function EquipmentSelect({ equipment, setEquipment, defaultValue }) {
  return (
    <Stack sx={{ width: '100%', pb: 5 }}>
      <FormControl
        fullWidth
        style={{
          backgroundColor: 'white',
          display: 'flex',
          flex: 1,
          borderRadius: '20px',
          marginbottom: '16px',
        }}>
        <Select
          style={{
            backgroundColor: 'white',
            display: 'flex',
            flex: 1,
            borderRadius: '4px',
            marginbottom: '16px',
          }}
          defaultValue={equipment}
          value={equipment}
          onChange={(e) => {
            setEquipment(e.target.value);
          }}
          size="small">
          <MenuItem value={'Gauge'}>Gauge</MenuItem>
          <MenuItem value={'Blower door fan'}>Blower door fan</MenuItem>
        </Select>
      </FormControl>
    </Stack>
  );
}
