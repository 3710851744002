// ----------------------------------------------------------------------

export default function Typography(theme) {
  return {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: "MaruBuri-Regular";
          src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-10-21@1.0/MaruBuri-Regular.woff")
            format("woff");
          font-weight: normal;
          font-style: normal;
        }
      `,
    },
    MuiTypography: {
      styleOverrides: {
        paragraph: {
          marginBottom: theme.spacing(2),
        },
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
      },
    },
  };
}
