/**
 * index.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import { createRoot } from 'react-dom/client';

// Use consistent styling
// import 'sanitize.css/sanitize.css';

// Import root app
import { App } from './app/App';

import { HelmetProvider } from 'react-helmet-async';

import reportWebVitals from './reportWebVitals';

// Initialize languages
import { enableES5 } from 'immer';

// For IE 11
enableES5();

const MOUNT_NODE = document.getElementById('root');
const root = createRoot(MOUNT_NODE);

root.render(
  <HelmetProvider>
    <App />
  </HelmetProvider>
);
