import * as React from 'react';

import EnvironmentCondition from '../EnvironmentCondition';
import AirtightnessResult from '../AirtightnessResult';
import GlobalCompareBar from '../../Graph/GlobalCompareBar';
import AchBoxPlot from '../../Graph/AchBoxPlot';
import AirBoxPlot from '../../Graph/AirBoxPlot';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import { ColorPicker, useColor } from 'react-color-palette';
import 'react-color-palette/lib/css/styles.css';

import { AddButton, BlueButton, DarkGreyButton, GreyButton } from '../../components/button';
import { NoteTextField, SelectLable } from '../../components/const';
import { UseSelect } from '../../components/select';

import {
  Radio,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';

import { AdditionalInfoAirtightnessHead, GraphArea } from '../../components/const';
import {
  LableText,
  LableTextCenter,
  LableDescriptionTextCenter,
  LableDescriptionTextLeft,
  LableDescriptionBoldTextCenter,
  LableDescriptionBoldTextLeft,
  ModalCard,
  SpaceHeadExpert,
  BorderTable,
} from '../../components/const';

import { AchTable } from '../../tables/AchTable';
import {
  GetAirtightnessPerformCriteria,
  GetAirtightnessPerformCriteriaChart,
  AddAirtightnessPerformCriteria,
  GetAvgAirtightness,
  GetCurrentBuilding,
  GetCurrentSpace,
  GetAch50CompareChart,
  SetAch50CompareChart,
} from '../../data/Data';
import MenuItem from '@mui/material/MenuItem';
import ToggleButton from '@mui/material/ToggleButton';
import Box from '@mui/material/Box';
import { Biotech, Close, Delete } from '@mui/icons-material';

import { CardHeader, CenterModal, ModalHeadText, ModalInput, ModalLableText, ModalTextField, ToggleTextField, TypeButtonGroup, useModalToggle } from '../../Modal/Modal';
import _, { isNaN } from 'lodash';

const UseType = ['공동주택', '단독주택', '교육시설', '업무시설', '제1종 근린생활시설', '제2종 근린생활시설', '문화 및 집회시설', '노유자시설'];

function Step6(props) {
  const [isUpdated, setIsUpdated] = React.useState(true);
  const [airtightnessPerformCriteria, setAirtightnessPerformCriteria] = React.useState({});
  const [avgAirtightness, setAvgAirtightness] = React.useState([]);
  const [addOpen, setAddOpen] = React.useState(false);
  const [useType, setUseType] = React.useState(UseType[0]);
  const [useType2, setUseType2] = React.useState(UseType[0]);
  const [currentSpace, setCurrentSpace] = React.useState({});

  const [useTypes, setUseTypes] = React.useState();
  const [year1Start, setYear1Start] = React.useState(dayjs());
  const [year1End, setYear1End] = React.useState(dayjs());

  const [useTypes2, setUseTypes2] = React.useState();
  const [year2Start, setYear2Start] = React.useState(dayjs());
  const [year2End, setYear2End] = React.useState(dayjs());

  const loadData = () => {
    GetCurrentBuilding(props.userId).then((currentBuilding) => {
      GetCurrentSpace(currentBuilding.data[0].seq).then((currentSpace) => {
        setCurrentSpace(currentSpace.data[0]);

        let buildingName = currentBuilding.data[0].name;
        let spaceDongName = currentSpace.data[0].name_dong;
        let spaceDongNumber = currentSpace.data[0].name_number;
        if (buildingName === null || buildingName === undefined || buildingName === '') {
          buildingName = '-';
        }
        if (spaceDongName === null || spaceDongName === undefined || spaceDongName === '') {
          spaceDongName = '-';
        }
        if (spaceDongNumber === null || spaceDongNumber === undefined || spaceDongNumber === '') {
          spaceDongNumber = '-';
        }

        props.setTitle(buildingName + ' ' + spaceDongName + ' ' + spaceDongNumber);

        GetAvgAirtightness(props.useType, currentSpace.data[0].seq).then((res) => {
          setAvgAirtightness(res.data);
          props.setAvgAirtightness(res.data);
        });
        GetAirtightnessPerformCriteria(props.userType).then((res) => {
          setAirtightnessPerformCriteria(res.data);
        });

        GetAch50CompareChart().then((res) => {
          if (res.data.length > 0) {
            setUseTypes(JSON.parse(res.data[0].uses));
            setUseTypes2(JSON.parse(res.data[1].uses));

            setYear1Start(dayjs(res.data[0].start_year));
            setYear1End(dayjs(res.data[0].end_year));
            setYear2Start(dayjs(res.data[1].start_year));
            setYear2End(dayjs(res.data[1].end_year));

            //calcYear1Range(res.data[0].start_year, res.data[0].end_year);
            //calcYear2Range(res.data[1].start_year, res.data[1].end_year);
          }
        });
      });
    });
    setIsUpdated(false);
  };

  React.useEffect(() => {
    loadData();
  }, [isUpdated]);

  const numberRegex = (value, fix) => {
    return value
      ?.toFixed(fix)
      .toString()
      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  };

  const nameChange = (row) => {
    if (row.name === 'correlation') {
      return (
        <>
          Correlation, r<sup>2</sup>
        </>
      );
    } else if (row.name === 'ELA') {
      return (
        <>
          Effective Leakage Area, ELA<sub>4</sub>
        </>
      );
    } else if (row.name === 'EqLA') {
      return (
        <>
          Equivalent Leakage Area, EqLA<sub>10</sub>
        </>
      );
    } else if (row.name === 'normalized_leakage') {
      return (
        <>
          Normalized Leakage Area, EqLA<sub>10</sub>/외피면적
        </>
      );
    } else {
      return row.label;
    }
  };

  const Loader = (props) => {
    return (
      <TableRow key={props.row.name}>
        <TableCell align="left" style={{ paddingLeft: 30 }}>
          {nameChange(props.row)}
        </TableCell>
        <TableCell align="center">{props.row.unit}</TableCell>
        <TableCell align="center">
          {props.row.name === 'normalized_leakage' ? numberRegex(props.row.avg, 7) : props.row.name === '개수' ? numberRegex(props.row.avg, 0) : numberRegex(props.row.avg, 2)}
        </TableCell>
        <TableCell align="center">
          {props.row.name === 'normalized_leakage' ? numberRegex(props.row.avg_target, 7) : props.row.name === '개수' ? numberRegex(props.row.avg_target, 0) : numberRegex(props.row.avg_target, 2)}
        </TableCell>
        <TableCell align="center">{props.row.name === 'normalized_leakage' ? numberRegex(props.row.this, 7) : props.row.name === '개수' ? '-' : numberRegex(props.row.this, 2)}</TableCell>
      </TableRow>
    );
  };

  const calcYear1Range = (yearStart, yearEnd) => {
    let startDayJs = dayjs(yearStart);
    let endDayJs = dayjs(yearEnd);

    let start = startDayJs === null || isNaN(startDayJs.$y) ? 1900 : startDayJs.$y;
    let end = endDayJs === null || isNaN(endDayJs.$y) ? dayjs().$y : endDayJs.$y;

    if (start === 1900) {
      props.setYear1Range('전체');
      return '전체';
    } else {
      let str = end - start + 1 + '개년(' + start + '~' + end + ')';
      props.setYear1Range(str);
      return str;
    }
  };

  const calcYear2Range = (yearStart, yearEnd) => {
    let startDayJs = dayjs(yearStart);
    let endDayJs = dayjs(yearEnd);

    let start = startDayJs === null || isNaN(startDayJs.$y) ? 1900 : startDayJs.$y;
    let end = endDayJs === null || isNaN(endDayJs.$y) ? dayjs().$y : endDayJs.$y;

    if (start === 1900) {
      props.setYear2Range('전체');
      return '전체';
    } else {
      let str = end - start + 1 + '개년(' + start + '~' + end + ')';
      props.setYear2Range(str);
      return str;
    }
  };

  const handleSetAch50Chart = (chartNumber, useTypes, yearStart, yearEnd) => {
    SetAch50CompareChart(chartNumber, JSON.stringify(useTypes), dayjs(yearStart).format('YYYY-MM-DD'), dayjs(yearEnd).format('YYYY-MM-DD'));
  };

  return (
    <>
      <Stack sx={{ mt: 1, ml: 10, mr: 10 }}>
        <Stack direction="row" justifyContent="center">
          <Stack direction="column" justifyContent="center">
            <LableTextCenter sx={{ paddingBottom: 1 }}>부가 정보</LableTextCenter>
            <LableDescriptionTextLeft sx={{ paddingBottom: 2 }}>
              부가정보 페이지에서는 누적데이터와의 비교, 국내외 기밀성능 기준, 건물 유형별 기밀성능 비교 그래프를 제공합니다.
            </LableDescriptionTextLeft>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <LableText sx={{ pb: 2, paddingBottom: 5 }}>기밀성능 데이터 비교</LableText>
        </Stack>
        {avgAirtightness.length === 0 ? (
          <>
            <BorderTable>
              <TableHead>
                <TableRow>
                  {AdditionalInfoAirtightnessHead.map((head) => (
                    <TableCell key={head.id} align={head.alignment} width={head.width}>
                      {head.id === 'facility_average' ? head.label + ' (' + props.useType + ')' : head.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            </BorderTable>
            <Stack sx={{ marginTop: '9px' }} spacing={1}>
              <Skeleton variant="rectangular" height={38} />
              <Skeleton variant="rectangular" height={38} />
              <Skeleton variant="rectangular" height={38} />
              <Skeleton variant="rectangular" height={38} />
              <Skeleton variant="rectangular" height={38} />
              <Skeleton variant="rectangular" height={38} />
            </Stack>
          </>
        ) : (
          <BorderTable>
            <TableHead>
              <TableRow>
                {AdditionalInfoAirtightnessHead.map((head) => (
                  <TableCell key={head.id} align={head.alignment} width={head.width}>
                    {head.id === 'facility_average' ? head.label + ' (' + props.useType + ')' : head.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {avgAirtightness.map((row) => (
                <Loader key={row.name} row={row} />
              ))}
            </TableBody>
          </BorderTable>
        )}
      </Stack>

      <Stack sx={{ mt: 6, ml: 10, mr: 10 }}>
        <Stack direction="row" spacing={4} justifyContent="space-between">
          <Stack sx={{ flex: 1 }}>
            <Stack direction="row" justifyContent="space-between">
              <LableText sx={{ pb: 3 }}>ACH50 기밀성능 기준</LableText>
              {props.userType === 'admin' ? <OpenModalButton setIsUpdated={setIsUpdated} /> : <></>}
            </Stack>
            <AchTable userType={props.userType} criteria={airtightnessPerformCriteria} setIsUpdated={setIsUpdated} />
          </Stack>
          <Stack sx={{ flex: 1 }}>
            <LableText sx={{ pb: 3 }}>ACH50</LableText>
            <GraphArea>
              <GlobalCompareBar userType={props.userType} isUpdated={isUpdated} />
            </GraphArea>
          </Stack>
        </Stack>
      </Stack>

      <Stack sx={{ mt: 6, ml: 10, mr: 10 }}>
        <Stack direction="row" spacing={4} justifyContent="space-between">
          <Stack sx={{ flex: 1 }}>
            <Stack direction="row" justifyContent="space-between">
              <LableText sx={{ pb: 3 }}>ACH50 비교 그래프</LableText>
            </Stack>
            <Stack direction="row" justifyContent="right" alignItems="center" sx={{ pb: 1 }} spacing={4}>
              {props.userType === 'admin' ? (
                <Stack direction="row" alignItems="center">
                  <SelectLable>용도</SelectLable>
                  <UseSelect chartNumber={1} useTypes={useTypes} setUseTypes={setUseTypes} yearStart={year1Start} yearEnd={year1End} />
                </Stack>
              ) : (
                <></>
              )}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {props.userType === 'admin' ? (
                  <Stack direction="row" alignItems="center">
                    <SelectLable>기간</SelectLable>
                    <DatePicker
                      views={['year']}
                      label="Start"
                      value={year1Start}
                      minDate={dayjs('1990-01-01')}
                      maxDate={dayjs()}
                      onChange={(newValue) => {
                        setYear1Start(newValue);
                        handleSetAch50Chart(1, useTypes, newValue, year1End);
                      }}
                      renderInput={(params) => <TextField sx={{ width: 150 }} {...params} helperText={null} />}
                    />
                    &nbsp;&nbsp;~&nbsp;&nbsp;
                    <DatePicker
                      views={['year']}
                      label="End"
                      value={year1End}
                      minDate={year1Start}
                      maxDate={dayjs()}
                      onChange={(newValue) => {
                        setYear1End(newValue);
                        handleSetAch50Chart(1, useTypes, year1Start, newValue);
                      }}
                      renderInput={(params) => <TextField sx={{ width: 150 }} {...params} helperText={null} />}
                    />
                  </Stack>
                ) : (
                  <></>
                )}
              </LocalizationProvider>
            </Stack>
            <GraphArea>
              <AchBoxPlot space_id={props.currentSpace.seq} useTypes={useTypes} yearStart={year1Start} yearEnd={year1End} />
            </GraphArea>
            <Stack direction="row" justifyContent="center">
              <LableText sx={{ pt: 1 }}>
                건물 유형별&nbsp;{calcYear1Range(year1Start, year1End)}
                &nbsp;누적 데이터
              </LableText>
            </Stack>
          </Stack>
          <Stack sx={{ flex: 1 }}>
            <Stack direction="row" justifyContent="space-between">
              <LableText sx={{ pb: 3 }}>&nbsp;</LableText>
            </Stack>
            <Stack direction="row" justifyContent="right" alignItems="center" sx={{ pb: 1 }} spacing={4}>
              {props.userType === 'admin' ? (
                <Stack direction="row" alignItems="center">
                  <SelectLable>용도</SelectLable>
                  <UseSelect chartNumber={2} useTypes={useTypes2} setUseTypes={setUseTypes2} yearStart={year2Start} yearEnd={year2End} />
                </Stack>
              ) : (
                <></>
              )}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {props.userType === 'admin' ? (
                  <Stack direction="row" alignItems="center">
                    <SelectLable>기간</SelectLable>
                    <DatePicker
                      views={['year']}
                      label="Start"
                      value={year2Start}
                      minDate={dayjs('1990-01-01')}
                      maxDate={dayjs()}
                      onChange={(newValue) => {
                        setYear2Start(newValue);
                        handleSetAch50Chart(2, useTypes2, newValue, year2End);
                      }}
                      renderInput={(params) => <TextField sx={{ width: 150 }} {...params} helperText={null} />}
                    />
                    &nbsp;&nbsp;~&nbsp;&nbsp;
                    <DatePicker
                      views={['year']}
                      label="End"
                      value={year2End}
                      minDate={year2Start}
                      maxDate={dayjs()}
                      onChange={(newValue) => {
                        setYear2End(newValue);
                        handleSetAch50Chart(2, useTypes2, year2Start, newValue);
                      }}
                      renderInput={(params) => <TextField sx={{ width: 150 }} {...params} helperText={null} />}
                    />
                  </Stack>
                ) : (
                  <></>
                )}
              </LocalizationProvider>
            </Stack>
            <GraphArea>
              <AirBoxPlot space_id={props.currentSpace.seq} useTypes={useTypes2} yearStart={year2Start} yearEnd={year2End} />
            </GraphArea>
            <Stack direction="row" justifyContent="center">
              <LableText sx={{ pt: 1 }}>
                건물 유형별&nbsp;
                {calcYear2Range(year2Start, year2End)}
                &nbsp;누적 데이터
              </LableText>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}

function rgbToHex(r, g, b) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function rgbToString(color) {
  return color.rgb.r + ', ' + color.rgb.g + ', ' + color.rgb.b;
}

function OpenModalButton(props) {
  const { on, open, close } = useModalToggle();
  const [color, setColor] = useColor('hex', rgbToHex(255, 0, 0));
  const [openPalette, setOpenPalette] = React.useState(false);
  const [newCriteria, setNewCriteria] = React.useState(Criteria());
  const [isUpdated, setIsUpdated] = React.useState(true);

  const [group, setGroup] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [value, setValue] = React.useState(0);

  const groupChange = (e) => {
    newCriteria.group = e.target.value;
  };

  const titleChange = (e) => {
    newCriteria.title = e.target.value;
  };

  const valueChange = (e) => {
    newCriteria.value = isNumber(e.target.value) ? e.target.value : newCriteria.value;
    setIsUpdated(!isUpdated);
  };

  const handleSaveButton = () => {
    if (group === undefined || title === undefined || value === undefined) {
      alert('Group과 Title은 숫자형식 입력이 불가능합니다.');
      return;
    }

    debugger;
    AddAirtightnessPerformCriteria(group, rgbToString(color), title, value);
    setOpenPalette(false);
    props.setIsUpdated(true);
    close();
  };

  const isNumber = (number) => {
    const regex = /^[+-]?\d*(\.?\d*)?$/;
    return number === '' || regex.test(number);
  };

  const handleChangeGroup = (event) => {
    if (isNumber(event.target.value)) {
      setGroup();
    } else {
      setGroup(event.target.value);
    }
  };

  const handleChangeTitle = (event) => {
    if (isNumber(event.target.value)) {
      setTitle();
    } else {
      setTitle(event.target.value);
    }
  };

  const handleChangeValue = (event) => {
    if (isNumber(event.target.value)) {
      setValue(event.target.value);
    } else {
      setValue();
    }
  };

  React.useEffect(() => {}, [color, isUpdated]);

  return (
    <>
      <AddButton onClick={open}>추가</AddButton>
      <CenterModal open={on} onClose={close}>
        <ModalCard style={{ width: '800px' }}>
          <CardHeader direction="row" justifyContent="space-between">
            <ModalHeadText sx={{ pt: 1 }}>ACH50 기밀성능 기준</ModalHeadText>
            <IconButton sx={{ mr: 5 }} size="small" onClick={close}>
              <Close />
            </IconButton>
          </CardHeader>
          <CardContent style={{ padding: 20 }}>
            <Stack direction="row" justifyContent="space-between">
              <Stack style={{ width: '800px' }}>
                <Grid container spacing={0} justifyContent="center" sx={{ mb: 4, backgroundColor: '#F8FAFB' }}>
                  <Grid item xs={11} sx={{ mt: 4, mb: 2 }}>
                    <ModalLableText style={{ fontSize: 17, fontWeight: 400 }}>Group</ModalLableText>
                    <Stack direction="row" spacing={2} justifyContent="center">
                      <ModalTextField
                        onChange={(e) => {
                          handleChangeGroup(e);
                          //groupChange(e);
                        }}
                        size="small"></ModalTextField>
                      <p
                        onClick={() => {
                          setOpenPalette(!openPalette);
                        }}
                        style={{
                          cursor: 'pointer',
                          backgroundColor: `rgb(${rgbToString(color)})`,
                          width: 30,
                          height: 30,
                          marginTop: 5,
                          marginLeft: 20,
                        }}></p>
                    </Stack>
                    <ModalLableText style={{ fontSize: 17, fontWeight: 400 }}>Title</ModalLableText>
                    <ModalInput
                      onChange={(e) => {
                        handleChangeTitle(e);
                        //titleChange(e);
                      }}
                      size="small"></ModalInput>
                    <ModalLableText style={{ fontSize: 17, fontWeight: 400 }}>Value</ModalLableText>
                    <ModalInput
                      onChange={(e) => {
                        handleChangeValue(e);
                        //valueChange(e);
                      }}
                      size="small"></ModalInput>
                  </Grid>
                </Grid>
              </Stack>

              {openPalette === true ? (
                <Stack direction="column" spacing={2}>
                  <ColorPicker width={256} height={120} color={color} onChange={setColor} hideHSV dark />
                </Stack>
              ) : (
                <></>
              )}
            </Stack>
            <Stack direction="row" spacing={2} justifyContent="center">
              <BlueButton
                onClick={() => {
                  handleSaveButton();
                }}>
                저장
              </BlueButton>
              <GreyButton onClick={close}>닫기</GreyButton>
            </Stack>
          </CardContent>
        </ModalCard>
      </CenterModal>
    </>
  );
}

function Criteria(group = '', color = '', title = '', value = '') {
  return {
    group: group,
    color: color,
    title: title,
    value: value,
  };
}

export default Step6;
