import { endsWith } from 'lodash';
import { toImage } from 'plotly.js';
import React, { useRef } from 'react';
import Plot from 'react-plotly.js';
import { GetAirtightnessPerformCriteriaChart } from '../data/Data';

function GlobalCompareBar(props) {
  const [chartData, setChartData] = React.useState([]);

  React.useEffect(() => {
    GetAirtightnessPerformCriteriaChart(props.userType).then((res) => {
      let chart = [];
      res.data.map((data) => {
        chart.push(handleStartData(data.titles, data.start_values, data.group, data.color));
        chart.push(handleEndData(data.titles, data.end_values, data.group, data.color));
      });
      setChartData(chart);
    });
    // let xData = [1, 2, 3];

    // let chart = [
    //   handleStartData(["OO건물", "AA건물", "BB건물"], [0, 1.5, 3]),
    //   handleEndData(["OO건물", "AA건물", "BB건물"], [1.5, 1.5, 4.5], "Korea", "55,128,191"),
    //   handleStartData(["A", "B", "C"], [0, 2, 5]),
    //   handleEndData(["A", "B", "C"], [2, 2, 2], "Japan", "255,0,0"),
    // ];
    // setChartData(chart)

    //handleAnnotations(xData, ["OO건물", "AA건물", "BB건물",], ["OO건물", "AA건물", "BB건물"],)
    //handleAnnotations(xData, ["A", "B", "C"], ["A", "B", "C"])
  }, [props.isUpdated]);

  const handleStartData = (y, x) => {
    return {
      y: y,
      x: x,
      name: '',
      orientation: 'h',
      marker: {
        color: 'rgba(1,1,1,0.0)',
      },
      type: 'bar',
    };
  };

  const handleEndData = (y, x, name, color) => {
    return {
      y: y,
      x: x,
      name: name,
      type: 'bar',
      orientation: 'h',
      marker: {
        color: 'rgba(' + color + ',0.7)',
        line: {
          color: 'rgba(' + color + ',1.0)',
          width: 1,
        },
      },
    };
  };

  const handleAnnotations = (y, x, text) => {
    for (let i = 0; i < x.length; i++) {
      let result = {
        x: x[i],
        y: y[i],
        text: text[i],
        font: {
          size: 14,
        },
        showarrow: false,
      };
      layout.annotations.push(result);
    }
  };

  const layout = {
    width: '100%',
    height: 600,
    showlegend: true,
    annotations: [],
    paper_bgcolor: '#F4F4F4',
    plot_bgcolor: '#F4F4F4',
    barmode: 'stack',
    xaxis: {
      title: 'ACH50',
      zeroline: true,
      dtick: 0.5,
    },
    yaxis: {
      automargin: true,
      tickfont: {
        size: 11,
      },
      ticksuffix: '  ',
    },
    margin: { t: 30, b: 70, l: 20, r: 20 },
  };

  const ref = useRef();
  return (
    <>
      <Plot
        divId="global-compare-bar"
        ref={ref}
        data={chartData}
        layout={layout}
        style={{ border: '1px solid #DDDDDD', flex: 1 }}
        onAfterPlot={async () => {
          const res = await toImage(ref.current.el, { format: 'svg' });
          return res;
        }}
      />
    </>
  );
}

export default GlobalCompareBar;
