import * as React from 'react';
import { Stack, TableCell, TableHead, TableRow } from '@mui/material';
import { NoteTextField, BuildingInfoHead, SpaceInfoHead } from '../../components/const';
import {
  LableText,
  LableTextCenter,
  LableDescriptionTextCenter,
  LableDescriptionTextLeft,
  LableDescriptionBoldTextCenter,
  LableDescriptionBoldTextLeft,
  ModalCard,
  SpaceHeadExpert,
  BorderTable,
} from '../../components/const';
import { GetMeasureAirCondition, GetEquipments, GetCurrentBuilding, GetCurrentSpace } from '../../data/Data';

import { BuildingTable } from '../../tables/BuildingTable';
import { SpaceTable } from '../../tables/SpaceTable';
import { MeasuringTable } from '../../tables/MeasuringTable';
import EnvironmentCondition from '../EnvironmentCondition';
import AirtightnessResult from '../AirtightnessResult';

function StepFive(props) {
  const [pressureType, setPressureType] = React.useState('');
  const [airRows, setAirRows] = React.useState([]);
  const [equipmentRows, setEquipmentRows] = React.useState([]);
  const [currentSpace, setCurrentSpace] = React.useState({});
  const [currentBuilding, setCurrentBuilding] = React.useState({});
  const [note, setNote] = React.useState();

  const loadData = () => {
    GetCurrentBuilding(props.userId).then((currentBuilding) => {
      setCurrentBuilding(currentBuilding.data[0]);
      GetCurrentSpace(currentBuilding.data[0].seq).then((currentSpace) => {
        setCurrentSpace(currentSpace.data[0]);

        let buildingName = currentBuilding.data[0].name;
        let spaceDongName = currentSpace.data[0].name_dong;
        let spaceDongNumber = currentSpace.data[0].name_number;
        if (buildingName === null || buildingName === undefined || buildingName === '') {
          buildingName = '-';
        }
        if (spaceDongName === null || spaceDongName === undefined || spaceDongName === '') {
          spaceDongName = '-';
        }
        if (spaceDongNumber === null || spaceDongNumber === undefined || spaceDongNumber === '') {
          spaceDongNumber = '-';
        }

        props.setTitle(buildingName + ' ' + spaceDongName + ' ' + spaceDongNumber);
        props.setCurrentSpaceId(currentSpace.data[0].seq);
        if (currentSpace.data[0].note === null || currentSpace.data[0].note === 'null' || spaceDongNumber === undefined) {
          props.setNote(null);
          setNote(null);
        } else {
          props.setNote(currentSpace.data[0].note);
          setNote(currentSpace.data[0].note);
        }
        GetMeasureAirCondition(currentSpace.data[0].seq).then((response) => {
          setAirRows(response.data);
          setPressureType(response.data[0].pressure_type);
          props.setPressureType(response.data[0].pressure_type);
        });
        GetEquipments(props.userId, currentSpace.data[0].seq).then((response) => {
          setEquipmentRows(response.data);
        });
      });
    });
  };

  const handleNoteChange = (e) => {
    props.setNote(e.target.value);
    setNote(e.target.value);
  };

  React.useEffect(() => {
    props.setTab('air');
    loadData();
  }, []);

  return (
    <>
      <Stack sx={{ mt: 1, ml: 10, mr: 10 }}>
        <Stack direction="row" justifyContent="center">
          <Stack direction="column" justifyContent="center">
            <LableTextCenter sx={{ paddingBottom: 1 }}>5. 입력 내용 확인</LableTextCenter>
            <LableDescriptionTextLeft sx={{ paddingBottom: 1 }}>입력한 1~4번 항목에 대해 확인합니다.</LableDescriptionTextLeft>
            <LableDescriptionTextLeft sx={{ paddingBottom: 1 }}>추가적으로 측정과 관련된 특이 사항을 기록할 수 있습니다.</LableDescriptionTextLeft>
            <LableDescriptionTextLeft sx={{ paddingBottom: 1 }}># 단, 입력 완료를 누른 후에 1~4단계에서 입력된 항목을 수정할 수 없으므로 유의해야 합니다.</LableDescriptionTextLeft>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <LableText sx={{ pb: 2, paddingBottom: 5 }}>건물 정보</LableText>
        </Stack>
        <BorderTable>
          <TableHead>
            <TableRow>
              {BuildingInfoHead.map((it) => (
                <TableCell key={it.id} align={it.alignment} width={it.width}>
                  {it.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        </BorderTable>
        <BuildingTable userId={props.userId} userType={props.userType} buildings={[currentBuilding]} isResult={true} />
      </Stack>
      <Stack sx={{ mt: 6, ml: 10, mr: 10 }}>
        <Stack direction="row" justifyContent="space-between">
          <LableText sx={{ pb: 2, paddingBottom: 5 }}>측정 공간 정보</LableText>
        </Stack>
        <BorderTable>
          <TableHead>
            <TableRow>
              {SpaceInfoHead.map((it) => (
                <TableCell key={it.id} align={it.alignment} width={it.width} style={{ paddingLeft: 0 }}>
                  {it.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        </BorderTable>
        <SpaceTable userType={props.userType} currentSpace={currentSpace} currentBuilding={currentBuilding} buildingId={currentBuilding.seq} isResult={true} />
      </Stack>
      <EnvironmentCondition userId={props.userId} setTitle={props.setTitle} isResult={true} />
      <AirtightnessResult userId={props.userId} setTitle={props.setTitle} isResult={true} />

      <Stack sx={{ mt: 6, ml: 10, mr: 10 }}>
        <Stack direction="row" justifyContent="space-between">
          <LableText sx={{ pb: 2, paddingBottom: 5 }}>측정 장비</LableText>
        </Stack>
        <MeasuringTable userId={props.userId} userType={props.userType} currentSpaceId={currentSpace.seq} equipmentRows={equipmentRows} isResult={true} isGauge={false} />
      </Stack>
      <Stack sx={{ mt: 6, ml: 10, mr: 10 }}>
        <LableText sx={{ pb: 2 }}>기타 기록 사항</LableText>
        <NoteTextField
          placeholder=" - 확인 단계에서 기타 측정과 관련된 특이 사항 등을 기록"
          multiline
          rows={8}
          value={note}
          onChange={(e) => {
            handleNoteChange(e);
          }}></NoteTextField>
      </Stack>
    </>
  );
}
export default StepFive;
