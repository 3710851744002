import { PropaneSharp } from '@mui/icons-material';
import { Stack, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { set } from 'lodash';
import * as React from 'react';
import { useScratch } from 'react-use';
import { BorderTable, ResultHead, PressureHead, DecompHead, PressureDecompHead } from '../components/const';
import { EditMeasureAirCondition, GetMeasureAirCondition, GetCurrentBuilding, GetCurrentSpace } from '../data/Data';
import { handleCalc1_ACH50, handleCalc2_AirPermeability, handleCalc3_ELA, handleCalc4_EqLA, handleCalc5_normalized_leakage } from '../pages/AirtightnessFormula';

export function AirtightnessResultTable(props) {
  //const [airRows, setAirRows] = React.useState([]);
  const [currentBuilding, setCurrentBuliding] = React.useState({});
  const [currentSpace, setCurrentSpace] = React.useState({});
  const [isInit, setIsInit] = React.useState(true);
  const [isUpdated, setIsUpdated] = React.useState(false);
  const [rowChanged, setRowChanged] = React.useState(false);

  const isNumber = (number) => {
    const regex = /^[+-]?\d*(\.?\d*)?$/;
    return number === '' || regex.test(number);
  };

  const handleValueChange = (e, row, isPressure) => {
    if (isNumber(e.target.value)) {
      props.airRows.find((r) => {
        if (r.name === row.name) {
          if (isPressure === true) {
            r.value_pressure = e.target.value;
          } else {
            r.value_decomp = e.target.value;
          }
        }
      });
      setRowChanged(true);
    }
  };

  const handleBlur = (e) => {
    var newRows = props.airRows;
    if (e.target.value === '' || e.target.value.slice(-1) === '.' || e.target.value.slice(0) === '-') {
      props.setAirRows(newRows);
      setRowChanged(true);
    } else {
      EditMeasureAirCondition(newRows);
      props.setAirRows(newRows);
      setIsUpdated(true);
    }
  };

  const calculateAverage = (pressure, decomp) => {
    var p = pressure;
    var d = decomp;

    if (p === '') {
      p = 0;
    }
    if (d === '') {
      d = 0;
    }
    return (parseFloat(p) + parseFloat(d)) / 2;
  };

  const CalculateAirtightness = (row, isPressure) => {
    var volume = currentSpace.volume;
    var outside_area = currentSpace.outside_area;

    var nObj = props.airRows.find((row) => {
      if (row.name === 'n') return row;
    });
    var n = isPressure === true ? nObj.value_pressure : nObj.value_decomp;

    var cObj = props.airRows.find((row) => {
      if (row.name === 'c') return row;
    });
    var c = isPressure === true ? cObj.value_pressure : cObj.value_decomp;

    var q50rObj = props.airRows.find((row) => {
      if (row.name === 'Q50r') return row;
    });
    var q50r = isPressure === true ? q50rObj.value_pressure : q50rObj.value_decomp;

    n = n === '' ? 0 : n;
    c = c === '' ? 0 : c;
    q50r = q50r === '' ? 0 : q50r;

    var result = 0;

    switch (row.name) {
      case 'ACH50':
        result = handleCalc1_ACH50(volume, q50r);
        break;
      case 'air_permeability':
        result = handleCalc2_AirPermeability(outside_area, q50r);
        break;
      case 'ELA':
        result = handleCalc3_ELA(n, c);
        break;
      case 'EqLA':
        result = handleCalc4_EqLA(n, c);
        break;
      case 'normalized_leakage':
        result = handleCalc5_normalized_leakage(n, c, outside_area);
        break;
      default:
        result = 0;
        break;
    }
    var isValid = true;
    var newRows = props.airRows;
    newRows.map((r) => {
      if (r.name === row.name) {
        isPressure ? (r.value_pressure = result) : (r.value_decomp = result);
      }
      if (isNaN(parseInt(r.value_decomp)) || isNaN(parseInt(r.value_pressure))) {
        isValid = false;
      }
    });

    if (isValid) {
      EditMeasureAirCondition(newRows)
        .then()
        .catch((err) => console.log(err));
    }
    return result;
  };

  const numberRegex = (value, fix) => {
    return value
      .toFixed(fix)
      .toString()
      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  };

  const nameChange = (row) => {
    if (row.name === 'correlation') {
      return (
        <>
          Correlation, r<sup>2</sup>
        </>
      );
    } else if (row.name === 'ELA') {
      return (
        <>
          Effective Leakage Area, ELA<sub>4</sub>
        </>
      );
    } else if (row.name === 'EqLA') {
      return (
        <>
          Equivalent Leakage Area, EqLA<sub>10</sub>
        </>
      );
    } else if (row.name === 'normalized_leakage') {
      return (
        <>
          Normalized Leakage Area, EqLA<sub>10</sub>/외피면적
        </>
      );
    } else {
      return row.label;
    }
  };

  React.useEffect(() => {
    if (isInit) {
      GetCurrentBuilding(props.userId).then((currentBuilding) => {
        setCurrentBuliding(currentBuilding.data[0]);
        GetCurrentSpace(currentBuilding.data[0].seq).then((space) => {
          if (space.data.length > 0) {
            setCurrentSpace(space.data[0]);

            let buildingName = currentBuilding.data[0].name;
            let spaceDongName = space.data[0].name_dong;
            let spaceDongNumber = space.data[0].name_number;
            if (buildingName === null || buildingName === undefined || buildingName === '') {
              buildingName = '-';
            }
            if (spaceDongName === null || spaceDongName === undefined || spaceDongName === '') {
              spaceDongName = '-';
            }
            if (spaceDongNumber === null || spaceDongNumber === undefined || spaceDongNumber === '') {
              spaceDongNumber = '-';
            }

            props.setTitle(buildingName + ' ' + spaceDongName + ' ' + spaceDongNumber);
            GetMeasureAirCondition(space.data[0].seq).then((response) => {
              props.setAirRows(response.data);
              props.setPressureType(response.data[0].pressure_type);
              setIsInit(false);
            });
          }
        });
      });
    }

    if (rowChanged) {
      setRowChanged(false);
    }

    if (isUpdated) {
      GetMeasureAirCondition(currentSpace.seq).then((response) => {
        props.setAirRows(response.data);
        setIsUpdated(false);

        let buildingName = currentBuilding.name;
        let spaceDongName = currentSpace.name_dong;
        let spaceDongNumber = currentSpace.name_number;
        if (buildingName === null || buildingName === undefined || buildingName === '') {
          buildingName = '-';
        }
        if (spaceDongName === null || spaceDongName === undefined || spaceDongName === '') {
          spaceDongName = '-';
        }
        if (spaceDongNumber === null || spaceDongNumber === undefined || spaceDongNumber === '') {
          spaceDongNumber = '-';
        }

        props.setTitle(buildingName + ' ' + spaceDongName + ' ' + spaceDongNumber);
      });
    }
  }, [isInit, isUpdated, rowChanged]);

  var ResultHead = [];

  if (props.pressureType === 'pressure') {
    ResultHead = PressureHead;
  } else if (props.pressureType === 'decomp') {
    ResultHead = DecompHead;
  } else {
    ResultHead = PressureDecompHead;
  }

  if (props.isResult) {
    return (
      <BorderTable>
        <TableHead>
          <TableRow>
            {ResultHead.map((it) => (
              <TableCell key={it.id} align={it.alignment} width={it.width}>
                {it.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.airRows.map((row, idx) => (
            <TableRow key={idx}>
              <TableCell align="left" style={{ paddingLeft: 30 }}>
                {nameChange(row)}
                {/* {row.name === 'correlation' ? <>Correlation, r&#8319;</> : row.label}
                {row.label} */}
              </TableCell>
              <TableCell align="center">{row.name === 'c' ? <>㎥/h·Pa&#8319;</> : row.unit}</TableCell>
              {props.pressureType === 'pressure' || props.pressureType === 'pressure_decomp' ? (
                row.name === 'normalized_leakage' ? (
                  <TableCell align="center" style={{ paddingRight: 0 }}>
                    {numberRegex(row.value_pressure, 7)}
                  </TableCell>
                ) : (
                  <TableCell align="center" style={{ paddingRight: 0 }}>
                    {numberRegex(row.value_pressure, 2)}
                  </TableCell>
                )
              ) : (
                <></>
              )}
              {props.pressureType === 'decomp' || props.pressureType === 'pressure_decomp' ? (
                row.name === 'normalized_leakage' ? (
                  <TableCell align="center" style={{ paddingRight: 0 }}>
                    {numberRegex(row.value_decomp, 7)}
                  </TableCell>
                ) : (
                  <TableCell align="center" style={{ paddingRight: 0 }}>
                    {numberRegex(row.value_decomp, 2)}
                  </TableCell>
                )
              ) : (
                <></>
              )}
              {props.pressureType === 'pressure_decomp' ? (
                row.name === 'normalized_leakage' ? (
                  <TableCell align="center" style={{ paddingRight: 0 }}>
                    {numberRegex(calculateAverage(row.value_pressure, row.value_decomp), 7)}
                  </TableCell>
                ) : (
                  <TableCell align="center" style={{ paddingRight: 0 }}>
                    {numberRegex(calculateAverage(row.value_pressure, row.value_decomp), 2)}
                  </TableCell>
                )
              ) : (
                <></>
              )}
            </TableRow>
          ))}
        </TableBody>
      </BorderTable>
    );
  } else if (props.isResult === false && props.state) {
    return (
      <BorderTable>
        <TableHead>
          <TableRow>
            {ResultHead.map((it) => (
              <TableCell key={it.id} align={it.alignment} width={it.width}>
                {it.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.airRows.map((row, idx) => (
            <TableRow key={idx}>
              {row.is_required ? (
                <TableCell align="left" style={{ paddingLeft: 16 }}>
                  <Stack justifyContent="left" direction="row" spacing={1}>
                    <img src={require('../media/Vector.png')} style={{ width: 14, height: 10 }} alt="check img" />
                    <Typography align="left">{nameChange(row)}</Typography>
                  </Stack>
                </TableCell>
              ) : (
                <TableCell align="left" style={{ paddingLeft: 40 }}>
                  {nameChange(row)}
                  {/* {row.name === 'correlation' ? <>Correlation, r&#8319;</> : row.label}
                {row.label} */}
                </TableCell>
              )}
              <TableCell align="center">{row.name === 'c' ? <>㎥/h·Pa&#8319;</> : row.unit}</TableCell>
              {props.pressureType === 'pressure' || props.pressureType === 'pressure_decomp' ? (
                row.is_required ? (
                  <TableCell align="center" style={{ paddingRight: 0 }}>
                    {row.value_pressure}
                  </TableCell>
                ) : (
                  <TableCell align="center" style={{ paddingRight: 0 }}>
                    {numberRegex(CalculateAirtightness(row, true), 2)}
                  </TableCell>
                )
              ) : (
                <></>
              )}
              {props.pressureType === 'decomp' || props.pressureType === 'pressure_decomp' ? (
                row.is_required ? (
                  <TableCell align="center" style={{ paddingRight: 0 }}>
                    {row.value_decomp}
                  </TableCell>
                ) : (
                  <TableCell align="center" style={{ paddingRight: 0 }}>
                    {numberRegex(CalculateAirtightness(row, false), 2)}
                  </TableCell>
                )
              ) : (
                <></>
              )}
              {props.pressureType === 'pressure_decomp' ? (
                <TableCell align="center" style={{ paddingRight: 0 }}>
                  {numberRegex(calculateAverage(row.value_pressure, row.value_decomp), 2)}
                </TableCell>
              ) : (
                <></>
              )}
              {/* <TableCell align="left">{air.avg}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </BorderTable>
    );
  } else {
    return (
      <BorderTable>
        <TableHead>
          <TableRow>
            {ResultHead.map((it) => (
              <TableCell key={it.id} align={it.alignment} width={it.width}>
                {it.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.airRows.map((row, idx) => (
            <TableRow key={idx}>
              {row.is_required ? (
                <TableCell align="left" style={{ paddingLeft: 16 }}>
                  <Stack direction="row" justifyContent="left" alignItems="center" spacing={1}>
                    <img src={require('../media/Vector.png')} style={{ width: 14, height: 10 }} alt="check img" />
                    <Typography>{nameChange(row)}</Typography>
                  </Stack>
                </TableCell>
              ) : (
                <TableCell align="left" style={{ paddingLeft: 40 }}>
                  {nameChange(row)}
                </TableCell>
              )}
              <TableCell align="center">{row.name === 'c' ? <>㎥/h·Pa&#8319;</> : row.unit}</TableCell>
              {props.pressureType === 'pressure' || props.pressureType === 'pressure_decomp' ? (
                row.is_required ? (
                  <TableCell align="center" style={{ paddingRight: 0 }}>
                    <input
                      style={{
                        fontSize: '16px',
                        textAlign: 'center',
                        fontWeight: '100',
                        color: '#666666',
                        width: '100px',
                        height: '33px',
                        padding: 0,
                      }}
                      value={row.value_pressure === undefined ? '' : row.value_pressure}
                      onChange={(e) => {
                        handleValueChange(e, row, true);
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                    />
                  </TableCell>
                ) : row.name === 'normalized_leakage' ? (
                  <TableCell align="center" style={{ paddingRight: 0 }}>
                    {numberRegex(CalculateAirtightness(row, true), 7)}
                  </TableCell>
                ) : (
                  <TableCell align="center" style={{ paddingRight: 0 }}>
                    {numberRegex(CalculateAirtightness(row, true), 2)}
                  </TableCell>
                )
              ) : (
                <></>
              )}
              {props.pressureType === 'decomp' || props.pressureType === 'pressure_decomp' ? (
                row.is_required ? (
                  <TableCell align="center" style={{ paddingRight: 0 }}>
                    <input
                      style={{
                        fontSize: '16px',
                        textAlign: 'center',
                        fontWeight: '100',
                        color: '#666666',
                        width: '100px',
                        height: '33px',
                        padding: 0,
                      }}
                      value={row.value_decomp === undefined ? '' : row.value_decomp}
                      onChange={(e) => {
                        handleValueChange(e, row, false);
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                    />
                  </TableCell>
                ) : row.name === 'normalized_leakage' ? (
                  <TableCell align="center" style={{ paddingRight: 0 }}>
                    {numberRegex(CalculateAirtightness(row, false), 7)}
                  </TableCell>
                ) : (
                  <TableCell align="center" style={{ paddingRight: 0 }}>
                    {numberRegex(CalculateAirtightness(row, false), 2)}
                  </TableCell>
                )
              ) : (
                <></>
              )}
              {props.pressureType === 'pressure_decomp' ? (
                row.name === 'normalized_leakage' ? (
                  <TableCell align="center" style={{ paddingRight: 0 }}>
                    {numberRegex(calculateAverage(row.value_pressure, row.value_decomp), 7)}
                  </TableCell>
                ) : (
                  <TableCell align="center" style={{ paddingRight: 0 }}>
                    {numberRegex(calculateAverage(row.value_pressure, row.value_decomp), 2)}
                  </TableCell>
                )
              ) : (
                <></>
              )}
              {/* <TableCell align="left">{air.avg}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </BorderTable>
    );
  }
}
