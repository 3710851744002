import React from 'react';
import { Navigate, useNavigate, useRoutes, useLocation } from 'react-router-dom';
import IndexPage from './pages/IndexPage';
import Page404 from './pages/Page404';
import Main from './pages/Main';
import { Renewal } from './data/Data';
import SetAuthorizationToken from './utils/SetAuthorizationToken';

export default function Router() {
  const location = useLocation();
  const navigate = useNavigate();

  const [validToken, setValidToken] = React.useState(false);

  const routes = [
    { path: '/404/', element: <Page404 /> },
    { path: '*', element: <Navigate to="/" replace /> },
    {
      path: '/',
      element: <IndexPage validToken={validToken} setValidToken={setValidToken} />,
    },
    {
      path: '/main/:step/',
      element: <Main state={location.state} tab={'env'} />,
    },
    { path: '/main/', element: <Navigate to="/main/1/" replace /> },
  ];

  const handleNavigate = (path, state) => {
    if (path === `/`) {
      navigate(path, state);
    }
  };

  React.useEffect(() => {
    let delay = 119 * 60 * 1000; //(119분), accessToken유효기간 2시간, 그 전에 갱신

    let timerId = setTimeout(async function request() {
      try {
        var res = await Renewal();
        setValidToken(true);
      } catch (error) {
        if (error.response.status === 401) {
          setValidToken(false);
          SetAuthorizationToken('AccessToken', null);
          SetAuthorizationToken('RefreshToken', null);
          handleNavigate('/', {});
        }
      }

      timerId = setTimeout(request, delay);
    }, delay);
  }, []);

  return useRoutes(routes);
}
