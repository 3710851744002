import * as React from 'react';
import { Stack } from '@mui/material';
import { LableText, LableTextCenter, LableDescriptionTextCenter, LableDescriptionBoldTextCenter, ModalCard, BuildingHeadExpert, BorderTable } from '../components/const';
import { AirtightnessResultTable } from '../tables/AirtightnessResultTable';
import { AirtightnessButton } from '../components/button';
import ToggleButton from '@mui/material/ToggleButton';
import { CardHeader, CenterModal, ModalHeadText, ModalInput, ModalLableText, ModalTextField, ToggleTextField, TypeButtonGroup, useModalToggle } from '../Modal/Modal';
import { EditMeasureAirCondition } from '../data/Data';
import { PresureSelect } from '../components/select';

function AirtightnessResult(props) {
  const [pressureType, setPressureType] = React.useState('');
  const [airRows, setAirRows] = React.useState([]);
  const [currentBuilding, setCurrentBuliding] = React.useState({});
  const [currentSpace, setCurrentSpace] = React.useState({});
  const [isInit, setIsInit] = React.useState(true);
  const [isUpdated, setIsUpdated] = React.useState(false);
  const [rowChanged, setRowChanged] = React.useState(false);

  const Types = ['pressure', 'decomp', 'pressure_decomp'];

  const saveChangedPressureType = (pressureType) => {
    console.log('🚀 ~ file: AirtightnessResult.js:33 ~ saveChangedPressureType ~ pressureType:', pressureType);
    let newRows = airRows;
    newRows = newRows.map((row) => {
      return { ...row, pressure_type: pressureType };
    });

    EditMeasureAirCondition(newRows)
      .then()
      .catch((err) => console.log(err));
    setAirRows(newRows);
    setPressureType(pressureType);
    props.setPressureType(pressureType);
  };

  React.useEffect(() => {
    setPressureType('pressure');
  }, []);

  React.useEffect(() => {
    // if(isInit){
    //   GetCurrentBuilding(props.userId).then(currentBuilding => {
    //     setCurrentBuliding(currentBuilding.data[0]);
    //     GetCurrentSpace(currentBuilding.data[0].seq).then(space => {
    //       if(space.data.length > 0){
    //         setCurrentSpace(space.data[0]);
    //         props.setTitle(currentBuilding.data[0].name + ' ' + space.data[0].name_dong + ' ' + space.data[0].name_number);
    //         GetMeasureAirCondition(space.data[0].seq).then((response) => {
    //           setAirRows(response.data);
    //           setPressureType(response.data[0].pressure_type);
    //           setIsInit(false);
    //         });
    //       }
    //     })
    //   })
    // }
    // else{
    //   if(isUpdated){
    //     GetMeasureAirCondition(currentSpace.seq).then((response) => {
    //       setAirRows(response.data);
    //       setIsUpdated(false);
    //       props.setTitle(currentBuilding.name + ' ' + currentSpace.name_dong + ' ' + currentSpace.name_number);
    //     })
    //   }
    // }
  }, [pressureType]);

  if (props.isResult) {
    return (
      <>
        <Stack sx={{ mt: 6, ml: 10, mr: 10 }}>
          <Stack direction="row" alignItems="center" spacing={1} sx={{ pb: 2, paddingBottom: 5 }}>
            <LableText sx={{ pr: 2 }}>기밀성능 측정 결과</LableText>
          </Stack>
          <AirtightnessResultTable
            userId={props.userId}
            setTitle={props.setTitle}
            // currentSpace={currentSpace}
            pressureType={pressureType}
            setPressureType={setPressureType}
            airRows={airRows}
            setAirRows={setAirRows}
            // setIsUpdated={setIsUpdated}
            state={props.state}
            isResult={props.isResult}
            // rowChanged={rowChanged}
            // setRowChanged={setRowChanged}
          />
        </Stack>
      </>
    );
  } else if (props.isResult === false && props.state) {
    return (
      <>
        <Stack sx={{ mt: 4, ml: 10, mr: 10 }}>
          <AirtightnessResultTable
            userId={props.userId}
            setTitle={props.setTitle}
            // currentSpace={currentSpace}
            pressureType={pressureType}
            setPressureType={setPressureType}
            airRows={airRows}
            setAirRows={setAirRows}
            // setIsUpdated={setIsUpdated}
            state={props.state}
            isResult={props.isResult}
            // rowChanged={rowChanged}
            // setRowChanged={setRowChanged}
          />
        </Stack>
      </>
    );
  } else {
    return (
      <>
        <Stack sx={{ ml: 10, mr: 10 }}>
          <Stack direction="row" alignItems="center" spacing={1} sx={{ paddingBottom: 3 }}>
            <PresureSelect pressureType={pressureType} onChange={(e) => saveChangedPressureType(e)} />
            {/* <TypeButtonGroup
              value={Types.includes(pressureType) ? pressureType : ''}
              onChange={(e) => saveChangedPressureType(e.target.value)}
              exclusive
              color="primary">
              <ToggleButton value={'pressure'} style={{ marginTop: 10, width: 8, height: 20 }}>
                가압
              </ToggleButton>
              <ToggleButton value={'decomp'} style={{ marginTop: 10, width: 8, height: 20 }}>
                감압
              </ToggleButton>
              <ToggleButton value={'pressure_decomp'} style={{ marginTop: 10, width: 8, height: 20 }}>
                가압/감압
              </ToggleButton>
            </TypeButtonGroup> */}
          </Stack>
          <AirtightnessResultTable
            userId={props.userId}
            setTitle={props.setTitle}
            // currentSpace={currentSpace}
            pressureType={pressureType}
            setPressureType={setPressureType}
            airRows={airRows}
            setAirRows={setAirRows}
            // setIsUpdated={setIsUpdated}
            state={props.state}
            isResult={props.isResult}
            // rowChanged={rowChanged}
            // setRowChanged={setRowChanged}
          />
        </Stack>
      </>
    );
  }
}

export default AirtightnessResult;
