import * as React from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import EnvironmentCondition from '../EnvironmentCondition';
import AirtightnessResult from '../AirtightnessResult';

import { useLocation } from 'react-router-dom';
import { CardContent, Stack } from '@mui/material';
import { MainToggleButtonGroup } from '../../components/button';

import {
  LableText,
  LableTextCenter,
  LableDescriptionTextCenter,
  LableDescriptionTextLeft,
  LableDescriptionBoldTextCenter,
  LableDescriptionBoldTextLeft,
  ModalCard,
  SpaceHeadExpert,
  BorderTable,
} from '../../components/const';

function StepThree(props) {
  React.useEffect(() => {}, []);

  return (
    <CardContent style={{ padding: 0, background: '#fcfdff' }}>
      <Stack direction="column" justifyContent="center">
        <Stack direction="row" justifyContent="center" sx={{ paddingBottom: 3 }}>
          <MainToggleButtonGroup
            color="primary"
            value={props.tab}
            exclusive
            onChange={(e) => {
              props.setTab(props.tab === 'env' ? 'air' : 'env');
            }}>
            <ToggleButton style={{ justifycontent: 'center' }} value="env">
              3.1. 측정 조건 (Test Condition)
            </ToggleButton>
            <ToggleButton value="air">3.2. 기밀성능 측정 결과 (Airtightness Result)</ToggleButton>
          </MainToggleButtonGroup>
        </Stack>

        <Stack direction="row" justifyContent="center" alignItems="flex-start">
          <Stack style={{ width: '65vh' }} direction="column" justifyContent="center">
            <LableTextCenter sx={{ paddingBottom: 1 }}>3.1. 측정 조건</LableTextCenter>
            <LableDescriptionTextLeft sx={{ paddingBottom: 1 }}>측정 시 환경 조건을 입력합니다.</LableDescriptionTextLeft>
            <LableDescriptionTextLeft sx={{ paddingBottom: 1 }}>측정 조건은 실내외 온도, 외부 기압, 외부 풍속 및 실내외 습도 정보를 입력하게 됩니다.</LableDescriptionTextLeft>
            <LableDescriptionTextLeft sx={{ paddingBottom: 5 }}>외부 기압은 대기압인 101,325 Pa이 기본값으로 설정되어 있습니다.</LableDescriptionTextLeft>
          </Stack>
          <Stack style={{ width: '65vh' }} direction="column" justifyContent="center">
            <LableTextCenter sx={{ paddingBottom: 1 }}>3.2. 기밀성능 측정 결과</LableTextCenter>
            <LableDescriptionTextLeft sx={{ paddingBottom: 1 }}>기밀성능 측정 결과를 입력합니다.</LableDescriptionTextLeft>
            <LableDescriptionTextLeft sx={{ paddingBottom: 1 }}>
              가압, 감압, 가압/감압을 선택하여 측정을 통해 얻어지는 Airflow Exponent, Air Leakage Coefficient, Correlation, Air Leakage Rate at 50 Pa을 입력하게 됩니다.
            </LableDescriptionTextLeft>
            <LableDescriptionTextLeft sx={{ paddingBottom: 5 }}># 단, 가압/감압 선택시 가압과 감압에 대한 값을 각각 입력해주어야 평균값을 구할 수 있습니다.</LableDescriptionTextLeft>
          </Stack>
        </Stack>
      </Stack>
      {props.tab === 'env' ? (
        <EnvironmentCondition userId={props.userId} setTitle={props.setTitle} state={props.state} isResult={false} />
      ) : (
        <AirtightnessResult userId={props.userId} setTitle={props.setTitle} state={props.state} isResult={false} setPressureType={props.setPressureType} />
      )}
    </CardContent>
  );
}

export default StepThree;
