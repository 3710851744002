import moment from 'moment';

const HEIGHT = 2240;
export function drawPage2(canvas, img, id, graph1, graph2, graph3, avgAirtightness, year1Range, year2Range, currentBuilding) {
  const numbers = (value) => {
    try {
      return value.toFixed(2).toLocaleString('ko-KR');
    } catch (error) {
      return '-';
    }
  };

  return new Promise((resolve, reject) => {
    // =================================================
    // 디스플레이 크기 설정 (css 픽셀)
    // canvas.style.width = `${372}px`;
    // canvas.style.height = `${560}px`;

    // 메모리에 실제 크기 설정 (픽셀 밀도를 고려하여 크기 조정)
    // const dpr = window.devicePixelRatio;
    // //==================================================
    const ctx = document.querySelector(id).getContext('2d');

    ctx.drawImage(img, 0, 0, canvas.width, HEIGHT);
    ctx.drawImage(graph1, 140, 745, 1210, 720);
    ctx.drawImage(graph2, 140, 1550, 590, 390);
    ctx.drawImage(graph3, 760, 1550, 590, 390);

    ctx.font = '600 19px sans-serif';
    // ctx.fillText('-', 515, 374);
    // ctx.fillText('1/h @50 Pa', 515, 427);
    // ctx.fillText('m³/h㎡@50 Pa', 515, 480);
    // ctx.fillText('㎡ @4 Pa', 515, 532);
    // ctx.fillText('㎡ @10 Pa', 515, 584);
    // ctx.fillText('c㎡/㎡ @10 Pa', 515, 637);

    const aa = (t) => 1087 - ctx.measureText(t).width / 2;
    ctx.fillText(currentBuilding.building_type, aa(currentBuilding.building_type), 319);

    // const nameChange = (row) => {
    //   if (row.name === 'correlation') {
    //     return 'Correlation, r';
    //   } else if (row.name === 'ELA') {
    //     return 'Effective Leakage Area, ELA';
    //   } else if (row.name === 'EqLA') {
    //     return 'Equivalent Leakage Area, EqLA';
    //   } else if (row.name === 'normalized_leakage') {
    //     return 'Normalized Leakage Area, EqLA';
    //   } else {
    //     return row.label;
    //   }
    // };

    // const z = (t) => 155;
    // ctx.fillText(nameChange(avgAirtightness[0]), z(avgAirtightness[0].label), 374);
    // ctx.fillText(nameChange(avgAirtightness[1]), z(avgAirtightness[1].label), 427);
    // ctx.fillText(nameChange(avgAirtightness[2]), z(avgAirtightness[2].label), 480);
    // ctx.fillText(nameChange(avgAirtightness[3]), z(avgAirtightness[3].label), 532);
    // ctx.fillText(nameChange(avgAirtightness[4]), z(avgAirtightness[4].label), 582);
    // ctx.fillText(nameChange(avgAirtightness[5]), z(avgAirtightness[5].label), 635);

    // ctx.font = '12px Noto Sans KR';
    // ctx.fillText('4', 435, 536);
    // ctx.fillText('10', 468, 584);
    // ctx.fillText('10', 478, 637);

    // ctx.font = '22px Noto Sans KR';
    // ctx.fillText('/외피면적', 493, 635);

    const y = (t) => 615;
    ctx.fillText(avgAirtightness[0].unit, y(avgAirtightness[0].unit), 374);
    ctx.fillText(avgAirtightness[1].unit, y(avgAirtightness[1].unit), 427);
    ctx.fillText(avgAirtightness[2].unit, y(avgAirtightness[2].unit), 480);
    ctx.fillText(avgAirtightness[3].unit, y(avgAirtightness[3].unit), 532);
    ctx.fillText(avgAirtightness[4].unit, y(avgAirtightness[4].unit), 582);
    ctx.fillText(avgAirtightness[5].unit, y(avgAirtightness[5].unit), 635);

    const a = (t) => 980 - ctx.measureText(t).width;
    ctx.fillText(avgAirtightness[0].avg, a(avgAirtightness[0].avg), 374);
    ctx.fillText(numbers(avgAirtightness[1].avg), a(numbers(avgAirtightness[1].avg)), 427);
    ctx.fillText(numbers(avgAirtightness[2].avg), a(numbers(avgAirtightness[2].avg)), 480);
    ctx.fillText(numbers(avgAirtightness[3].avg), a(numbers(avgAirtightness[3].avg)), 532);
    ctx.fillText(numbers(avgAirtightness[4].avg), a(numbers(avgAirtightness[4].avg)), 582);
    ctx.fillText(numbers(avgAirtightness[5].avg), a(numbers(avgAirtightness[5].avg)), 635);

    const b = (t) => 1162 - ctx.measureText(t).width;
    ctx.fillText(avgAirtightness[0].avg_target, b(avgAirtightness[0].avg_target), 374);
    ctx.fillText(numbers(avgAirtightness[1].avg_target), b(numbers(avgAirtightness[1].avg_target)), 427);
    ctx.fillText(numbers(avgAirtightness[2].avg_target), b(numbers(avgAirtightness[2].avg_target)), 480);
    ctx.fillText(numbers(avgAirtightness[3].avg_target), b(numbers(avgAirtightness[3].avg_target)), 532);
    ctx.fillText(numbers(avgAirtightness[4].avg_target), b(numbers(avgAirtightness[4].avg_target)), 582);
    ctx.fillText(numbers(avgAirtightness[5].avg_target), b(numbers(avgAirtightness[5].avg_target)), 635);

    const c = (t) => 1330 - ctx.measureText(t).width;
    ctx.fillText('-', c('-'), 374);
    ctx.fillText(numbers(avgAirtightness[1].this), c(numbers(avgAirtightness[1].this)), 427);
    ctx.fillText(numbers(avgAirtightness[2].this), c(numbers(avgAirtightness[2].this)), 480);
    ctx.fillText(numbers(avgAirtightness[3].this), c(numbers(avgAirtightness[3].this)), 532);
    ctx.fillText(numbers(avgAirtightness[4].this), c(numbers(avgAirtightness[4].this)), 582);
    ctx.fillText(numbers(avgAirtightness[5].this), c(numbers(avgAirtightness[5].this)), 635);

    ctx.font = '20px sans-serif bold';
    const d = (t) => 1090 - ctx.measureText(t).width / 2;
    // ctx.fillText('건물 유형별', d('건물 유형별'), 319);

    //debugger;
    const e = (t) => 445 - ctx.measureText(t).width / 2;
    ctx.fillText('건물 유형별 ' + year1Range + ' 누적 데이터', e('건물 유형별 ' + year1Range + ' 누적 데이터'), 1980);

    const f = (t) => 1060 - ctx.measureText(t).width / 2;
    ctx.fillText('건물 유형별 ' + year2Range + ' 누적 데이터', f('건물 유형별 ' + year2Range + ' 누적 데이터'), 1980);

    ctx.font = '23px sans-serif';
    const g = (t) => 740 - ctx.measureText(t).width / 2;
    ctx.fillText(moment().format('YYYY년 MM월 D일'), g(moment().format('YYYY년 MM월 D일')), 2030);

    //ctx.scale(dpr, dpr);
    ctx.restore();
    resolve(canvas.toDataURL());
  });
}
