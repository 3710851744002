import { styled } from '@mui/material';
import { toImage } from 'plotly.js';
import printJS from 'print-js';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { BlueButton, PurpleButton } from '../components/button';
import { ApproveModal } from '../Modal/ApproveModal';
import { useModalToggle } from '../Modal/Modal';
import { AirType, CheckWidth } from './NoteComponent';
import { drawPage2 } from './PrintReport2';
import { GetMeasureAirCondition, GetMeasureEnvCondition, GetEquipments, GetCurrentBuilding, GetCurrentSpace, GetExpertNumber } from '../data/Data';

import moment from 'moment';
import { Try } from '@mui/icons-material';

const HiddenCanvas = styled('canvas')`
  position: fixed;
  top: -10000000px;
`;

const HEIGHT = 2240;

export function FontPreLoader() {
  return (
    <>
      <Helmet>
        <link rel="preload" as="font" href="https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-10-21@1.0/MaruBuri-Regular.woff" />
      </Helmet>
      <p style={{ fontFamily: 'sans-serif', opacity: 0 }}>abc</p>
    </>
  );
}

export function PrintReport1Type2Button(props) {
  const canvasRef = React.useRef([]);
  const { on, open, close } = useModalToggle();
  // eslint-disable-next-line

  const [pressureType, setPressureType] = React.useState('');
  const [airRows, setAirRows] = React.useState([]);
  const [envRows, setEnvRows] = React.useState([]);
  const [equipmentRows, setEquipmentRows] = React.useState([]);

  const [userId, setUserId] = React.useState(sessionStorage.getItem('userId'));
  const [userType, setUserType] = React.useState(sessionStorage.getItem('userType'));

  const getSessionCurrentBuilding = () => {
    var ss_cb = sessionStorage.getItem('currentBuilding');
    if (ss_cb === null || ss_cb === undefined || ss_cb === 'undefined') {
      GetCurrentBuilding(userId).then((res) => {
        return res.data[0];
      });
    } else {
      return JSON.parse(ss_cb);
    }
  };

  const getSessionCurrentSpace = () => {
    var ss_cs = sessionStorage.getItem('currentSpace');
    if (ss_cs === null || ss_cs === undefined || ss_cs === 'undefined') {
      GetCurrentSpace(currentBuilding.seq).then((res) => {
        return res.data[0];
      });
    } else {
      return JSON.parse(ss_cs);
    }
  };

  const [currentSpace, setCurrentSpace] = React.useState(getSessionCurrentSpace());
  const [currentBuilding, setCurrentBuilding] = React.useState(getSessionCurrentBuilding());
  const [measurePurpose, setMeasurePurpose] = React.useState('');

  React.useEffect(() => {
    if (currentSpace.measure_purpose === '법규, 제도, 기준, 인증') {
      setMeasurePurpose(300);
    } else if (currentSpace.measure_purpose === '건축물 시공 품질 시험') {
      setMeasurePurpose(585);
    } else if (currentSpace.measure_purpose === '연구 및 업무 데이터') {
      setMeasurePurpose(875);
    } else if (currentSpace.measure_purpose === '기타') {
      setMeasurePurpose(1150);
    } else {
      setMeasurePurpose(-100);
    }
  }, [currentSpace]);

  const loadData = async () => {
    GetMeasureAirCondition(currentSpace.seq).then((response) => {
      setAirRows(response.data);
      setPressureType(response.data[0].pressure_type);
    });
    GetMeasureEnvCondition(currentSpace.seq).then((response) => {
      setEnvRows(response.data);
    });
    GetEquipments(userId, currentSpace.seq).then((response) => {
      setEquipmentRows(response.data);
    });

    // GetCurrentBuilding(props.userId).then((currentBuilding) => {
    //   setCurrentBuilding(currentBuilding.data[0]);
    //   GetCurrentSpace(currentBuilding.data[0].seq).then((currentSpace) => {
    //     setCurrentSpace(currentSpace.data[0]);
    //     GetMeasureAirCondition(currentSpace.data[0].seq).then((response) => {
    //       setAirRows(response.data);
    //       setPressureType(response.data[0].pressure_type);
    //     });
    //     GetMeasureEnvCondition(currentSpace.data[0].seq).then((response) => {
    //       setEnvRows(response.data);
    //     });
    //     GetEquipments(props.userId, currentSpace.data[0].seq).then(
    //       (response) => {
    //         setEquipmentRows(response.data);
    //       }
    //     );
    //   });
    // });
  };

  const handleOpenReport = async () => {
    await loadData();
    open();
  };

  return (
    <>
      <PurpleButton
        disabled={props.disabled}
        variant="contained"
        onClick={async () => {
          await handleOpenReport();
        }}>
        보고서 출력
      </PurpleButton>
      <ApproveModal
        on={on}
        onClose={close}
        onConfirm={async () => {
          await printReport(
            canvasRef.current,
            close,
            props.pressureType,
            currentBuilding,
            currentSpace,
            envRows,
            airRows,
            equipmentRows,
            props.avgAirtightness,
            props.userType,
            props.year1Range,
            props.year2Range,
            measurePurpose
          );
        }}></ApproveModal>
      <HiddenCanvas ref={canvasRef} width="1488" height={HEIGHT} id="single-type-print-button" />
      {/* <FontPreLoader /> */}
    </>
  );
}

function getImageLoader(src) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = src;
    img.onerror = reject;
    img.onload = () => resolve(img);
  });
}

async function printReport(canvas, onClose, pressureType, currentBuilding, currentSpace, envRows, airRows, equipmentRows, avgAirtightness, userType, year1Range, year2Range, check) {
  try {
    let req = require('../media/report1_2.png');
    // let req = null;
    // if (userType === 'normal') {
    //   req = require('../media/report1_2_normal.png');
    // } else {
    //   req = require('../media/report1_2_expert.png');
    // }

    const res_expert_number = await GetExpertNumber();
    const report_number = res_expert_number.data[0].Column1;

    const page1Img = await getImageLoader(req);

    const page1 = await drawPage1(
      canvas,
      page1Img,
      pressureType === 'pressure' ? AirType.PRE : AirType.REDU,
      check,
      currentBuilding,
      currentSpace,
      envRows,
      airRows,
      equipmentRows,
      userType,
      report_number
    );

    let rawHtml = '';
    if (userType === 'normal') {
      rawHtml = `
      <p className="pageBreak" style="width: 21cm; height: 29.7cm; position: relative; border: 1px #fff solid;">PAGE BOX
        <img  src="${page1}" style="width: 100%; height: 100%; position: absolute; left:0; top:0;">
      </p>`;
    } else {
      const page2Img = await getImageLoader(require('../media/report2.png'));

      const graph1EncodedImg = await toImage(document.getElementById('global-compare-bar'), { format: 'svg', height: 720, width: 1210 });

      const graph2EncodedImg = await toImage(document.getElementById('ach-box-plot'), { format: 'svg', height: 390, width: 590 });

      const graph3EncodedImg = await toImage(document.getElementById('air-box-plot'), { format: 'svg', height: 390, width: 590 });

      const graph1Img = await getImageLoader(`${graph1EncodedImg}`);
      const graph2Img = await getImageLoader(`${graph2EncodedImg}`);
      const graph3Img = await getImageLoader(`${graph3EncodedImg}`);

      const page2 = await drawPage2(canvas, page2Img, '#single-type-print-button', graph1Img, graph2Img, graph3Img, avgAirtightness, year1Range, year2Range, currentBuilding);

      rawHtml = `
      <p className="pageBreak" style="width: 21cm; height: 29.7cm; position: relative; border: 1px #fff solid;">PAGE BOX
        <img  src="${page1}" style="width: 100%; height: 100%; position: absolute; left:0; top:0;">
      </p>
      <p className="pageBreak" style="width: 21cm; height: 29.7cm; position: relative; border: 1px #fff solid;">PAGE BOX
        <img  src="${page2}" style="width: 100%; height: 100%; position: absolute; left:0; top:0;">
      </p>`;
    }

    printJS({
      onPrintDialogClose: onClose,
      printable: rawHtml,
      type: 'raw-html',
      style: `
      @media print {
        @page {
          size: 21cm 29.7cm;
          margin: 0px;
        }

        * {
          margin:0;
          padding:0;
        }
      }
     `,
      targetStyles: ['*'],
    });
  } catch (err) {
    debugger;
  }
  onClose();
}

function drawPage1(canvas, img, airType, check, currentBuilding, currentSpace, envRows, airRows, equipmentRows, userType, report_number) {
  const numbers = (value, fix) => {
    try {
      return value.toFixed(fix).toLocaleString('ko-KR');
    } catch (error) {
      return '-';
    }
  };

  return new Promise(async (resolve, reject) => {
    // =================================================
    // 디스플레이 크기 설정 (css 픽셀)
    // canvas.style.width = `${372}px`;
    // canvas.style.height = `${560}px`;

    // 메모리에 실제 크기 설정 (픽셀 밀도를 고려하여 크기 조정)
    // const dpr = window.devicePixelRatio;
    // //==================================================

    const gaugeText = (equipmentRows) => {
      if (equipmentRows !== undefined) {
        let gauges = equipmentRows.filter((x) => {
          return x.equipment.toUpperCase() === 'GAUGE' && x.is_current;
        });

        if (gauges.length === 0) {
          return `Gauge -`;
        } else if (gauges.length === 1) {
          if (gauges[0].model_name === '' || gauges[0].model_name === null || gauges[0].model_name === undefined) {
            return `Gauge -`;
          } else {
            return `Gauge ${gauges[0].model_name}`;
          }
        } else {
          if (gauges[0].model_name === '' || gauges[0].model_name === null || gauges[0].model_name === undefined) {
            return `Gauge - (외 ${gauges.length - 1}개)`;
          } else {
            return `Gauge ${gauges[0].model_name}(외 ${gauges.length - 1}개)`;
          }
        }
      }
    };

    const blowerText = (equipmentRows) => {
      if (equipmentRows !== undefined) {
        let blowers = equipmentRows.filter((x) => {
          return x.equipment.toUpperCase() === 'BLOWER DOOR FAN' && x.is_current;
        });

        if (blowers.length === 0) {
          return `Fan -`;
        } else if (blowers.length === 1) {
          if (blowers[0].model_name === '' || blowers[0].model_name === null || blowers[0].model_name === undefined) {
            return `Fan -`;
          } else {
            return `Fan ${blowers[0].model_name}`;
          }
        } else {
          if (blowers[0].model_name === '' || blowers[0].model_name === null || blowers[0].model_name === undefined) {
            return `Fan - (외 ${blowers.length - 1}개)`;
          } else {
            return `Fan ${blowers[0].model_name}(외 ${blowers.length - 1}개)`;
          }
        }
      }
    };

    const username = sessionStorage.getItem('username');
    const company = sessionStorage.getItem('company');
    const training_name = sessionStorage.getItem('training_name');
    const expert_number = sessionStorage.getItem('expert_number');

    const ctx = document.querySelector('#single-type-print-button').getContext('2d');

    ctx.drawImage(img, 0, 0, canvas.width, HEIGHT);
    ctx.font = 'bold 52px sans-serif';
    ctx.fillText('✓', check, 532);

    ctx.font = '23px sans-serif';
    ctx.fillText('BAIP-' + moment().format('YYMM-') + report_number, 245, 393);

    const leng = (t) => 390 + ctx.measureText(t).width;

    if (userType === 'normal') {
      ctx.fillText('Non-registered (Certified ----, #----)', 380, 440);
    } else {
      ctx.fillText(username + ' (Cerified ' + training_name + ',  ' + expert_number + ')', 380, 440);
    }

    ctx.font = '20px sans-serif';
    if (currentBuilding.name === '' || currentBuilding.name === null || currentBuilding.name === undefined) {
      ctx.fillText('-', 305, 653);
    } else {
      ctx.fillText(currentBuilding.name, 305, 653);
    }
    ctx.fillText(currentBuilding.building_type, 305, 701);
    ctx.fillText(currentBuilding.location, 305, 749);
    ctx.fillText(currentBuilding.year, 305, 797);

    if (currentSpace.floor_number === '' || currentSpace.floor_number === null || currentSpace.floor_number === undefined) {
      ctx.fillText('-', 305, 844);
    } else {
      ctx.fillText(currentBuilding.floor_number, 305, 844);
    }

    if (currentSpace.height === '' || currentSpace.height === null || currentSpace.height === undefined) {
      ctx.fillText('-', 305, 893);
    } else {
      ctx.fillText(numbers(currentBuilding.height, 2), 305, 893);
    }

    if (currentSpace.gross_area === '' || currentSpace.gross_area === null || currentSpace.gross_area === undefined) {
      ctx.fillText('-', 305, 940);
    } else {
      ctx.fillText(numbers(currentBuilding.gross_area, 2), 305, 940);
    }

    if (currentSpace.name_dong === '' || currentSpace.name_dong === null || currentSpace.name_dong === undefined) {
      ctx.fillText('-', 650, 701);
    } else {
      ctx.fillText(currentSpace.name_dong, 650, 701);
    }

    if (currentSpace.name_number === '' || currentSpace.name_number === null || currentSpace.name_number === undefined) {
      ctx.fillText('-', 650, 749);
    } else {
      ctx.fillText(currentSpace.name_number, 650, 749);
    }

    if (currentSpace.floor_area === '' || currentSpace.floor_area === null || currentSpace.floor_area === undefined) {
      ctx.fillText('-', 650, 797);
    } else {
      ctx.fillText(numbers(currentSpace.floor_area, 2), 650, 797);
    }

    if (currentSpace.floor_height === '' || currentSpace.floor_height === null || currentSpace.floor_height === undefined) {
      ctx.fillText('-', 650, 844);
    } else {
      ctx.fillText(numbers(currentSpace.floor_height, 2), 650, 844);
    }

    ctx.fillText(numbers(currentSpace.volume, 2), 650, 893);
    ctx.fillText(numbers(currentSpace.outside_area, 2), 650, 940);

    if (company === '' || company === null || company === undefined) {
      ctx.fillText('-', 1095, 653);
    } else {
      ctx.fillText(company, 1095, 653);
    }

    if (username === '' || username === null || username === undefined) {
      ctx.fillText('-', 1095, 701);
    } else {
      ctx.fillText(username, 1095, 701);
    }

    if (currentSpace.measure_date === '' || currentSpace.measure_date === null || currentSpace.measure_date === undefined) {
      ctx.fillText('-', 1095, 749);
    } else {
      ctx.fillText(moment(currentSpace.measure_date).format('YYYY-MM-DD'), 1095, 749);
    }

    if (currentSpace.measure_standard === '' || currentSpace.measure_standard === null || currentSpace.measure_standard === undefined) {
      ctx.fillText('-', 1095, 797);
    } else {
      ctx.fillText(currentSpace.measure_standard, 1095, 797);
    }

    if (currentSpace.measure_point === '' || currentSpace.measure_point === 'null' || currentSpace.measure_point === null || currentSpace.measure_point === undefined) {
      ctx.fillText('-', 1095, 844);
    } else {
      ctx.fillText(currentSpace.measure_point, 1095, 844);
    }

    ctx.fillText(gaugeText(equipmentRows), 1095, 893);
    ctx.fillText(blowerText(equipmentRows), 1095, 940);

    try {
      var envRows2 = numbers((envRows[2].value_before + envRows[2].value_after) / 2, 0);
      ctx.fillText(envRows2, 365, 1082);
    } catch (error) {}

    try {
      var envRows3 = numbers((envRows[3].value_before + envRows[3].value_after) / 2, 1);
      ctx.fillText(envRows3, 365, 1132);
    } catch (error) {}

    try {
      var envRows0 = numbers((envRows[0].value_before + envRows[0].value_after) / 2, 1);
      ctx.fillText(envRows0, 765, 1082);
    } catch (error) {}

    try {
      var envRows1 = numbers((envRows[1].value_before + envRows[1].value_after) / 2, 1);
      ctx.fillText(envRows1, 765, 1132);
    } catch (error) {}

    try {
      var envRows4 = numbers((envRows[4].value_before + envRows[4].value_after) / 2, 1);
      ctx.fillText(envRows4, 1165, 1082);
    } catch (error) {}

    try {
      var envRows5 = numbers((envRows[5].value_before + envRows[5].value_after) / 2, 1);
      ctx.fillText(envRows5, 1165, 1132);
    } catch (error) {}

    // const nameChange = (row) => {
    //   if (row.name === 'correlation') {
    //     return 'Correlation, r';
    //   } else if (row.name === 'ELA') {
    //     return 'Effective Leakage Area, ELA';
    //   } else if (row.name === 'EqLA') {
    //     return 'Equivalent Leakage Area, EqLA';
    //   } else if (row.name === 'normalized_leakage') {
    //     return 'Normalized Leakage Area, EqLA';
    //   } else {
    //     return row.label;
    //   }
    // };

    // let position = 155;
    // ctx.fillText(nameChange(airRows[0]), position, 1323);
    // ctx.fillText(nameChange(airRows[1]), position, 1377);
    // ctx.fillText(nameChange(airRows[2]), position, 1427);
    // ctx.fillText(nameChange(airRows[3]), position, 1482);
    // ctx.fillText(nameChange(airRows[4]), position, 1535);
    // ctx.fillText(nameChange(airRows[5]), position, 1588);
    // ctx.fillText(nameChange(airRows[6]), position, 1642);
    // ctx.fillText(nameChange(airRows[7]), position, 1693);
    // ctx.fillText(nameChange(airRows[8]), position, 1747);

    // ctx.font = '12px Noto Sans KR';
    // ctx.fillText('2', 293, 1418);
    // ctx.fillText('4', 435, 1645);
    // ctx.fillText('10', 468, 1695);
    // ctx.fillText('10', 478, 1750);

    // ctx.font = '22px Noto Sans KR';
    // ctx.fillText('/외피면적', 493, 1747);

    const sup = <>&#8319;</>;
    const unit = '㎥/h·Pa' + sup.props.children;
    let position = 635;

    try {
      ctx.fillText(airRows[0].unit, position, 1323);
    } catch (error) {}

    try {
      ctx.fillText(unit, position, 1377);
    } catch (error) {}

    try {
      ctx.fillText(airRows[2].unit, position, 1427);
    } catch (error) {}

    try {
      ctx.fillText(airRows[3].unit, position, 1482);
    } catch (error) {}

    try {
      ctx.fillText(airRows[4].unit, position, 1535);
    } catch (error) {}

    try {
      ctx.fillText(airRows[5].unit, position, 1588);
    } catch (error) {}

    try {
      ctx.fillText(airRows[6].unit, position, 1642);
    } catch (error) {}

    try {
      ctx.fillText(airRows[7].unit, position, 1693);
    } catch (error) {}

    try {
      ctx.fillText(airRows[8].unit, position, 1747);
    } catch (error) {}

    // ctx.fillText("m³/h @50 Pa", 530, 1327);
    // ctx.fillText("1/h @50 Pa", 530, 1377);
    // ctx.fillText("m³/h㎡@50 Pa", 530, 1432);
    // ctx.fillText("-", 530, 1487);
    // ctx.fillText("m³/h㎡", 530, 1542);
    // ctx.fillText("%", 530, 1592);
    // ctx.fillText("㎡ @4 Pa", 530, 1647);
    // ctx.fillText("㎡ @10 Pa", 530, 1702);
    // ctx.fillText("c㎡/㎡ @10 Pa", 530, 1752);

    const avg = (t) => 1330 - ctx.measureText(t).width;

    if (airType === AirType.PRE) {
      //////////////////////////////////////////////////////
      var Q50r = numbers(airRows[0].value_pressure, 2);
      ctx.fillText(Q50r, avg(Q50r), 1323);

      var ACH50 = numbers(airRows[1].value_pressure, 2);
      ctx.fillText(ACH50, avg(ACH50), 1377);

      var air_permeability = numbers(airRows[2].value_pressure, 2);
      ctx.fillText(air_permeability, avg(air_permeability), 1427);

      var n = numbers(airRows[3].value_pressure, 2);
      ctx.fillText(n, avg(n), 1482);

      var c = numbers(airRows[4].value_pressure, 2);
      ctx.fillText(c, avg(c), 1535);

      var correlation = numbers(airRows[5].value_pressure, 2);
      ctx.fillText(correlation, avg(correlation), 1588);

      var ELA = numbers(airRows[6].value_pressure, 2);
      ctx.fillText(ELA, avg(ELA), 1642);

      var EqLA = numbers(airRows[7].value_pressure, 2);
      ctx.fillText(EqLA, avg(EqLA), 1693);

      var normalized_leakage = numbers(airRows[8].value_pressure, 7);
      ctx.fillText(normalized_leakage, avg(normalized_leakage), 1747);
    } else {
      //////////////////////////////////////////////////////
      var Q50r = numbers(airRows[0].value_decomp, 2);
      ctx.fillText(Q50r, avg(Q50r), 1323);

      var ACH50 = numbers(airRows[1].value_decomp, 2);
      ctx.fillText(ACH50, avg(ACH50), 1377);

      var air_permeability = numbers(airRows[2].value_decomp, 2);
      ctx.fillText(air_permeability, avg(air_permeability), 1427);

      var n = numbers(airRows[3].value_decomp, 2);
      ctx.fillText(n, avg(n), 1482);

      var c = numbers(airRows[4].value_decomp, 2);
      ctx.fillText(c, avg(c), 1535);

      var correlation = numbers(airRows[5].value_decomp, 2);
      ctx.fillText(correlation, avg(correlation), 1588);

      var ELA = numbers(airRows[6].value_decomp, 2);
      ctx.fillText(ELA, avg(ELA), 1642);

      var EqLA = numbers(airRows[7].value_decomp, 2);
      ctx.fillText(EqLA, avg(EqLA), 1693);

      var normalized_leakage = numbers(airRows[8].value_decomp, 7);
      ctx.fillText(normalized_leakage, avg(normalized_leakage), 1747);
    }

    ctx.font = '23px sans-serif';
    const e = (t) => 740 - ctx.measureText(t).width / 2;
    ctx.fillText(moment().format('YYYY년 MM월 D일'), e(moment().format('YYYY년 MM월 D일')), 2030);

    ctx.font = '600 19px sans-serif';
    ctx.fillText(airType === AirType.PRE ? '가압' : '감압', 1125, 1270);
    //ctx.scale(dpr, dpr);
    ctx.restore();
    resolve(canvas.toDataURL());
  });
}
