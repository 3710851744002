import {
  Radio,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';
import * as React from 'react';
import { BorderTable, SpaceInfoHead, SpaceHead, SpaceHeadExpert } from '../components/const';
import { DeleteSpace, EditSpace, GetSpacs } from '../data/Data';
import { AddButton, BlueButton, DarkGreyButton, GreyButton } from '../components/button';
import { CardHeader, CenterModal, ModalHeadText, ModalInput, ModalLableText, ModalTextField, ToggleTextField, TypeButtonGroup, useModalToggle, ModalDatePicker } from '../Modal/Modal';
import { LableText, ModalCard } from '../components/const';

import ToggleButton from '@mui/material/ToggleButton';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import moment from 'moment';
import { Close, Delete } from '@mui/icons-material';
import { DeleteBuilding, EditBuilding, SetCurrentSpace } from '../data/Data';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const UseType = ['공동주택', '단독주택', '교육시설', '업무시설', '제1종 근린생활시설', '제2종 근린생활시설', '문화 및 집회시설', '노유자시설'];

export function SpaceTable(props) {
  const userType = props.userType;
  const [open, setOpen] = React.useState(false);
  const [currentSpaceSeq, setCurrentSpaceSeq] = React.useState(0);
  const [title, setTitle] = React.useState('');

  const handleClickOpen = (seq) => {
    setCurrentSpaceSeq(seq);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (seq) => {
    DeleteSpace(seq);
    setOpen(false);
    if (props.spaces.length > 0 && props.currentSpace.seq === seq) {
      SetCurrentSpace(props.spaces[0].seq, props.buildingId);
    }
    props.setIsUpdated(true);
  };

  React.useEffect(() => {
    let spaceDongName = props.currentSpace.name_dong;
    let spaceDongNumber = props.currentSpace.name_number;
    if (spaceDongName === null || spaceDongName === undefined || spaceDongName === '') {
      spaceDongName = '-';
    }
    if (spaceDongNumber === null || spaceDongNumber === undefined || spaceDongNumber === '') {
      spaceDongNumber = '-';
    }

    setTitle(spaceDongName + ' 동 ' + spaceDongNumber + ' 호');
  }, [props.currentSpace]);

  return (
    <BorderTable>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'선택한 측정 정보를 리스트에서 삭제하시겠습니까?'}</DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleDelete(currentSpaceSeq);
            }}>
            삭제
          </Button>
          <Button onClick={handleClose}> 닫기 </Button>
        </DialogActions>
      </Dialog>
      {props.isResult === true ? (
        <>
          <TableBody>
            {
              <>
                <TableRow>
                  <TableCell align="left" style={{ width: '40%', paddingLeft: 200 }}>
                    측정 대상 공간명
                  </TableCell>
                  <TableCell align="center">{title}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" style={{ width: '40%', paddingLeft: 200 }}>
                    측정 감독자
                  </TableCell>
                  <TableCell align="center">{props.currentSpace.supervisor}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" style={{ width: '40%', paddingLeft: 200 }}>
                    측정 일자
                  </TableCell>
                  <TableCell align="center">{moment(props.currentSpace.measure_date).format('YYYY-MM-DD')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" style={{ width: '40%', paddingLeft: 200 }}>
                    측정 방법
                  </TableCell>
                  <TableCell align="center">{props.currentSpace.measure_point}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" style={{ width: '40%', paddingLeft: 200 }}>
                    측정 규격
                  </TableCell>
                  <TableCell align="center">{props.currentSpace.measure_standard}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" style={{ width: '40%', paddingLeft: 200 }}>
                    측정 대상공간의 총 바닥면적 (㎡)
                  </TableCell>
                  <TableCell align="center">{props.currentSpace.floor_area}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" style={{ width: '40%', paddingLeft: 200 }}>
                    측정 대상공간의 총 높이 (m)
                  </TableCell>
                  <TableCell align="center">{props.currentSpace.floor_height}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" style={{ width: '40%', paddingLeft: 200 }}>
                    측정 대상공간의 총 체적 (㎥)
                  </TableCell>
                  <TableCell align="center">{props.currentSpace.volume}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" style={{ width: '40%', paddingLeft: 200 }}>
                    측정 대상공간의 총 외피면적 (㎡)
                  </TableCell>
                  <TableCell align="center">{props.currentSpace.outside_area}</TableCell>
                </TableRow>
              </>
            }
          </TableBody>
        </>
      ) : (
        <>
          <TableBody>
            {props.spaces.map((space, idx) => (
              <TableRow key={idx}>
                <TableCell align="center" style={{ width: '4%' }}>
                  <TableRadioButton
                    align="center"
                    value={space.is_current === true}
                    setValue={() => [SetCurrentSpace(space.seq, props.buildingId), props.setIsUpdated(true), props.setState(space.state)]}
                  />
                </TableCell>
                <TableCell align="center" style={{ width: '5%' }}>
                  {idx + 1}
                </TableCell>
                <TableCell align="center" style={{ width: '10%' }}>
                  {space.name_dong}
                </TableCell>
                <TableCell align="center" style={{ width: '10%' }}>
                  {space.name_number}
                </TableCell>
                <TableCell align="center" style={{ width: '5%' }}>
                  <Stack direction="row" justifyContent="center">
                    {space.photo72 ? <img src={window.location.origin + space.photo72} style={{ width: 32, height: 26 }} alt="space img" /> : <></>}
                  </Stack>
                </TableCell>
                <TableCell align="center" style={{ width: '10%' }}>
                  {moment(space.measure_date).format('YYYY-MM-DD')}
                </TableCell>
                <TableCell align="center" style={{ width: '10%' }}>
                  {space.measure_standard}
                </TableCell>
                <TableCell align="center" style={{ width: '10%' }}>
                  {space.measure_point}
                </TableCell>
                <TableCell align="center" style={{ width: '10%' }}>
                  {space.floor_area}
                </TableCell>
                {space.state === true ? (
                  <TableCell align="center" style={{ color: '#3D4ABC', width: '10%' }}>
                    입력 완료
                  </TableCell>
                ) : (
                  <TableCell align="center" style={{ color: '#FF0000', width: '10%' }}>
                    입력 중
                  </TableCell>
                )}
                {userType === 'admin' ? (
                  <TableCell align="center" style={{ width: '5%' }}>
                    <Stack direction="row" justifyContent="center">
                      <OpenModalButton spaces={props.spaces} setSpaces={props.setSpaces} currentSpace={space} setIsUpdated={props.setIsUpdated} image={space.photo} />
                    </Stack>
                  </TableCell>
                ) : space.state === false ? (
                  <TableCell align="center" style={{ width: '5%' }}>
                    <Stack direction="row" justifyContent="center">
                      <OpenModalButton spaces={props.spaces} setSpaces={props.setSpaces} currentSpace={space} setIsUpdated={props.setIsUpdated} image={space.photo} />
                    </Stack>
                  </TableCell>
                ) : (
                  <TableCell align="center" style={{ width: '5%' }}></TableCell>
                )}
                {userType === 'admin' ? (
                  <TableCell align="center" style={{ width: '5%' }}>
                    <Stack direction="row" justifyContent="center">
                      <img
                        src={require('../media/deleteIcon.png')}
                        style={{ width: 14, height: 14, cursor: 'pointer' }}
                        onClick={() => {
                          handleClickOpen(space.seq);
                        }}
                        alt="delete img"
                      />
                    </Stack>
                  </TableCell>
                ) : space.state === false ? (
                  <TableCell align="center" style={{ width: '5%' }}>
                    <Stack direction="row" justifyContent="center">
                      <img
                        src={require('../media/deleteIcon.png')}
                        style={{ width: 14, height: 14, cursor: 'pointer' }}
                        onClick={() => {
                          handleClickOpen(space.seq);
                        }}
                        alt="delete img"
                      />
                    </Stack>
                  </TableCell>
                ) : (
                  <TableCell align="center" style={{ width: '5%' }}></TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </>
      )}
    </BorderTable>
  );
}

export function TableRadioButton({ value, setValue }) {
  return (
    <div>
      <Radio
        checked={value === true}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        value={value}
        size="small"
        sx={{ p: 0, color: '#DDDDDD', '&.Mui-checked': { color: '#222222' } }}
      />
    </div>
  );
}

function OpenModalButton(props) {
  const [useType, setUseType] = React.useState(UseType.APRT);
  const { on, open, close } = useModalToggle();
  const [date, setDate] = React.useState(props.currentSpace.measure_date);
  const [newSpace, setNewSpace] = React.useState(props.currentSpace);
  const selectedImageRef = React.useRef(null);
  const [selectedImage, setSelectedImage] = React.useState(props.image);
  const [selectedFile, setSelectedFile] = React.useState(props.file);
  const [measurePoint, setMeasurePoint] = React.useState(props.currentSpace.measure_point);
  const [measurePurpose, setMeasurePurpose] = React.useState(props.currentSpace.measure_purpose);
  const [measureStandard, setMeasureStandard] = React.useState(props.currentSpace.measure_standard);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [confirmMsg, setConfirmMsg] = React.useState('');

  const [floorArea, setFloorArea] = React.useState(props.currentSpace.floorArea);
  const [floorHeight, setFloorHeight] = React.useState(props.currentSpace.floor_height);
  const [volume, setVolume] = React.useState(props.currentSpace.volume);
  const [outsideArea, setOutsideArea] = React.useState(props.currentSpace.outsideArea);

  const isNumber = (number) => {
    const regex = /^(\d+)?(\.\d*)?$/;
    return number !== '' && regex.test(number);
  };

  const nameDongChange = (e) => {
    newSpace.name_dong = e.target.value;
  };
  const nameNumberChange = (e) => {
    newSpace.name_number = e.target.value;
  };
  const supervisorChange = (e) => {
    newSpace.supervisor = e.target.value;
  };
  // const measurePurposeChange = (e) => {
  //   newSpace.measure_purpose = e.target.value;
  // };
  // const measureStandardChange = (e) => {
  //   newSpace.measure_standard = e.target.value;
  // };
  const floorAreaChange = (e) => {
    if (isNumber(e.target.value)) {
      newSpace.floor_area = e.target.value;
      setFloorArea(e.target.value);
    }
  };
  const floorHeightChange = (e) => {
    if (isNumber(e.target.value)) {
      newSpace.floor_height = e.target.value;
      setFloorHeight(e.target.value);
    }
  };
  const volumeChange = (e) => {
    if (isNumber(e.target.value)) {
      newSpace.volume = e.target.value;
      setVolume(e.target.value);
    }
  };
  // const volumeChange = (e) => {
  //   if (isNumber(e.target.value)) {
  //     if (e.target.value.startsWith('0')) {
  //       if (e.target.value.startsWith('0.')) {
  //         newSpace.volume = e.target.value;
  //         setVolume(e.target.value);
  //       }
  //     } else {
  //       newSpace.volume = e.target.value;
  //       setVolume(e.target.value);
  //     }
  //   }
  // };
  const outsideAreaChange = (e) => {
    if (isNumber(e.target.value)) {
      newSpace.outside_area = e.target.value;
      setOutsideArea(e.target.value);
    }
  };
  // const volumeChange = (e) => {
  //   newSpace.volume = e.target.value;
  // };
  // const outsideAreaChange = (e) => {
  //   newSpace.outside_area = e.target.value;
  // };
  const handleSaveButtonClick = (seq) => {
    if (newSpace.volume.toString().startsWith('0') || newSpace.outside_area.toString().startsWith('0')) {
      setConfirmMsg('체적 또는 외피면적에는 0을 입력할 수 없습니다.');
      setConfirmOpen(true);
      return;
    }
    if (newSpace.supervisor === '' || newSpace.measure_standard === '' || newSpace.volume === '' || newSpace.outside_area === '') {
      setConfirmMsg('필수 값이 입력되지 않았습니다.');
      setConfirmOpen(true);
    } else {
      newSpace.measure_date = dayjs(date).format('YYYY-MM-DD');

      debugger;
      newSpace.floor_area = newSpace.floor_area ?? '';
      newSpace.floor_height = newSpace.floor_height ?? '';
      newSpace.volume = newSpace.volume ?? '';
      newSpace.outside_area = newSpace.outside_area ?? '';

      newSpace.measure_point = measurePoint;
      newSpace.measure_purpose = measurePurpose;
      newSpace.measure_standard = measureStandard;
      //newSpace.photo = selectedImage;
      newSpace.file = selectedFile;
      newSpace.filename = selectedFile?.name;

      EditSpace(newSpace).then(() => {
        props.setIsUpdated(true);
        close();
      });
    }
  };

  const handleSelectImageClick = (e) => {
    selectedImageRef.current.click();
  };

  const handleSelectedImageChange = (e) => {
    let url = URL.createObjectURL(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    setSelectedImage(url);
  };

  const deleteImage = () => {
    setSelectedFile({});
    setSelectedImage('');
  };

  React.useEffect(() => {
    if (!useType) {
      setUseType(UseType.APRT);
    } else {
      setUseType(useType);
    }
  }, [useType]);

  React.useEffect(() => {
    setNewSpace(props.currentSpace);
  }, [props.currentSpace]);

  return (
    <>
      <AddButton style={{ height: '0px' }} startIcon={<img src={require('../media/penIcon.png')} style={{ width: 14, height: 14 }} alt="modify img" />} onClick={open}></AddButton>
      <CenterModal open={on} onClose={close}>
        <ModalCard>
          <CardHeader direction="row" justifyContent="space-between">
            <ModalHeadText sx={{ pt: 1 }}>측정공간정보 입력</ModalHeadText>
            <IconButton sx={{ mr: 5 }} size="small" onClick={close}>
              <Close />
            </IconButton>
          </CardHeader>
          <CardContent style={{ padding: 20 }}>
            <Stack sx={{ p: 4, pr: 1 }}>
              <Grid container spacing={3} sx={{ backgroundColor: '#F8FAFB' }}>
                <Grid item xs={6}>
                  <Stack direction="row" spacing={3}>
                    <Stack sx={{ flex: 1 }}>
                      <ModalLableText>건물명</ModalLableText>
                      <ModalInput defaultValue={props.currentSpace.name_dong} onChange={nameDongChange} endAdornment={<InputAdornment position="end">동</InputAdornment>} size="small"></ModalInput>
                    </Stack>
                    <Stack sx={{ flex: 1 }}>
                      <ModalLableText>측정공간</ModalLableText>
                      <ModalInput defaultValue={props.currentSpace.name_number} onChange={nameNumberChange} endAdornment={<InputAdornment position="end">호</InputAdornment>} size="small"></ModalInput>
                    </Stack>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <img src={require('../media/Vector.png')} style={{ width: 14, height: 10 }} alt="check img" />
                    <ModalLableText>측정 감독자</ModalLableText>
                  </Stack>
                  <ModalInput size="small" onChange={supervisorChange} defaultValue={props.currentSpace.supervisor}></ModalInput>
                  <ModalLableText>측정 목적</ModalLableText>
                  <Stack sx={{ flex: 1, mb: 2 }}>
                    <FormControl
                      fullWidth
                      style={{
                        background: '#FFFFFF',
                        borderRadius: 4,
                        flex: 1,
                      }}>
                      <Select
                        defaultValue={props.currentSpace.measure_purpose}
                        onChange={(e) => {
                          setMeasurePurpose(e.target.value);
                        }}
                        size="small">
                        <MenuItem value={'법규, 제도, 기준, 인증'}>법규, 제도, 기준, 인증</MenuItem>
                        <MenuItem value={'건축물 시공 품질 시험'}>건축물 시공 품질 시험</MenuItem>
                        <MenuItem value={'연구 및 업무 데이터'}>연구 및 업무 데이터</MenuItem>
                        <MenuItem value={'기타'}>기타</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <img src={require('../media/Vector.png')} style={{ width: 14, height: 10 }} alt="check img" />
                    <ModalLableText>측정 표준</ModalLableText>
                  </Stack>
                  <Stack sx={{ flex: 1, mb: 2 }}>
                    <FormControl
                      fullWidth
                      style={{
                        background: '#FFFFFF',
                        borderRadius: 4,
                        flex: 1,
                      }}>
                      <Select
                        defaultValue={props.currentSpace.measure_standard}
                        onChange={(e) => {
                          setMeasureStandard(e.target.value);
                        }}
                        size="small">
                        <MenuItem value={'ISO 9972(KS L ISO 9972)'}>ISO 9972(KS L ISO 9972)</MenuItem>
                        <MenuItem value={'EN13829'}>EN13829</MenuItem>
                        <MenuItem value={'ASTM E779'}>ASTM E779</MenuItem>
                        <MenuItem value={'기타'}>기타</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                  <ModalLableText>측정 시점</ModalLableText>
                  <Stack sx={{ flex: 1, mb: 2 }}>
                    <FormControl
                      fullWidth
                      style={{
                        background: '#FFFFFF',
                        borderRadius: 4,
                        flex: 1,
                      }}>
                      <Select
                        defaultValue={props.currentSpace.measure_point}
                        onChange={(e) => {
                          setMeasurePoint(e.target.value);
                        }}
                        size="small">
                        <MenuItem value={'준공 전'}>준공 전</MenuItem>
                        <MenuItem value={'준공 후'}>준공 후</MenuItem>
                        <MenuItem value={'실사용시'}>실사용시</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                  <ModalLableText>측정 일자</ModalLableText>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <ModalDatePicker
                      value={date}
                      defaultValue={props.currentSpace.date}
                      onChange={(newDate) => {
                        setDate(newDate);
                      }}
                      renderInput={(params) => <ModalTextField size="small" {...params} style={{ paddingBottom: 0 }} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6} sx={{ pr: 3 }}>
                  <ModalLableText>측정 대상공간의 총 바닥면적</ModalLableText>
                  <ModalInput
                    value={floorArea}
                    defaultValue={props.currentSpace.floor_area}
                    onChange={floorAreaChange}
                    endAdornment={<InputAdornment position="end">㎡</InputAdornment>}
                    size="small"></ModalInput>
                  <ModalLableText>측정 대상공간의 총 높이</ModalLableText>
                  <ModalInput
                    value={floorHeight}
                    defaultValue={props.currentSpace.floor_height}
                    onChange={floorHeightChange}
                    endAdornment={<InputAdornment position="end">m</InputAdornment>}
                    size="small"></ModalInput>
                  <Stack direction="row" spacing={1}>
                    <img src={require('../media/Vector.png')} style={{ width: 14, height: 10 }} alt="check img" />
                    <ModalLableText>측정 대상공간의 총 체적</ModalLableText>
                  </Stack>
                  <ModalInput
                    value={volume}
                    defaultValue={props.currentSpace.volume}
                    onChange={volumeChange}
                    endAdornment={<InputAdornment position="end">㎥</InputAdornment>}
                    size="small"></ModalInput>
                  <Stack direction="row" spacing={1}>
                    <img src={require('../media/Vector.png')} style={{ width: 14, height: 10 }} alt="check img" />
                    <ModalLableText>측정 대상공간의 총 외피면적</ModalLableText>
                  </Stack>
                  <ModalInput
                    value={outsideArea}
                    defaultValue={props.currentSpace.outside_area}
                    onChange={outsideAreaChange}
                    endAdornment={<InputAdornment position="end">㎡</InputAdornment>}
                    size="small"></ModalInput>
                  <Stack direction="row" justifyContent="space-between">
                    <ModalLableText>측정 사진</ModalLableText>
                    <Stack direction="row" spacing={1}>
                      <DarkGreyButton
                        onClick={(e) => {
                          handleSelectImageClick(e);
                        }}>
                        사진 선택
                      </DarkGreyButton>
                      <DarkGreyButton
                        onClick={() => {
                          deleteImage();
                        }}>
                        삭제
                      </DarkGreyButton>
                      <input
                        type="file"
                        ref={selectedImageRef}
                        style={{ display: 'none' }}
                        onChange={(e) => {
                          handleSelectedImageChange(e);
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Box
                    style={{
                      border: '1px dashed #DDDDDD',
                      minHeight: 124,
                    }}>
                    <img src={selectedImage} />
                  </Box>
                </Grid>
              </Grid>
            </Stack>
            <Stack direction="row" spacing={2} justifyContent="center">
              <BlueButton
                onClick={() => {
                  handleSaveButtonClick(props.currentSpace.seq);
                }}>
                저장
              </BlueButton>
              <Dialog
                open={confirmOpen}
                onClose={() => {
                  setConfirmOpen(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{confirmMsg}</DialogTitle>
                <DialogContent></DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setConfirmOpen(false);
                    }}>
                    확인
                  </Button>
                </DialogActions>
              </Dialog>
              <GreyButton onClick={close}>닫기</GreyButton>
            </Stack>
          </CardContent>
        </ModalCard>
      </CenterModal>
    </>
  );
}
