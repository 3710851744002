import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import ToggleButton from '@mui/material/ToggleButton';
import imageToBase64 from 'image-to-base64/browser';
import { styled } from '@mui/material';
import {
  LableText,
  LableTextCenter,
  LableDescriptionTextCenter,
  LableDescriptionTextLeft,
  LableDescriptionBoldTextCenter,
  LableDescriptionBoldTextLeft,
  ModalCard,
  BuildingHeadExpert,
  BorderTable,
} from '../../components/const';
import { AddButton, BlueButton, DarkGreyButton, GreyButton } from '../../components/button';
import { CardHeader, CenterModal, ModalHeadText, ModalInput, ModalLableText, ModalTextField, ToggleTextField, TypeButtonGroup, useModalToggle } from '../../Modal/Modal';
import { Close, PeopleAlt, SettingsSuggestTwoTone } from '@mui/icons-material';
import { CardContent, Select, FormControl, Grid, IconButton, InputAdornment, Stack, Dialog, DialogActions, DialogContent, DialogTitle, Button, TableCell, TableHead, TableRow } from '@mui/material';
import { BuildingTable } from '../../tables/BuildingTable';
import { GetBuildings, GetCurrentSpace, AddBuilding, UploadExcelFile, CopyBuilding } from '../../data/Data';

function StepOne(props) {
  const [buildings, setBuildings] = React.useState([]);
  const [isUpdated, setIsUpdated] = React.useState(true);

  const loadData = () => {
    GetBuildings(props.userId).then((response) => {
      setBuildings(response.data);
      response.data.find((building) => {
        if (building.is_current === true) {
          let buildingName = building.name;
          if (buildingName === null || buildingName === undefined || buildingName === '') {
            buildingName = '-';
          }
          props.setTitle(buildingName);
          props.setBuildingTitle(building.name);
          props.setUseType(building.building_type);
          props.setCurrentBuilding(building);
          props.setCurrentBuildingId(building.seq);

          GetCurrentSpace(building.seq).then((currentSpace) => {
            if (currentSpace.data.length > 0 && currentSpace.data[0].is_current === true) {
              props.setState(currentSpace.data[0].state);
              props.setCurrentSpace(currentSpace.data[0]);
              props.setCurrentSpaceId(currentSpace.data[0].seq);
            } else {
              props.setCurrentSpace();
              props.setCurrentSpaceId('');
            }
          });
        }
      });
      setIsUpdated(false);
    });
  };

  React.useEffect(() => {
    if (isUpdated) {
      props.setTab('env');
      loadData();
    }
  }, [props.userId, isUpdated]);

  return (
    <Stack sx={{ mt: 1, ml: 10, mr: 10 }} style={{ height: 510 }}>
      <Stack direction="row" justifyContent="center">
        <Stack direction="column" justifyContent="center">
          <LableTextCenter sx={{ paddingBottom: 1 }}>1. 건물 정보</LableTextCenter>
          <LableDescriptionTextLeft sx={{ paddingBottom: 1 }}>대상 건물에 대한 기본 정보를 입력합니다.</LableDescriptionTextLeft>
          <LableDescriptionTextLeft sx={{ paddingBottom: 1 }}>건물 정보는 건물명, 건물유형, 준공연도, 소재지, 층수 및 높이 정보를 입력하게 됩니다.</LableDescriptionTextLeft>
          <LableDescriptionTextLeft sx={{ paddingBottom: 2 }}># 새 건물을 추가하거나 건물 리스트를 복사하여 입력할 수 있으며 입력 완료 후에는 수정이 불가능 합니다.</LableDescriptionTextLeft>
        </Stack>
      </Stack>
      <Stack sx={{ pb: 2, paddingBottom: 2 }} direction="row" justifyContent="space-between">
        <OpenModalButton
          userId={props.userId}
          userType={props.userType}
          useType={props.useType}
          currentBuilding={props.currentBuilding}
          setCurrentBuilding={props.setCurrentBuilding}
          setCurrentBuildingId={props.setCurrentBuildingId}
          buildings={buildings}
          setBuildings={setBuildings}
          setIsUpdated={setIsUpdated}
          setUseType={props.setUseType}
        />
      </Stack>
      <BorderTable style={{ paddingRight: '11px' }}>
        <TableHead>
          <TableRow>
            {BuildingHeadExpert.map((it) => (
              <TableCell key={it.id} align={it.alignment} width={it.width}>
                {it.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </BorderTable>
      <Stack style={{ overflow: 'auto' }}>
        <BuildingTable
          userId={props.userId}
          userType={props.userType}
          setTitle={props.setTitle}
          buildings={buildings}
          currentBuilding={props.currentBuilding}
          setCurrentBuilding={props.setCurrentBuilding}
          setCurrentBuildingId={props.setCurrentBuildingId}
          setIsUpdated={setIsUpdated}
          isResult={false}
        />
      </Stack>
    </Stack>
  );
}

function OpenModalButton(props) {
  const inputFile = React.useRef(null);
  const [location, setLocation] = React.useState('서울특별시');
  const [useType, setUseType] = React.useState('공동주택');
  const [copyOpen, setCopyOpen] = React.useState(false);
  const selectedImageRef = React.useRef(null);
  const [selectedImage, setSelectedImage] = React.useState('');
  const [selectedFile, setSelectedFile] = React.useState({});
  const [newBuilding, setNewBuilding] = React.useState(CreateBuildingData(props.userId));
  const { on, open, close } = useModalToggle();
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [year, setYear] = React.useState();

  const nameChange = (e) => {
    newBuilding.name = e.target.value;
  };

  const yearChange = (e) => {
    const inputYear = e.target.value;
    const validYearRegex = /^(19|20)?\d{0,2}$/;
    if (validYearRegex.test(inputYear)) {
      setYear(inputYear);
      newBuilding.year = inputYear;
    }
  };

  const grossAreaChange = (e) => {
    newBuilding.gross_area = e.target.value;
  };

  const floorNumberChange = (e) => {
    newBuilding.floor_number = e.target.value;
  };

  const heightChange = (e) => {
    newBuilding.height = e.target.value;
  };

  const handleSaveButtonClick = (buildings) => {
    if (useType === '' || newBuilding.year === '') {
      setConfirmOpen(true);
    } else {
      newBuilding.user_id = props.userId;
      newBuilding.year = newBuilding.year ?? '';
      newBuilding.floor_number = newBuilding.floor_number ?? '';
      newBuilding.gross_area = newBuilding.gross_area ?? '';
      newBuilding.height = newBuilding.height ?? '';

      newBuilding.building_type = useType;
      newBuilding.location = location;
      newBuilding.file = selectedFile;
      newBuilding.filename = selectedFile.name;

      AddBuilding(newBuilding).then((res) => {
        newBuilding.seq = res.data;
        setUseType('');
        setSelectedImage('');
        setSelectedFile({});
        close();
        props.setIsUpdated(true);
      });
    }
  };

  const handleCopyAllClick = () => {
    CopyBuilding(props.currentBuilding.seq, true).then((res) => {
      props.setIsUpdated(true);
      setCopyOpen(false);
    });
  };

  const handleCopyBuildingOnlyClick = () => {
    CopyBuilding(props.currentBuilding.seq, false).then((res) => {
      props.setIsUpdated(true);
      setCopyOpen(false);
    });
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const handleExcelUpload = async (e) => {
    const { files } = e.target;
    if (files && files.length) {
      try {
        var res = await UploadExcelFile(files[0]);

        if (res.data.total === res.data.success) {
          alert(`업로드 성공`); //. 전체: ${res.data.total}, 성공: ${res.data.success}`);
        } else {
          alert(`업로드 부분 성공 ${res.data.total}`); //, 성공: ${res.data.success}, 실패RowNo: ${res.data.failureRowNo}`);
        }
      } catch (error) {
        console.log(error);
        alert(`파일 업로드를 실패하였습니다. ${error.response.data}`);
      }
      inputFile.current.value = '';
    }
  };

  const handleUseTypeClick = (e) => {
    props.setUseType(e.target.value);
    setUseType(e.target.value);
  };

  const handleSelectImageClick = (e) => {
    selectedImageRef.current.click();
  };

  const handleSelectedImageChange = (e) => {
    let url = URL.createObjectURL(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    setSelectedImage(url);
  };

  const deleteImage = () => {
    setSelectedFile({});
    setSelectedImage('');
  };

  React.useEffect(() => {}, [useType]);
  return (
    <>
      <Stack direction="row">
        <AddButton sx={{ marginRight: 2 }} startIcon={<img src={require('../../media/add.png')} style={{ width: 18, height: 17, marginBottom: 3 }} alt="add building img" />} onClick={open}>
          새 건물 추가
        </AddButton>
        <AddButton
          sx={{ width: 80, marginRight: 2 }}
          onClick={() => {
            // buildings 가 있고 선택한 대상이 있을 때 복사버튼 눌림
            if (props.buildings.length > 0 && props.currentBuilding !== null && props.currentBuilding !== undefined) {
              setCopyOpen(true);
            }
          }}>
          복사
        </AddButton>
        <input
          style={{ display: 'none' }}
          sx={{ width: 120 }}
          ref={inputFile}
          onChange={(e) => {
            handleExcelUpload(e);
          }}
          type="file"></input>
        {props.userType === 'admin' ? (
          <AddButton sx={{ marginRight: 2 }} onClick={onButtonClick}>
            엑셀업로드
          </AddButton>
        ) : (
          <></>
        )}
        <Dialog
          open={copyOpen}
          onClose={() => {
            setCopyOpen(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{'모든 측정정보와 함께 복사 하시겠습니까??'}</DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleCopyAllClick();
              }}>
              예
            </Button>
            <Button
              onClick={() => {
                handleCopyBuildingOnlyClick();
              }}>
              아니오
            </Button>
            <Button
              onClick={() => {
                setCopyOpen(false);
              }}>
              {' '}
              취소{' '}
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>

      <CenterModal open={on} onClose={close}>
        <ModalCard>
          <CardHeader direction="row" justifyContent="space-between">
            <ModalHeadText sx={{ pt: 1 }}>건물 정보 입력</ModalHeadText>
            <IconButton sx={{ mr: 5 }} size="small" onClick={close}>
              <Close />
            </IconButton>
          </CardHeader>
          <CardContent style={{ padding: 20 }}>
            <Stack sx={{ p: 4, pr: 1 }}>
              <Grid container spacing={3} sx={{ backgroundColor: '#F8FAFB' }}>
                <Grid item xs={7}>
                  <ModalLableText>건물명</ModalLableText>
                  <ModalTextField onChange={nameChange} size="small"></ModalTextField>
                  <Stack direction="row" spacing={1}>
                    <img src={require('../../media/Vector.png')} style={{ width: 14, height: 10 }} alt="check img" />
                    <ModalLableText>건물 유형</ModalLableText>
                  </Stack>
                  <Stack direction="row" sx={{ flex: 1, pb: 2 }}>
                    <TypeButtonGroup value={useType} onChange={(e) => handleUseTypeClick(e)} exclusive color="primary">
                      <ToggleButton value={'공동주택'}>공동주택</ToggleButton>
                      <ToggleButton value={'단독주택'}>단독주택</ToggleButton>
                      <ToggleButton value={'교육시설'}>교육시설</ToggleButton>
                      <ToggleButton value={'업무시설'}>업무시설</ToggleButton>
                    </TypeButtonGroup>
                    <FormControl
                      fullWidth
                      sx={{ flex: 1 }}
                      style={{
                        background: '#FFFFFF',
                        borderRadius: 4,
                      }}>
                      <Select value={useType} onChange={(e) => handleUseTypeClick(e)} size="small">
                        <MenuItem value={'제1종 근린생활시설'}>제1종 근린생활시설</MenuItem>
                        <MenuItem value={'제2종 근린생활시설'}>제2종 근린생활시설</MenuItem>
                        <MenuItem value={'문화 및 집회시설'}>문화 및 집회시설</MenuItem>
                        <MenuItem value={'노유자시설 '}>노유자시설</MenuItem>
                        <MenuItem value={'기타'}>기타</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                  <ModalLableText>소재지</ModalLableText>
                  <Stack sx={{ flex: 1, mb: 2 }}>
                    <FormControl
                      fullWidth
                      style={{
                        background: '#FFFFFF',
                        borderRadius: 4,
                        flex: 1,
                      }}>
                      <Select
                        value={location}
                        onChange={(e) => {
                          setLocation(e.target.value);
                        }}
                        size="small">
                        <MenuItem value={'서울특별시'}>서울특별시</MenuItem>
                        <MenuItem value={'부산광역시'}>부산광역시</MenuItem>
                        <MenuItem value={'대구광역시'}>대구광역시</MenuItem>
                        <MenuItem value={'인천광역시'}>인천광역시</MenuItem>
                        <MenuItem value={'광주광역시'}>광주광역시</MenuItem>
                        <MenuItem value={'대전광역시'}>대전광역시</MenuItem>
                        <MenuItem value={'울산광역시'}>울산광역시</MenuItem>
                        <MenuItem value={'세종특별자치시'}>세종특별자치시</MenuItem>
                        <MenuItem value={'경기도'}>경기도</MenuItem>
                        <MenuItem value={'강원도'}>강원도</MenuItem>
                        <MenuItem value={'충청북도'}>충청북도</MenuItem>
                        <MenuItem value={'충청남도'}>충청남도</MenuItem>
                        <MenuItem value={'전라북도'}>전라북도</MenuItem>
                        <MenuItem value={'전라남도'}>전라남도</MenuItem>
                        <MenuItem value={'경상북도'}>경상북도</MenuItem>
                        <MenuItem value={'경상남도'}>경상남도</MenuItem>
                        <MenuItem value={'제주특별자치도'}>제주특별자치도</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                  <Stack direction="row" spacing={3}>
                    <Stack direction="column" sx={{ flex: 1 }}>
                      <Stack direction="row" spacing={1}>
                        <img src={require('../../media/Vector.png')} style={{ width: 14, height: 10 }} alt="check img" />
                        <ModalLableText>준공 연도</ModalLableText>
                      </Stack>
                      <ModalInput value={year} onChange={yearChange} endAdornment={<InputAdornment position="end">년</InputAdornment>} size="small"></ModalInput>
                    </Stack>
                    <Stack direction="column" sx={{ flex: 1 }}>
                      <ModalLableText>연면적</ModalLableText>
                      <ModalInput onChange={grossAreaChange} endAdornment={<InputAdornment position="end">㎡</InputAdornment>} size="small"></ModalInput>
                    </Stack>
                  </Stack>
                  <Stack direction="row" spacing={3}>
                    <Stack sx={{ flex: 1 }}>
                      <ModalLableText>건물 층수</ModalLableText>
                      <ModalInput onChange={floorNumberChange} endAdornment={<InputAdornment position="end">층</InputAdornment>} size="small"></ModalInput>
                    </Stack>
                    <Stack sx={{ flex: 1 }}>
                      <ModalLableText>건물 높이</ModalLableText>
                      <ModalInput onChange={heightChange} endAdornment={<InputAdornment position="end">m</InputAdornment>} size="small"></ModalInput>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={5} sx={{ pr: 3 }}>
                  <Stack direction="row" justifyContent="space-between">
                    <ModalLableText>건물 전경 사진</ModalLableText>
                    <div className="App">
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(e) => {
                          handleSelectedImageChange(e);
                        }}
                      />
                    </div>
                    <Stack direction="row" spacing={1}>
                      <DarkGreyButton
                        onClick={(e) => {
                          handleSelectImageClick(e);
                        }}>
                        사진 선택
                      </DarkGreyButton>
                      <input
                        type="file"
                        ref={selectedImageRef}
                        style={{ display: 'none' }}
                        onChange={(e) => {
                          handleSelectedImageChange(e);
                        }}
                      />
                      <DarkGreyButton
                        onClick={() => {
                          deleteImage();
                        }}>
                        삭제
                      </DarkGreyButton>
                    </Stack>
                  </Stack>
                  <Box
                    style={{
                      border: '1px dashed #DDDDDD',
                      minHeight: 379,
                    }}>
                    {selectedImage ? <img src={selectedImage} /> : <></>}
                  </Box>
                </Grid>
              </Grid>
            </Stack>
            <Stack direction="row" spacing={2} justifyContent="center">
              <BlueButton
                onClick={() => {
                  handleSaveButtonClick(props.buildings);
                }}>
                저장
              </BlueButton>
              <GreyButton onClick={close}>닫기</GreyButton>
              <Dialog
                open={confirmOpen}
                onClose={() => {
                  setConfirmOpen(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{'필수 값이 입력되지 않았습니다.'}</DialogTitle>
                <DialogContent></DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setConfirmOpen(false);
                    }}>
                    확인
                  </Button>
                </DialogActions>
              </Dialog>
            </Stack>
          </CardContent>
        </ModalCard>
      </CenterModal>
    </>
  );
}

function CreateBuildingData(
  user_id = '',
  name = '',
  building_type = '',
  year = '',
  file = null,
  filename = '',
  photo = '',
  floor_number = '',
  height = '',
  gross_area = '',
  location = '',
  state = false
) {
  return {
    user_id: user_id,
    name: name,
    building_type: building_type,
    year: year,
    file: file,
    filename: filename,
    floor_number: floor_number,
    height: height,
    gross_area: gross_area,
    location: location,
    state: state,
  };
}

export default StepOne;
