import { Button, styled, ToggleButtonGroup as MtToggleButtonGroup } from '@mui/material';

export const StepButton = styled(Button)`
  color: #888888;
  background-color: #f0f5ff;
  box-shadow: none;
  flex: 1;
  padding: 16px 2px;
  font-size: 18px;
  font-weight: 350;
  &:hover,
  &:active {
    color: #888888;
    background-color: #f0f5ff;
  }
  span {
    color: white;
    background: #888888;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    font-weight: 350;
    div {
      margin: 0 auto;
      line-height: 30px;
    }
  }
  span.MuiTouchRipple-root {
    display: none;
  }
`;

export const MainToggleButtonGroup = styled(MtToggleButtonGroup)`
  background-color: #f4f4f4;
  margin: 0 50px;
  display: flex;
  width: 130vh;
  justify-content: center;

  button {
    border: none;
    font-size: 16px;
    font-weight: 350;
    color: #888888;
    flex: 1;
    padding: 15px 2px;
  }

  .Mui-selected {
    background-color: #3d4abc !important;
    font-weight: 700;
    color: #ffffff !important;
  }
`;

export const AirtightnessButton = styled(Button)`
  background-color: #cccccc;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  height: 22px;
  border-radius: 3px;

  &:hover,
  &:active {
    background-color: #555555;
  }
`;

export const DarkGreyButton = styled(Button)`
  background: #555555;
  color: #ffffff;
  height: 22px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  padding: 2px 16px;
  &:hover,
  &:active {
    background-color: #555555;
  }
`;

export const GreyButton = styled(Button)`
  background-color: #f4f4f4;
  color: #888888;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  border-radius: 4px;
  box-shadow: none;
  padding: 8px 35px;
  &:hover,
  &:active {
    color: #888888;
    background-color: #f4f4f4;
  }
  span {
    margin-right: 0px;
    margin-bottom: 3px;
  }
`;

export const BlueButton = styled(Button)`
  background-color: #f4f4f4;
  color: #4b71cf;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  border-radius: 4px;
  box-shadow: none;
  padding: 8px 35px;
  &:hover,
  &:active {
    color: #4b71cf;
    background-color: #f4f4f4;
  }
  span {
    margin-bottom: 4px;
  }
`;

export const PurpleButton = styled(Button)`
  background-color: #b23850;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  border-radius: 4px;
  box-shadow: none;
  padding: 8px 35px;
  &:hover,
  &:active {
    color: #ffffff;
    background-color: #b23850;
  }
  span {
    margin-bottom: 4px;
  }
`;

export const RedButton = styled(Button)`
  background-color: #f4f4f4;
  color: red;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  border-radius: 4px;
  box-shadow: none;
  padding: 8px 35px;
  &:hover,
  &:active {
    color: red;
    background-color: #f4f4f4;
  }
  span {
    margin-bottom: 4px;
  }
`;

export const AddButton = styled(Button)`
  background-color: #555555;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  padding: 0px 20px;
  border-radius: 5px;
  height: 38px;
  &:hover,
  &:active {
    color: #ffffff;
    background-color: black;
  }
`;

export const CheckButton = styled(Button)`
  background-color: #555555;
  color: #ffffff;
  font-weight: 300;
  font-size: 14px;
  border-radius: 5px;
  height: 38px;
  &:hover,
  &:active {
    color: #ffffff;
    background-color: black;
  }
`;

export const FindPasswordButton = styled(Button)`
  color: #000000;
  font-weight: 300;
  font-size: 14px;
  padding-top: 12px;
  padding-bottom: 2px;
  border-bottom: solid #666666 1px;
  text-decoration: none;
  &:hover,
  &:active {
    background: #ffffff;
    text-decoration: none;
  }
`;

export const LoginButton = styled(Button)`
  background: #3d4abc;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  padding: 8px 100px;
  opacity: 1 !important;
  &:hover,
  &:active {
    color: #ffffff;
    background-color: #3d4abc;
  }
`;
