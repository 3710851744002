import _ from 'lodash';
import React, { useEffect } from 'react';
import axios from 'axios';
import emailjs from '@emailjs/browser';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import { LoginButton, AddButton, BlueButton, DarkGreyButton, GreyButton, FindPasswordButton, CheckButton } from '../components/button';
import { LableText, NoteTextField, SelectLable } from '../components/const';
import { UseSelect } from '../components/select';

import {
  Card,
  Stack,
  Typography,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';

import { BorderTable, AdditionalInfoAirtightnessHead, GraphArea, ModalCard } from '../components/const';

import { Signin, Signup, Renewal, GetVerificationKey, GetUser, ExtensionPassword } from '../data/Data';
import MenuItem from '@mui/material/MenuItem';
import ToggleButton from '@mui/material/ToggleButton';
import Box from '@mui/material/Box';
import { Biotech, Close, Delete } from '@mui/icons-material';

import { CardHeader, CenterModal, ModalHeadText, ModalInput, ModalLableText, ModalTextField, ToggleTextField, TypeButtonGroup, useModalToggle } from './Modal';

import SetAuthorizationToken from '../utils/SetAuthorizationToken';

import EmailContactForm from './EmailContactForm';

export function LoginModal({ on, onClose, modalScreen, setModalScreen, onSucceed = _.noop, onPasswordExpiration = _.noop }) {
  const [id, setId] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [company_position, setCompany_position] = React.useState('');
  const [permission_type, setPermission_type] = React.useState('normal');
  const [passwordIncorrect, setPasswordIncorrect] = React.useState(false);
  const [emailAlreadyExists, setEmailAlreadyExists] = React.useState(true);
  const [valid, setValid] = React.useState(false);

  React.useEffect(() => {
    setId('');
    setPassword('');
    setPassword2('');
    setUsername('');
    setPhone('');
    setCompany('');
    setCompany_position('');
    setPermission_type('normal');
  }, [on]);

  React.useEffect(() => {
    if (phone.length === 10 && phone.startsWith('01') === false) {
      setPhone(phone.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3'));
    }
    if (phone.length === 10 && phone.startsWith('01')) {
      setPhone(phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
    }
    if (phone.length === 13) {
      setPhone(phone.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
    }
  }, [phone]);

  const idChange = (e) => {
    setId(e.target.value);
    setEmailAlreadyExists(true);
  };

  const passwordChange = (e) => {
    setPassword(e.target.value);

    setPasswordIncorrect(password2 !== e.target.value);

    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    setValid(regex.test(e.target.value));
  };

  const password2Change = (e) => {
    setPassword2(e.target.value);

    setPasswordIncorrect(password !== e.target.value);
  };

  const usernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleChangePhone = (e) => {
    const regex = /^[0-9\b -]{0,15}$/;
    if (regex.test(e.target.value)) {
      setPhone(e.target.value);
    }
  };

  const companyChange = (e) => {
    setCompany(e.target.value);
  };

  const company_positionChange = (e) => {
    setCompany_position(e.target.value);
  };

  const permission_typeChange = (e) => {
    setPermission_type(e.target.value);
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const validateEmail = (email) => {
    return emailRegex.test(email);
  };

  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };

  const handleLogin = async (e) => {
    if (!id) {
      alert('아이디를 입력해주세요.');
      return;
    }
    const isValidEmail = validateEmail(id);
    if (!isValidEmail) {
      alert('이메일을 입력해주세요.');
      return;
    }
    var userData = UserSigninData(id, password);

    try {
      var res = await Signin(id, password);

      sessionStorage.setItem('userId', id);
      sessionStorage.setItem('userType', res.data.role);
      sessionStorage.setItem('email', res.data.email);
      sessionStorage.setItem('username', res.data.username);
      sessionStorage.setItem('phone', res.data.phone);
      sessionStorage.setItem('company', res.data.company);
      sessionStorage.setItem('company_position', res.data.company_position);
      sessionStorage.setItem('role', res.data.role);
      sessionStorage.setItem('training_name', res.data.training_name);
      sessionStorage.setItem('expert_number', res.data.expert_number);

      if (res.data.is_expired === false) {
        const accessToken = res.data.AccessToken;
        const refreshToken = res.data.RefreshToken;

        SetAuthorizationToken('AccessToken', accessToken);
        SetAuthorizationToken('RefreshToken', refreshToken);

        onSucceed(res.data.role, id);
      } else {
        setModalScreen(3);

        // var msg =
        //   '비밀번호 변경일로부터 90일이 경과하였습니다. 비밀번호를 변경해주세요.\r\n[확인] 지금 변경\r\n[취소] 90일 연장';
        // console.log('🚀 ~ file: LoginModal.js:176 ~ handleLogin ~ msg:', res.data.Message);
        // const confirmed = window.confirm(msg);
        // console.log('🚀 ~ file: LoginModal.js:175 ~ handleLogin ~ confirmed:', confirmed);
        // if (confirmed) {
        //   var res1 = await handleSendMailResetPassword(id);
        //   console.log('🚀 ~ file: LoginModal.js:178 ~ handleLogin ~ id:', id);
        //   SetAuthorizationToken('AccessToken', null);
        //   SetAuthorizationToken('RefreshToken', null);
        // } else if (!confirmed) {
        //   var res2 = await ExtensionPassword(id);
        //   alert('연장되었습니다.');
        //   onSucceed(res.data.role, id);
        // }
        // console.log('🚀 ~ file: LoginModal.js:186 ~ handleLogin ~ res.data.role:', res.data.role);
      }
    } catch (err) {
      alert(err.response.data.Message);
    }
  };

  const handleSignup = async (e) => {
    if (emailAlreadyExists) {
      alert('이메일 중복 체크를 해주세요.');
      return;
    }
    if (valid === false) {
      return;
    }

    if (!id || !password || !username || !company || !permission_type) {
      alert('필수정보를 입력해주세요.');
      return;
    }

    var regData = UserSignupData(id, password, username, phone, company, company_position, permission_type);
    try {
      var res = await Signup(regData);
      if (permission_type === 'normal') {
        handleSendMailVerification(id, res.data.VerificationKey);
      } else {
        handleSendMailVerificationForExpert(id, res.data.VerificationKey);
      }
      //console.log(res);
      //onSucceed(res.data, id);
    } catch (err) {
      //console.log(err);
      alert(err.response.data.Message);
    }
  };

  const handleOnKeyPressSendMailResetPassword = (e) => {
    if (e.key === 'Enter') {
      handleSendMailResetPassword(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };

  const handleSendMailVerification = (email, verificationKey) => {
    const url = `https://db.baip.co.kr/verification?email=${email}&verificationKey=${verificationKey}`;

    let templateParams = {
      link: `<a href='${url}'>이메일 인증</>`,
      to_email: email,
      subject: '회원가입 인증',
      message: '위 링크를 클릭하여 이메일 인증을 완료해주세요.',
    };
    emailjs.send('service_cue6tll', 'template_n1dje3h', templateParams, 'Q4pdCIPQNj90r7QeC').then(
      function (response) {
        alert('이메일 인증을 하여 회원가입을 완료해주세요.\r\n메일을 수신하지 못한 경우 스팸메일함의 확인이 필요합니다.');
        onClose();
      },
      function (error) {
        console.log('Failed...', error);
      }
    );
  };

  const handleSendMailVerificationForExpert = (email, verificationKey) => {
    const url = `https://db.baip.co.kr/verification?email=${email}&verificationKey=${verificationKey}`;

    let templateParams = {
      link: `<a href='${url}'>이메일 인증</>`,
      to_email: email,
      subject: '회원가입 인증 (전문가)',
      message:
        '위 링크를 클릭하여 이메일 인증을 완료해주세요.\r\n추가로 전문가 기능 승인을 위하여 아래 이메일로 전문가교육인증서를 보내주시기 바랍니다.\r\n검토후 최종 승인됩니다.\r\n\r\nbaip.information@gmail.com',
    };
    emailjs.send('service_cue6tll', 'template_7nkq0kk', templateParams, 'Q4pdCIPQNj90r7QeC').then(
      function (response) {
        alert('이메일 인증을 하여 회원가입을 완료해주세요.\r\n메일을 수신하지 못한 경우 스팸메일함의 확인이 필요합니다.');
        onClose();
      },
      function (error) {
        console.log('Failed...', error);
      }
    );
  };

  const handleSendMailResetPassword = async () => {
    SetAuthorizationToken('AccessToken', null);
    SetAuthorizationToken('RefreshToken', null);
    onClose();

    var email = id;
    try {
      var res = await GetVerificationKey(email);
      const url = `https://db.baip.co.kr/password?email=${email}&verificationKey=${res.data.VerificationKey}`;

      let templateParams = {
        link: `<a href='${url}'>비밀번호 재설정</>`,
        to_email: email,
        subject: '비밀번호 재설정',
        message: '위 링크를 클릭하여 비밀번호를 재설정해주세요.',
      };
      emailjs.send('service_cue6tll', 'template_n1dje3h', templateParams, 'Q4pdCIPQNj90r7QeC').then(
        function (response) {
          alert('이메일에서 비밀번호를 재설정해주세요.\r\n메일을 수신하지 못한 경우 스팸메일함의 확인이 필요합니다.');
        },
        function (error) {
          //console.log('Failed...', error);
        }
      );
    } catch (error) {
      alert(error.response.data);
    }
  };

  const handleAlreadyExists = async () => {
    try {
      var res = await GetUser(id);
      alert('이미 사용중인 이메일입니다.');
      setEmailAlreadyExists(true);
    } catch (error) {
      alert('사용 가능합니다.');
      setEmailAlreadyExists(false);
    }
  };

  return (
    <>
      <CenterModal open={on} onClose={onClose}>
        <>
          {modalScreen === 0 && (
            <>
              <ModalCard style={{ width: '500px' }}>
                <CardHeader direction="row" justifyContent="space-between">
                  <ModalHeadText sx={{ pt: 1 }}>로그인</ModalHeadText>
                  <IconButton sx={{ mr: 5 }} size="small" onClick={onClose}>
                    <Close />
                  </IconButton>
                </CardHeader>
                <CardContent style={{ padding: 20 }}>
                  <Stack direction="row" justifyContent="space-between">
                    <Stack style={{ width: '800px' }}>
                      <Grid container spacing={0} justifyContent="center" sx={{ mb: 3, backgroundColor: '#F8FAFB' }}>
                        <Grid item xs={11} sx={{ mt: 2, mb: 2 }}>
                          <ModalLableText style={{ fontSize: 17, fontWeight: 400 }}>이메일 주소</ModalLableText>
                          <ModalInput
                            onChange={(e) => {
                              idChange(e);
                            }}
                            size="small"></ModalInput>
                          <ModalLableText style={{ fontSize: 17, fontWeight: 400 }}>비밀번호</ModalLableText>
                          <ModalInput
                            type="password"
                            onChange={(e) => {
                              passwordChange(e);
                            }}
                            onKeyPress={handleOnKeyPress}
                            size="small"></ModalInput>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                  <Stack direction="row" spacing={2} justifyContent="center">
                    <BlueButton onClick={() => handleLogin()}>로그인</BlueButton>
                  </Stack>
                  <Stack direction="row" spacing={2} justifyContent="center">
                    <FindPasswordButton onClick={() => setModalScreen(1)}>회원가입</FindPasswordButton>
                    <FindPasswordButton onClick={() => setModalScreen(2)}>비밀번호 찾기</FindPasswordButton>
                  </Stack>
                </CardContent>
              </ModalCard>
            </>
          )}
          {modalScreen === 1 && (
            <>
              <ModalCard style={{ width: '500px' }}>
                <CardHeader direction="row" justifyContent="space-between">
                  <ModalHeadText sx={{ pt: 1 }}>회원가입</ModalHeadText>
                  <IconButton sx={{ mr: 5 }} size="small" onClick={onClose}>
                    <Close />
                  </IconButton>
                </CardHeader>
                <CardContent style={{ padding: 20 }}>
                  <Stack direction="row" justifyContent="space-between">
                    <Stack style={{ width: '800px' }}>
                      <Grid container spacing={0} justifyContent="center" sx={{ mb: 3, backgroundColor: '#F8FAFB' }}>
                        <Grid item xs={11} sx={{ mt: 2, mb: 2 }}>
                          <Stack direction="row" spacing={1} alignItems="center">
                            <img src={require('../media/Vector.png')} style={{ width: 14, height: 14, marginBottom: 7 }} alt="check img" />
                            <ModalLableText style={{ fontSize: 17, fontWeight: 400 }}>이메일 주소</ModalLableText>
                          </Stack>
                          <Stack direction="row" spacing={1}>
                            <Stack sx={{ flex: 5 }}>
                              <ModalInput
                                type="email"
                                onChange={(e) => {
                                  idChange(e);
                                }}
                                size="small"></ModalInput>
                            </Stack>
                            <Stack sx={{ flex: 1.3 }}>
                              <CheckButton onClick={() => handleAlreadyExists()}>중복확인</CheckButton>
                              {/* <button
                                style={{
                                  width: 30,
                                  height: 30,
                                }}></button> */}
                            </Stack>
                          </Stack>
                          <Stack direction="row" spacing={1} alignItems="center">
                            <img src={require('../media/Vector.png')} style={{ width: 14, height: 14, marginBottom: 7 }} alt="check img" />
                            <ModalLableText style={{ fontSize: 17, fontWeight: 400 }}>비밀번호</ModalLableText>
                          </Stack>
                          <Stack direction="row" spacing={2}>
                            <Stack sx={{ flex: 1 }}>
                              <ModalTextField
                                error={password && !valid}
                                type="password"
                                helperText={password && !valid && <p>대문자, 숫자, 특수문자 중 2개 이상 조합 및 최소 8자리 이상</p>}
                                onChange={(e) => {
                                  passwordChange(e);
                                }}
                                size="small"></ModalTextField>
                            </Stack>
                            <Stack sx={{ flex: 1 }}>
                              <ModalTextField
                                error={passwordIncorrect}
                                id="outlined-error-helper-text"
                                type="password"
                                helperText={passwordIncorrect ? 'Incorrect entry.' : ''}
                                onChange={(e) => {
                                  password2Change(e);
                                }}
                                size="small"></ModalTextField>
                            </Stack>
                          </Stack>
                          <Stack direction="row" spacing={1} alignItems="center">
                            <img src={require('../media/Vector.png')} style={{ width: 14, height: 14, marginBottom: 7 }} alt="check img" />
                            <ModalLableText style={{ fontSize: 17, fontWeight: 400 }}>이름</ModalLableText>
                          </Stack>
                          <ModalInput
                            type="text"
                            onChange={(e) => {
                              usernameChange(e);
                            }}
                            size="small"></ModalInput>
                          <ModalLableText style={{ fontSize: 17, fontWeight: 400 }}>연락처</ModalLableText>
                          <ModalInput placeholder="휴대폰번호를 입력하세요." type="text" onChange={handleChangePhone} value={phone} size="small"></ModalInput>
                          <Stack direction="row" spacing={1} alignItems="center">
                            <img src={require('../media/Vector.png')} style={{ width: 14, height: 14, marginBottom: 7 }} alt="check img" />
                            <ModalLableText style={{ fontSize: 17, fontWeight: 400 }}>기관</ModalLableText>
                          </Stack>
                          <ModalInput
                            type="text"
                            onChange={(e) => {
                              companyChange(e);
                            }}
                            size="small"></ModalInput>
                          <ModalLableText style={{ fontSize: 17, fontWeight: 400 }}>직위</ModalLableText>
                          <ModalInput
                            type="text"
                            onChange={(e) => {
                              company_positionChange(e);
                            }}
                            size="small"></ModalInput>{' '}
                          <Stack direction="row" spacing={1} alignItems="center">
                            <img src={require('../media/Vector.png')} style={{ width: 14, height: 14, marginBottom: 7 }} alt="check img" />
                            <ModalLableText style={{ fontSize: 17, fontWeight: 400 }}>사용자 유형</ModalLableText>
                          </Stack>
                          <RadioGroup row aria-labelledby="row-radio-buttons-group-label" name="row-radio-buttons-group" value={permission_type} onChange={permission_typeChange}>
                            <FormControlLabel value="normal" control={<Radio />} label="일반" />
                            <FormControlLabel value="expert" control={<Radio />} label="전문가 (승인필요)" />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                  <Stack direction="row" spacing={2} justifyContent="center">
                    <BlueButton onClick={() => handleSignup()}>가입</BlueButton>
                  </Stack>
                </CardContent>
              </ModalCard>
            </>
          )}
          {modalScreen === 2 && (
            <>
              <ModalCard style={{ width: '500px' }}>
                <CardHeader direction="row" justifyContent="space-between">
                  <ModalHeadText sx={{ pt: 1 }}>비밀번호 찾기</ModalHeadText>
                  <IconButton sx={{ mr: 5 }} size="small" onClick={onClose}>
                    <Close />
                  </IconButton>
                </CardHeader>
                <CardContent style={{ padding: 20 }}>
                  <Stack direction="row" justifyContent="space-between">
                    <Stack style={{ width: '800px' }}>
                      <Grid container spacing={0} justifyContent="center" sx={{ mb: 3, backgroundColor: '#F8FAFB' }}>
                        <Grid item xs={11} sx={{ mt: 2, mb: 2 }}>
                          <ModalLableText style={{ fontSize: 17, fontWeight: 400 }}>이메일 주소</ModalLableText>
                          <ModalInput
                            onChange={(e) => {
                              idChange(e);
                            }}
                            onKeyPress={handleOnKeyPressSendMailResetPassword}
                            size="small"></ModalInput>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                  <Stack direction="row" spacing={2} justifyContent="center">
                    <BlueButton onClick={() => handleSendMailResetPassword()}>인증 메일 보내기</BlueButton>
                  </Stack>
                </CardContent>
              </ModalCard>
            </>
          )}
          {modalScreen === 3 && (
            <ModalCard style={{ width: '500px' }}>
              <CardHeader direction="row" justifyContent="space-between">
                <ModalHeadText sx={{ pt: 1 }}>비밀번호 만료</ModalHeadText>
                <IconButton sx={{ mr: 5 }} size="small" onClick={onClose}>
                  <Close />
                </IconButton>
              </CardHeader>
              <CardContent style={{ padding: 20 }}>
                <Stack direction="row" justifyContent="space-between">
                  <Stack style={{ width: '800px' }}>
                    <Grid container spacing={0} justifyContent="center" sx={{ mb: 3, backgroundColor: '#F8FAFB' }}>
                      <Grid item xs={11} sx={{ mt: 2, mb: 2 }}>
                        <p>비밀번호 변경일로부터 90일이 경과하였습니다. 비밀번호를 변경해주세요.</p>
                      </Grid>
                    </Grid>
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="center">
                  <BlueButton onClick={() => handleSendMailResetPassword()}>지금 변경</BlueButton>
                  <BlueButton
                    onClick={async () => {
                      var res = await ExtensionPassword(id);
                      alert('연장되었습니다.');
                      onSucceed(res.data.role, id);
                    }}>
                    90일 연장
                  </BlueButton>
                </Stack>
              </CardContent>
            </ModalCard>
          )}
        </>
      </CenterModal>
    </>
  );
}

function Criteria(group = '', color = '', title = '', value = '') {
  return {
    group: group,
    color: color,
    title: title,
    value: value,
  };
}

function UserSigninData(email = '', password = '') {
  return {
    email: email,
    password: password,
  };
}

function UserSignupData(email = '', password = '', username = '', phone = '', company = null, company_position = null, permission_type = '') {
  return {
    email: email,
    password: password,
    username: username,
    phone: phone,
    company: company,
    company_position: company_position,
    permission_type: permission_type,
  };
}
