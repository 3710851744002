import {
  Radio,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';

import * as React from 'react';
import { BorderTable, BuildingHead, BuildingHeadExpert, BuildingInfoHead, ModalCard } from '../components/const';

import { AddButton, BlueButton, DarkGreyButton, GreyButton } from '../components/button';

import { CardHeader, CenterModal, ModalHeadText, ModalInput, ModalLableText, ModalTextField, ToggleTextField, TypeButtonGroup, useModalToggle } from '../Modal/Modal';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import ToggleButton from '@mui/material/ToggleButton';
import { Biotech, Close, Delete } from '@mui/icons-material';
import { DeleteBuilding, EditBuilding, SetCurrentBuilding } from '../data/Data';
import imageToBase64 from 'image-to-base64/browser';

const UseType = ['공동주택', '단독주택', '교육시설', '업무시설', '제1종 근린생활시설', '제2종 근린생활시설', '문화 및 집회시설', '노유자시설'];

let id = 0;

export function BuildingTable(props) {
  const userType = props.userType;
  const [open, setOpen] = React.useState(false);
  const [selectedBuildingSeq, setSelectedBuildingSeq] = React.useState(0);

  const handleClickOpen = (seq) => {
    setSelectedBuildingSeq(seq);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    DeleteBuilding(selectedBuildingSeq);
    setOpen(false);
    if (props.buildings.length > 0 && props.currentBuilding.seq === selectedBuildingSeq) {
      SetCurrentBuilding(props.buildings[0].seq, props.userID);
    }
    props.setIsUpdated(true);
  };

  React.useEffect(() => {
    console.log('BuildingTable', props.buildings);
  }, [props.buildings]);

  return (
    <BorderTable>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'선택한 건물 정보를 리스트에서 삭제하시겠습니까?'}</DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleDelete();
            }}>
            삭제
          </Button>
          <Button onClick={handleClose}> 닫기 </Button>
        </DialogActions>
      </Dialog>
      {props.isResult === true ? (
        <>
          <TableBody>
            {props.buildings.map((building, idx) => (
              <TableRow key={idx}>
                <TableCell align="center" style={{ width: '15%' }}>
                  {building.name}
                </TableCell>
                <TableCell align="center" style={{ width: '10%' }}>
                  {building.building_type}
                </TableCell>
                <TableCell align="center" style={{ width: '5%' }}>
                  {building.year}
                </TableCell>
                <TableCell align="center" style={{ width: '5%' }}>
                  {building.location}
                </TableCell>
                <TableCell align="center" style={{ width: '5%' }}>
                  {building.gross_area}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </>
      ) : (
        <>
          <TableBody>
            {props.buildings.map((building, idx) => (
              <TableRow key={idx}>
                <TableCell align="center">
                  <TableRadioButton
                    style={{ width: '4%' }}
                    align="center"
                    value={building.is_current === true}
                    setValue={() => [
                      console.log(' value={building.is_current === true}', building),
                      SetCurrentBuilding(building.seq, props.userID),
                      props.setCurrentBuilding(building),
                      props.setCurrentBuildingId(building.seq),
                      props.setTitle(building.name),
                      props.setIsUpdated(true),
                    ]}
                  />
                </TableCell>
                <TableCell align="center" style={{ width: '5%' }}>
                  {idx + 1}
                </TableCell>
                <TableCell align="center" style={{ width: '20%' }}>
                  {building.name}
                </TableCell>
                <TableCell align="center" style={{ width: '10%' }}>
                  <Stack direction="row" justifyContent="center">
                    {building.photo72 ? <img src={window.location.origin + building.photo72} style={{ width: 32, height: 26 }} alt="building img" /> : <></>}
                  </Stack>
                </TableCell>
                <TableCell align="center" style={{ width: '10%' }}>
                  {building.building_type}
                </TableCell>
                <TableCell align="center" style={{ width: '10%' }}>
                  {building.measure_count}
                </TableCell>
                <TableCell align="center" style={{ width: '10%' }}>
                  {building.year}
                </TableCell>
                <TableCell align="center" style={{ width: '10%' }}>
                  {building.location}
                </TableCell>
                {building.state === true ? (
                  <TableCell align="center" style={{ color: '#3D4ABC', width: '10%' }}>
                    입력 완료
                  </TableCell>
                ) : (
                  <TableCell align="center" style={{ color: '#FF0000', width: '10%' }}>
                    입력 중
                  </TableCell>
                )}
                {userType === 'admin' ? (
                  <TableCell align="center" style={{ width: '5%' }}>
                    <Stack direction="row" justifyContent="center">
                      <OpenModalButton selectedBuilding={building} setIsUpdated={props.setIsUpdated} image={building.photo} />
                    </Stack>
                  </TableCell>
                ) : building.state === false ? (
                  <TableCell align="center" style={{ width: '5%' }}>
                    <Stack direction="row" justifyContent="center">
                      <OpenModalButton selectedBuilding={building} setIsUpdated={props.setIsUpdated} image={building.photo} />
                    </Stack>
                  </TableCell>
                ) : (
                  <TableCell align="center" style={{ width: '5%' }}></TableCell>
                )}
                {userType === 'admin' ? (
                  <TableCell align="center" style={{ width: '5%' }}>
                    <Stack direction="row" justifyContent="center">
                      <img
                        src={require('../media/deleteIcon.png')}
                        style={{ width: 14, height: 14, cursor: 'pointer' }}
                        value={building.seq}
                        onClick={() => {
                          handleClickOpen(building.seq);
                        }}
                        alt="delete img"
                      />
                    </Stack>
                  </TableCell>
                ) : building.state === false ? (
                  <TableCell align="center" style={{ width: '5%' }}>
                    <Stack direction="row" justifyContent="center">
                      <img
                        src={require('../media/deleteIcon.png')}
                        style={{ width: 14, height: 14, cursor: 'pointer' }}
                        value={building.seq}
                        onClick={() => {
                          handleClickOpen(building.seq);
                        }}
                        alt="delete img"
                      />
                    </Stack>
                  </TableCell>
                ) : (
                  <TableCell align="center" style={{ width: '5%' }}></TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </>
      )}
    </BorderTable>
  );
}

export function TableRadioButton({ value, setValue }) {
  return (
    <div>
      <Radio
        checked={value === true}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        value={value}
        size="small"
        sx={{ p: 0, color: '#DDDDDD', '&.Mui-checked': { color: '#222222' } }}
      />
    </div>
  );
}

function OpenModalButton(props) {
  const [location, setLocation] = React.useState(props.selectedBuilding.location);
  const [useType, setUseType] = React.useState(props.selectedBuilding.building_type);
  const [newBuilding, setNewBuilding] = React.useState(props.selectedBuilding);
  const { on, open, close } = useModalToggle();
  const selectedImageRef = React.useRef(null);
  const [selectedImage, setSelectedImage] = React.useState(props.image);
  const [selectedFile, setSelectedFile] = React.useState();
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [year, setYear] = React.useState();

  const nameChange = (e) => {
    newBuilding.name = e.target.value;
  };

  const yearChange = (e) => {
    const inputYear = e.target.value;
    const validYearRegex = /^(19|20)?\d{0,2}$/;
    if (validYearRegex.test(inputYear)) {
      setYear(inputYear);
      props.selectedBuilding.year = inputYear;
      newBuilding.year = inputYear;
    }
  };

  const grossAreaChange = (e) => {
    newBuilding.gross_area = e.target.value;
  };

  const floorNumberChange = (e) => {
    newBuilding.floor_number = e.target.value;
  };

  const heightChange = (e) => {
    newBuilding.height = e.target.value;
  };

  const handleSaveButtonClick = (seq) => {
    if (useType === '' || newBuilding.year === '') {
      setConfirmOpen(true);
    } else {
      newBuilding.year = newBuilding.year ?? '';
      newBuilding.floor_number = newBuilding.floor_number ?? '';
      newBuilding.gross_area = newBuilding.gross_area ?? '';
      newBuilding.height = newBuilding.height ?? '';

      newBuilding.building_type = useType;
      newBuilding.location = location;
      //newBuilding.photo = selectedImage;
      newBuilding.file = selectedFile;
      newBuilding.filename = selectedFile?.name;

      EditBuilding(newBuilding).then((res) => {
        props.setIsUpdated(true);
        close();
      });
    }
  };

  const handleSelectImageClick = (e) => {
    selectedImageRef.current.click();
  };

  const handleSelectedImageChange = (e) => {
    let url = URL.createObjectURL(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    setSelectedImage(url);
  };

  const deleteImage = () => {
    setSelectedFile({});
    setSelectedImage('');
  };

  React.useEffect(() => {
    if (!useType) {
      setUseType(UseType.APRT);
    } else {
      setUseType(useType);
    }
  }, [useType]);

  React.useEffect(() => {
    setNewBuilding(props.selectedBuilding);
    setYear(props.selectedBuilding.year);
  }, [props.selectedBuilding]);

  return (
    <>
      <AddButton style={{ height: '0px' }} startIcon={<img src={require('../media/penIcon.png')} style={{ width: 14, height: 14 }} alt="modify img" />} onClick={open}></AddButton>
      <CenterModal open={on} onClose={close}>
        <ModalCard>
          <CardHeader direction="row" justifyContent="space-between">
            <ModalHeadText sx={{ pt: 1 }}>건물 정보 입력</ModalHeadText>
            <IconButton sx={{ mr: 5 }} size="small" onClick={close}>
              <Close />
            </IconButton>
          </CardHeader>
          <CardContent style={{ padding: 20 }}>
            <Stack sx={{ p: 4, pr: 1 }}>
              <Grid container spacing={3} sx={{ backgroundColor: '#F8FAFB' }}>
                <Grid item xs={7}>
                  <ModalLableText>건물명</ModalLableText>
                  <ModalTextField defaultValue={props.selectedBuilding.name} onChange={nameChange} size="small"></ModalTextField>
                  <Stack direction="row" spacing={1}>
                    <img src={require('../media/Vector.png')} style={{ width: 14, height: 10 }} alt="check img" />
                    <ModalLableText>건물 유형</ModalLableText>
                  </Stack>
                  <Stack direction="row" sx={{ flex: 1, pb: 2 }}>
                    <TypeButtonGroup value={UseType.includes(useType) ? useType : ''} onChange={(e) => setUseType(e.target.value)} exclusive color="primary">
                      <ToggleButton value={'공동주택'}>공동주택</ToggleButton>
                      <ToggleButton value={'단독주택'}>단독주택</ToggleButton>
                      <ToggleButton value={'교육시설'}>교육시설</ToggleButton>
                      <ToggleButton value={'업무시설'}>업무시설</ToggleButton>
                    </TypeButtonGroup>
                    <FormControl
                      fullWidth
                      sx={{ flex: 1 }}
                      style={{
                        background: '#FFFFFF',
                        borderRadius: 4,
                      }}>
                      <Select value={UseType.includes(useType) ? '' : useType} onChange={(e) => setUseType(e.target.value)} size="small">
                        <MenuItem value={'제1종 근린생활시설'}>제1종 근린생활시설</MenuItem>
                        <MenuItem value={'제2종 근린생활시설'}>제2종 근린생활시설</MenuItem>
                        <MenuItem value={'문화 및 집회시설'}>문화 및 집회시설</MenuItem>
                        <MenuItem value={'노유자시설 '}>노유자시설</MenuItem>
                        <MenuItem value={'기타'}>기타</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                  <ModalLableText>소재지</ModalLableText>
                  <Stack sx={{ flex: 1, mb: 2 }}>
                    <FormControl
                      fullWidth
                      style={{
                        background: '#FFFFFF',
                        borderRadius: 4,
                        flex: 1,
                      }}>
                      <Select
                        value={location}
                        onChange={(e) => {
                          setLocation(e.target.value);
                        }}
                        size="small">
                        <MenuItem value={'서울특별시'}>서울특별시</MenuItem>
                        <MenuItem value={'부산광역시'}>부산광역시</MenuItem>
                        <MenuItem value={'대구광역시'}>대구광역시</MenuItem>
                        <MenuItem value={'인천광역시'}>인천광역시</MenuItem>
                        <MenuItem value={'광주광역시'}>광주광역시</MenuItem>
                        <MenuItem value={'대전광역시'}>대전광역시</MenuItem>
                        <MenuItem value={'울산광역시'}>울산광역시</MenuItem>
                        <MenuItem value={'세종특별자치시'}>세종특별자치시</MenuItem>
                        <MenuItem value={'경기도'}>경기도</MenuItem>
                        <MenuItem value={'강원도'}>강원도</MenuItem>
                        <MenuItem value={'충청북도'}>충청북도</MenuItem>
                        <MenuItem value={'충청남도'}>충청남도</MenuItem>
                        <MenuItem value={'전라북도'}>전라북도</MenuItem>
                        <MenuItem value={'전라남도'}>전라남도</MenuItem>
                        <MenuItem value={'경상북도'}>경상북도</MenuItem>
                        <MenuItem value={'경상남도'}>경상남도</MenuItem>
                        <MenuItem value={'제주특별자치도'}>제주특별자치도</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                  <Stack direction="row" spacing={3}>
                    <Stack direction="column" sx={{ flex: 1 }}>
                      <Stack direction="row" spacing={1}>
                        <img src={require('../media/Vector.png')} style={{ width: 14, height: 10 }} alt="check img" />
                        <ModalLableText>준공 연도</ModalLableText>
                      </Stack>
                      <ModalInput value={year} onChange={yearChange} endAdornment={<InputAdornment position="end">년</InputAdornment>} size="small"></ModalInput>
                    </Stack>
                    <Stack direction="column" sx={{ flex: 1 }}>
                      <ModalLableText>연면적</ModalLableText>
                      <ModalInput
                        defaultValue={props.selectedBuilding.gross_area}
                        onChange={grossAreaChange}
                        endAdornment={<InputAdornment position="end">㎡</InputAdornment>}
                        size="small"></ModalInput>
                    </Stack>
                  </Stack>
                  <Stack direction="row" spacing={3}>
                    <Stack sx={{ flex: 1 }}>
                      <ModalLableText>건물 층수</ModalLableText>
                      <ModalInput
                        onChange={floorNumberChange}
                        defaultValue={props.selectedBuilding.floor_number}
                        endAdornment={<InputAdornment position="end">층</InputAdornment>}
                        size="small"></ModalInput>
                    </Stack>
                    <Stack sx={{ flex: 1 }}>
                      <ModalLableText>건물 높이</ModalLableText>
                      <ModalInput onChange={heightChange} defaultValue={props.selectedBuilding.height} endAdornment={<InputAdornment position="end">m</InputAdornment>} size="small"></ModalInput>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={5} sx={{ pr: 3 }}>
                  <Stack direction="row" justifyContent="space-between">
                    <ModalLableText>건물 전경 사진</ModalLableText>
                    <Stack direction="row" spacing={1}>
                      <DarkGreyButton
                        onClick={(e) => {
                          handleSelectImageClick(e);
                        }}>
                        사진 선택
                      </DarkGreyButton>
                      <input
                        type="file"
                        ref={selectedImageRef}
                        style={{ display: 'none' }}
                        onChange={(e) => {
                          handleSelectedImageChange(e);
                        }}
                      />
                      <DarkGreyButton
                        onClick={() => {
                          deleteImage();
                        }}>
                        삭제
                      </DarkGreyButton>
                    </Stack>
                  </Stack>
                  <Box
                    style={{
                      border: '1px dashed #DDDDDD',
                      minHeight: 379,
                    }}>
                    <img src={selectedImage} />
                  </Box>
                </Grid>
              </Grid>
            </Stack>
            <Stack direction="row" spacing={2} justifyContent="center">
              <BlueButton
                onClick={() => {
                  handleSaveButtonClick(props.selectedBuilding.seq);
                }}>
                저장
              </BlueButton>
              <Dialog
                open={confirmOpen}
                onClose={() => {
                  setConfirmOpen(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{'필수 값이 입력되지 않았습니다.'}</DialogTitle>
                <DialogContent></DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setConfirmOpen(false);
                    }}>
                    확인
                  </Button>
                </DialogActions>
              </Dialog>
              <GreyButton onClick={close}>닫기</GreyButton>
            </Stack>
          </CardContent>
        </ModalCard>
      </CenterModal>
    </>
  );
}
