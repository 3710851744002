import * as React from 'react';

import moment from 'moment';
import dayjs from 'dayjs';

import { MeasuringTable } from '../../tables/MeasuringTable';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { EquipmentSelect } from '../../components/select';
import {
  LableText,
  LableTextCenter,
  LableDescriptionTextCenter,
  LableDescriptionTextLeft,
  LableDescriptionBoldTextCenter,
  LableDescriptionBoldTextLeft,
  ModalCard,
  BuildingHeadExpert,
  BorderTable,
  SecondLableText,
} from '../../components/const';
import { AddButton, BlueButton, GreyButton } from '../../components/button';
import { CardHeader, CenterModal, ModalHeadText, ModalDatePicker, ModalInput, ModalLableText, ModalTextField, useModalToggle } from '../../Modal/Modal';
import { Close } from '@mui/icons-material';
import { CardContent, Grid, IconButton, Stack } from '@mui/material';
import { GetEquipments, GetCurrentBuilding, GetCurrentSpace, AddEquipment } from '../../data/Data';

function Step4(props) {
  const [isInit, setIsInit] = React.useState(true);
  const [isUpdated, setIsUpdated] = React.useState(false);
  const [equipmentRows, setEquipmentRows] = React.useState([]);
  const [gaugeData, setGaugeData] = React.useState([]);
  const [blowerData, setBlowerData] = React.useState([]);
  const [currentSpace, setCurrentSpace] = React.useState({});
  const [currentBuilding, setCurrentBuilding] = React.useState({});

  const loadData = () => {
    GetCurrentBuilding(props.userId).then((currentBuilding) => {
      setCurrentBuilding(currentBuilding.data[0]);
      GetCurrentSpace(currentBuilding.data[0].seq).then((currentSpace) => {
        setCurrentSpace(currentSpace.data[0]);

        let buildingName = currentBuilding.data[0].name;
        let spaceDongName = currentSpace.data[0].name_dong;
        let spaceDongNumber = currentSpace.data[0].name_number;
        if (buildingName === null || buildingName === undefined || buildingName === '') {
          buildingName = '-';
        }
        if (spaceDongName === null || spaceDongName === undefined || spaceDongName === '') {
          spaceDongName = '-';
        }
        if (spaceDongNumber === null || spaceDongNumber === undefined || spaceDongNumber === '') {
          spaceDongNumber = '-';
        }

        props.setTitle(buildingName + ' ' + spaceDongName + ' ' + spaceDongNumber);

        GetEquipments(props.userId, currentSpace.data[0].seq).then((response) => {
          var gaugeList = [];
          var blowerList = [];
          response.data.map((data) => {
            if (data.equipment === 'Gauge') {
              gaugeList.push(data);
            } else {
              blowerList.push(data);
            }
          });

          setGaugeData(gaugeList);
          setBlowerData(blowerList);
        });
      });
    });
  };

  React.useEffect(() => {
    props.setTab('air');
    if (isInit) {
      loadData();
      setIsInit(false);
    }
    if (isUpdated) {
      GetEquipments(props.userId, currentSpace.seq).then((response) => {
        var gaugeList = [];
        var blowerList = [];
        response.data.map((data) => {
          if (data.equipment === 'Gauge') {
            gaugeList.push(data);
          } else {
            blowerList.push(data);
          }
        });

        setGaugeData(gaugeList);
        setBlowerData(blowerList);
      });
      setIsUpdated(false);
    }
  }, [isUpdated]);

  return (
    <>
      <Stack sx={{ mt: 1, ml: 10, mr: 10 }}>
        <Stack direction="row" justifyContent="center">
          <Stack direction="column" justifyContent="center">
            <LableTextCenter sx={{ paddingBottom: 1 }}>4. 측정 장비</LableTextCenter>
            <LableDescriptionTextLeft sx={{ paddingBottom: 1 }}>게이지 및 블로어도어 팬에 대한 정보를 입력합니다.</LableDescriptionTextLeft>
            <LableDescriptionTextLeft sx={{ paddingBottom: 1 }}>측정 장비는 게이지/블로어도어를 선택한 후 제조사, 모델명, 시리얼 번호, 교정 만료 기간을 입력하게 됩니다.</LableDescriptionTextLeft>
            <LableDescriptionTextLeft sx={{ paddingBottom: 2 }}># 사용한 장비를 선택해주세요.</LableDescriptionTextLeft>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          {props.state ? <></> : <OpenModalButton userId={props.userId} setIsUpdated={setIsUpdated} />}
        </Stack>
        <Stack direction="row" justifyContent="space-between" sx={{ pb: 2, pt: 3 }}>
          <Stack direction="row" alignItems="center" spacing={1} sx={{ pb: 3 }}>
            <LableText sx={{ pr: 2 }}>게이지 (Gauge)</LableText>
          </Stack>
        </Stack>
        <MeasuringTable
          userId={props.userId}
          userType={props.userType}
          currentSpaceId={currentSpace.seq}
          equipmentRows={gaugeData}
          setEquipmentRows={setEquipmentRows}
          setIsUpdated={setIsUpdated}
          state={props.state}
          isResult={false}
        />
        <Stack direction="row" justifyContent="space-between" sx={{ pb: 2, pt: 5 }}>
          <Stack direction="row" alignItems="center" spacing={1} sx={{ pb: 3 }}>
            <LableText sx={{ pr: 2 }}>블로어도어 팬 (Blower Door Fan)</LableText>
          </Stack>
        </Stack>
        <MeasuringTable
          userId={props.userId}
          userType={props.userType}
          currentSpaceId={currentSpace.seq}
          equipmentRows={blowerData}
          setEquipmentRows={setEquipmentRows}
          setIsUpdated={setIsUpdated}
          state={props.state}
          isResult={false}
        />
      </Stack>
    </>
  );
}

function OpenModalButton(props) {
  const { on, open, close } = useModalToggle();
  const [date, setDate] = React.useState(moment().format('YYYY-MM-DD'));
  const [equipment, setEquipment] = React.useState('Gauge');
  const [newEquipment, setNewEquipment] = React.useState(CreateEquipmentData(props.userID));

  const companyChange = (e) => {
    newEquipment.company = e.target.value;
  };
  const modelNameChange = (e) => {
    newEquipment.model_name = e.target.value;
  };
  const serialNumberChange = (e) => {
    newEquipment.serial_number = e.target.value;
  };
  const noteChange = (e) => {
    newEquipment.note = e.target.value;
  };

  const handleSaveButtonClick = () => {
    newEquipment.user_id = props.userId;
    newEquipment.equipment = equipment;
    newEquipment.calibration_expiry_period = dayjs(date).format('YYYY-MM-DD');

    AddEquipment(newEquipment).then(() => {
      props.setIsUpdated(true);
      close();
    });
  };

  return (
    <>
      <AddButton startIcon={<img src={require('../../media/add.png')} style={{ width: 18, height: 17, marginBottom: 3 }} alt="add building img" />} onClick={open}>
        새 장비 추가
      </AddButton>
      <CenterModal open={on} onClose={close}>
        <ModalCard>
          <CardHeader direction="row" justifyContent="space-between">
            <ModalHeadText sx={{ pt: 1 }}>측정 장비 추가</ModalHeadText>
            <IconButton sx={{ mr: 5 }} size="small" onClick={close}>
              <Close />
            </IconButton>
          </CardHeader>
          <CardContent style={{ padding: 20, paddingTop: 25 }}>
            <Stack sx={{ p: 4, pr: 1 }}>
              <Grid container spacing={3} sx={{ backgroundColor: '#F8FAFB' }}>
                <Grid item xs={6} sx={{ pb: 5 }}>
                  <ModalLableText>측정 장비</ModalLableText>
                  <EquipmentSelect equipment={equipment} setEquipment={setEquipment} />
                  <ModalLableText>제조사</ModalLableText>
                  <ModalInput onChange={companyChange} style={{ marginBottom: 50 }} size="small"></ModalInput>
                  <ModalLableText>모델명</ModalLableText>
                  <ModalInput onChange={modelNameChange} size="small"></ModalInput>
                </Grid>
                <Grid item xs={6} sx={{ pr: 3 }}>
                  <ModalLableText>시리얼 번호</ModalLableText>
                  <ModalInput onChange={serialNumberChange} style={{ marginBottom: 40 }} size="small"></ModalInput>
                  <ModalLableText>교정 만료 기간</ModalLableText>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <ModalDatePicker
                      value={date}
                      inputFormat={'YYYY-MM-DD'}
                      onChange={(newDate) => {
                        setDate(newDate);
                      }}
                      renderInput={(params) => <ModalTextField size="small" {...params} style={{ paddingBottom: 0, marginBottom: 50 }} />}
                    />
                  </LocalizationProvider>
                  <ModalLableText>비고</ModalLableText>
                  <ModalInput onChange={noteChange} size="small"></ModalInput>
                </Grid>
              </Grid>
            </Stack>
            <Stack direction="row" spacing={2} justifyContent="center">
              <BlueButton
                onClick={() => {
                  handleSaveButtonClick(props.spaces);
                }}>
                저장
              </BlueButton>
              <GreyButton onClick={close}>닫기</GreyButton>
            </Stack>
          </CardContent>
        </ModalCard>
      </CenterModal>
    </>
  );
}

function CreateEquipmentData(user_id = '', equipment = '', company = '', model_name = '', serial_number = '', calibration_expiry_period = '', note = '') {
  return {
    user_id: user_id,
    equipment: equipment,
    company: company,
    model_name: model_name,
    serial_number: serial_number,
    calibration_expiry_period: calibration_expiry_period,
    note: note,
  };
}

export default Step4;
