import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

import StepOne from './steps/Step1';
import StepTwo from './steps/Step2';
import StepThree from './steps/Step3';
import StepFour from './steps/Step4';
import StepFive from './steps/Step5';
import StepSix from './steps/Step6';
import image from '../media/Background.png';
import { PrintReport1Type1Button } from './PrintReport1Type1';
import { PrintReport1Type2Button } from './PrintReport1Type2';
import { useModalToggle } from '../Modal/Modal';
import { UserModal } from '../Modal/UserModal';

import Scrollbar from '../components/Scrollbar';
import { Card, FixedHeader, Header, HeaderText, UserButton } from '../components/const';

import { BlueButton, GreyButton, StepButton, PurpleButton } from '../components/button';

import Box from '@mui/material/Box';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { CardContent, Paper, Stack, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

import { SetConfirmed, SetPreviewNote, GetCurrentBuilding, GetCurrentSpace, GetMeasureAirCondition, Renewal, Test } from '../data/Data';
import { Try } from '@mui/icons-material';
import SetAuthorizationToken from '../utils/SetAuthorizationToken';

const NormalNav = [
  { id: 1, label: '건물 정보' },
  { id: 2, label: '측정 공간 정보' },
  { id: 3, label: '측정 결과' },
  { id: 4, label: '측정 장비' },
  { id: 5, label: '입력 내용 확인' },
];

const ExpertNav = [
  { id: 1, label: '건물 정보' },
  { id: 2, label: '측정 공간 정보' },
  { id: 3, label: '측정 결과' },
  { id: 4, label: '측정 장비' },
  { id: 5, label: '입력 내용 확인' },
  { id: 6, label: '부가정보' },
];

const UseType = ['공동주택', '단독주택', '교육시설', '업무시설', '제1종 근린생활시설', '제2종 근린생활시설', '문화 및 집회시설', '노유자시설'];

function Main() {
  const location = useLocation();
  const navigate = useNavigate();

  const getSessionUser = () => {
    try {
      var ss_userId = '';
      if (location.state?.userId === null || location.state?.userId === undefined) {
        ss_userId = sessionStorage.getItem('userId');
      } else {
        ss_userId = location.state?.userId;
      }
      sessionStorage.setItem('userId', ss_userId);
      return ss_userId;
    } catch (error) {
      return undefined;
    }
  };

  const getSessionUserType = () => {
    try {
      var ss_userType = '';
      if (location.state?.userType === null || location.state?.userType === undefined) {
        ss_userType = sessionStorage.getItem('userType');
      } else {
        ss_userType = location.state?.userType;
      }
      sessionStorage.setItem('userType', ss_userType);
      return ss_userType;
    } catch (error) {
      return undefined;
    }
  };

  const [userId, setUserId] = React.useState(getSessionUser());
  const [userType, setUserType] = React.useState(getSessionUserType());

  const [currentPage, setCurrentPage] = React.useState();

  const [title, setTitle] = React.useState('');
  const [buildingTitle, setBuildingTitle] = React.useState('');
  const [useType, setUseType] = React.useState(UseType[0]);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [currentBuilding, setCurrentBuilding] = React.useState();
  const [currentBuildingId, setCurrentBuildingId] = React.useState({});
  const [currentSpace, setCurrentSpace] = React.useState();
  const [currentSpaceId, setCurrentSpaceId] = React.useState();
  const [avgAirtightness, setAvgAirtightness] = React.useState([]);

  const [state, setState] = React.useState(false);
  const [note, setNote] = React.useState('');

  const isNormal = userType === 'normal';
  const [tab, setTab] = React.useState('env');

  const [pressureType, setPressureType] = React.useState('');

  const [selectBuildingOpen, setSelectBuildingOpen] = React.useState(false);
  const [selectSpaceOpen, setSelectSpaceOpen] = React.useState(false);

  const [enable, setEnable] = React.useState(false);
  const [year1Range, setYear1Range] = React.useState();
  const [year2Range, setYear2Range] = React.useState();

  const { on, open, close } = useModalToggle();
  const [modalScreen, setModalScreen] = React.useState(0);

  const PrintReport = () => {
    if (pressureType === '') {
      return;
    }

    if (pressureType === 'pressure_decomp') {
      return <PrintReport1Type1Button disabled={!enable} userId={userId} userType={userType} avgAirtightness={avgAirtightness} year1Range={year1Range} year2Range={year2Range} />;
    } else {
      return (
        <PrintReport1Type2Button disabled={!enable} userId={userId} userType={userType} pressureType={pressureType} avgAirtightness={avgAirtightness} year1Range={year1Range} year2Range={year2Range} />
      );
    }
  };

  const SelectBuildingDialog = () => {
    return (
      <Dialog
        open={selectBuildingOpen}
        onClose={() => {
          setSelectBuildingOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">선택된 건물이 없습니다.</DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSelectBuildingOpen(false);
            }}>
            확인
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const SelectSpaceDialog = () => {
    return (
      <Dialog
        open={selectSpaceOpen}
        onClose={() => {
          setSelectSpaceOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">선택된 측정공간이 없습니다.</DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSelectSpaceOpen(false);
            }}>
            확인
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const ConfirmButtonDialog = () => {
    return (
      <Dialog
        open={confirmOpen}
        onClose={() => {
          setConfirmOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {state ? (
            '입력을 취소 하시겠습니까?'
          ) : (
            <>
              <>입력을 완료 하시겠습니까?</>
              <br />
              <>입력 완료 후, 수정/삭제가 불가합니다.</>
            </>
          )}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              SetConfirmed(currentSpaceId).then((res) => {
                setState(res.data[0].state);
              });
              if (note !== '') {
                SetPreviewNote(currentSpaceId, note);
              }
              setConfirmOpen(false);
            }}>
            확인
          </Button>
          <Button
            onClick={() => {
              setConfirmOpen(false);
            }}>
            취소
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  let { step } = useParams();
  step = parseInt(step);

  const handleNavigate = (path, state) => {
    if (path === `/`) {
      setIsIndexPage(true);
    }
    navigate(path, state);
  };

  const [isIndexPage, setIsIndexPage] = React.useState(false);
  const [validToken, setValidToken] = React.useState(false);

  // React.useEffect(() => {
  //   debugger;
  // }, [location]);

  // React.useEffect(() => {
  //   if (isIndexPage) {
  //     return;
  //   }
  //   let delay = 60 * 1000;

  //   let timerId = setTimeout(async function request() {
  //     try {
  //       var res = await Renewal();
  //       console.log('try: ', res);
  //     } catch (error) {
  //       if (error.response.status === 401) {
  //         SetAuthorizationToken('AccessToken', null);
  //         SetAuthorizationToken('RefreshToken', null);
  //         handleNavigate('/', {});
  //       }
  //     }

  //     timerId = setTimeout(request, delay);
  //   }, delay);
  // }, [isIndexPage]);

  React.useEffect(() => {
    if (userType === 'normal') {
      setEnable(true);
    } else {
      setEnable(avgAirtightness.length > 0);
    }
  }, [step, avgAirtightness, userType]);

  React.useEffect(() => {
    if (userId !== null && userId !== undefined) {
      sessionStorage.setItem('userId', userId);
      sessionStorage.setItem('userType', userType);

      if (currentBuilding === null || currentBuilding === undefined) {
        var ss_cb = sessionStorage.getItem('currentBuilding');
        if (ss_cb === null || ss_cb === undefined || ss_cb === 'undefined') {
          GetCurrentBuilding(userId).then((res) => {
            setCurrentBuilding(res.data[0]);
          });
        } else {
          setCurrentBuilding(JSON.parse(ss_cb));
        }
      } else {
        if (currentSpace === null || currentSpace === undefined) {
          var ss_cs = sessionStorage.getItem('currentSpace');
          if (ss_cs === null || ss_cs === undefined || ss_cs === 'undefined') {
            GetCurrentSpace(currentBuilding.seq).then((res) => {
              setCurrentSpace(res.data[0]);
            });
          } else {
            setCurrentSpace(JSON.parse(ss_cs));
          }
        }
      }
    } else {
      handleNavigate(`/`, {});
    }
  }, [userId, userType]);

  React.useEffect(() => {
    if (currentBuilding === null || currentBuilding === undefined || currentBuilding === 'undefined') {
      return;
    }

    sessionStorage.setItem('currentBuilding', JSON.stringify(currentBuilding));

    GetCurrentSpace(currentBuilding.seq).then((res) => {
      setCurrentSpace(res.data[0]);
    });
  }, [currentBuilding]);

  React.useEffect(() => {
    //debugger;

    if (currentSpace === null || currentSpace === undefined || currentSpace === 'undefined') {
      return;
    }

    setState(currentSpace.state);

    GetMeasureAirCondition(currentSpace.seq).then((res) => {
      setPressureType(res.data[0].pressure_type);
    });
    sessionStorage.setItem('currentSpace', JSON.stringify(currentSpace));
  }, [currentSpace]);

  // React.useEffect(() => {
  //   sessionStorage.setItem("state", state);
  // }, [state]);

  React.useEffect(() => {
    if (step === 1) {
      setCurrentPage(
        <StepOne
          userId={userId}
          userType={userType}
          setTitle={setTitle}
          setBuildingTitle={setBuildingTitle}
          currentBuilding={currentBuilding}
          setCurrentBuilding={setCurrentBuilding}
          setCurrentBuildingId={setCurrentBuildingId}
          setCurrentSpace={setCurrentSpace}
          setCurrentSpaceId={setCurrentSpaceId}
          setUseType={setUseType}
          setTab={setTab}
          setState={setState}
        />
      );
    } else if (step === 2) {
      setCurrentPage(
        <StepTwo
          userId={userId}
          userType={userType}
          setTitle={setTitle}
          buildingTitle={buildingTitle}
          currentBuilding={currentBuilding}
          currentSpace={currentSpace}
          setCurrentSpace={setCurrentSpace}
          setCurrentSpaceId={setCurrentSpaceId}
          setTab={setTab}
          setState={setState}
        />
      );
    } else if (step === 3) {
      setCurrentPage(<StepThree userId={userId} userType={userType} setTitle={setTitle} tab={tab} setTab={setTab} state={state} setPressureType={setPressureType} />);
    } else if (step === 4) {
      setCurrentPage(<StepFour userId={userId} userType={userType} setTitle={setTitle} setTab={setTab} state={state} />);
    } else if (step === 5) {
      setCurrentPage(<StepFive userId={userId} userType={userType} setTitle={setTitle} setNote={setNote} setCurrentSpaceId={setCurrentSpaceId} setTab={setTab} setPressureType={setPressureType} />);
    } else if (step === 6) {
      setCurrentPage(
        <StepSix
          userId={userId}
          userType={userType}
          setTitle={setTitle}
          useType={useType}
          currentSpace={currentSpace}
          setAvgAirtightness={setAvgAirtightness}
          setYear1Range={setYear1Range}
          setYear2Range={setYear2Range}
        />
      );
    }
  }, [step, tab, userId, state, currentBuilding, currentSpace]);

  const stepList = isNormal ? NormalNav : ExpertNav;

  const handleStepMove = (before, after, direct) => {
    if (before === after) {
      return;
    }

    let isNullBuilding = currentBuilding === undefined;
    let isNullSpace = currentSpace === undefined;

    if (after === 2 && before < after && isNullBuilding) {
      setSelectBuildingOpen(true);
      return;
    } else if (after === 3 && before < after && isNullSpace) {
      setSelectSpaceOpen(true);
      return;
    } else if (after > 3 && before < after && isNullBuilding) {
      setSelectBuildingOpen(true);
      return;
    } else if (after > 3 && before < after && isNullSpace) {
      setSelectSpaceOpen(true);
      return;
    }

    let path = '';
    let step4Tab = 'env';

    if (direct === true) {
      path = `/main/${after}/`;
    } else {
      if (after === 0) {
        path = `/`;
      } else if (after === 2 && before === 3 && tab === 'air') {
        path = `/main/${3}/`;
        step4Tab = 'env';
      } else if (after === 3 && before === 4) {
        path = `/main/${3}/`;
        step4Tab = 'air';
      } else if (after === 4 && before === 3 && tab === 'env') {
        path = `/main/${3}/`;
        step4Tab = 'air';
      } else if ((userType === 'normal' && after === 6) || (userType !== 'normal' && after === 7)) {
        path = `/main/${1}/`;
      } else {
        path = `/main/${after}/`;
      }
    }

    setTab(step4Tab);

    handleNavigate(path, {
      state: {
        userType: userType,
        tab: step4Tab,
      },
    });

    // navigate(path, {
    //   state: {
    //     userType: userType,
    //     tab: step4Tab,
    //   },
    // });
  };

  const handleLogout = () => {
    SetAuthorizationToken('AccessToken', null);
    SetAuthorizationToken('RefreshToken', null);

    var path = `/`;
    handleNavigate(path, { state: { validToken: false, setValidToken: null } });
  };

  const handleChangeModal = () => {
    var accessToken = axios.defaults.headers.common['AccessToken'];
    var userId = sessionStorage.getItem('userId');
    var userType = sessionStorage.getItem('userType');

    open();

    if (accessToken !== undefined) {
    } else {
      // let path = '/main/1/';
      // navigate(path, {
      //   state: { userType: userType, userId: userId, title: '' },
      // });
    }
  };

  const HandleChangeSucceed = (userType, userId) => {
    close();
    //var accessToken = axios.defaults.headers.common['AccessToken'];
    //var userId = sessionStorage.getItem('userId');
    //var userType = sessionStorage.getItem('userType');

    //props.setValidToken(true);
    // let path = '/main/1/';
    // navigate(path, {
    //   state: { userType: userType, userId: userId, title: '' }, // 테스트를 위한 사용자유형 및 사용자ID 추가 - 이덕현
    // });
  };

  return (
    <>
      <Helmet title="Airtightness DB" />
      <Paper
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          height: '100vh',
        }}>
        <Scrollbar>
          <Stack direction="row" justifyContent="end" sx={{ mr: 1, mt: 1 }} spacing={2}>
            <UserButton onClick={handleChangeModal}>회원정보 변경</UserButton>
            <UserModal
              on={on}
              onClose={() => {
                setModalScreen(0);
                close();
              }}
              modalScreen={modalScreen}
              setModalScreen={setModalScreen}
              onSucceed={async (userType, userId) => {
                await HandleChangeSucceed(userType, userId);
              }}>
              회원정보 변경
            </UserModal>
            <UserButton onClick={handleLogout}>로그아웃</UserButton>
          </Stack>
          <Card>
            <FixedHeader>
              <Header direction="row" justifyContent="space-between">
                <Stack direction="row" alignItems="center" sx={{ ml: 6 }} spacing={2}>
                  <img src={require('../media/logo_new_title_invert.png')} alt="로고" style={{ height: 55, marginTop: 10 }} />
                  {/* <LogoText>Korea Building Airtightness Database</LogoText> */}
                </Stack>
                <Stack direction="row" alignItems="center" sx={{ mr: 6 }}>
                  <HeaderText>{title}</HeaderText>
                </Stack>
              </Header>
              <Stack direction="row" style={{ flex: 1 }}>
                {stepList.map((it) => {
                  const isChecked = it.id <= step;
                  const isCurrentStep = it.id === step;
                  const lastStep = it.id === stepList.length;

                  //debugger;

                  const numLabelStyle = {
                    backgroundColor: isChecked ? '#FFFFFF' : '#888888',
                    color: isChecked ? '#4b5dc0' : 'white',
                    fontWeight: isChecked ? '500' : '350',
                    width: 28,
                    heigth: 28,
                    borderRadius: '50%',
                  };
                  const isBorderRound = isCurrentStep; // && !lastStep;
                  const buttonStyle = {
                    backgroundColor: isChecked ? '#3D4ABC' : '#F0F5FF',
                    borderTopRightRadius: isBorderRound ? '50px' : 0,
                    borderBottomRightRadius: isBorderRound ? '50px' : 0,
                    color: isChecked ? '#FFFFFF' : '#888888',
                    fontWeight: isChecked ? '500' : '350',
                  };
                  return it.id < 6 ? (
                    <StepButton
                      key={it.id}
                      variant="contained"
                      startIcon={<Box style={numLabelStyle}>{it.id}</Box>}
                      style={buttonStyle}
                      disableRipple
                      onClick={() => {
                        handleStepMove(step, it.id, true);
                      }}>
                      {it.label}
                    </StepButton>
                  ) : (
                    <StepButton
                      key={it.id}
                      variant="contained"
                      style={buttonStyle}
                      disableRipple
                      onClick={() => {
                        handleStepMove(step, it.id, true);
                      }}>
                      {it.label}
                    </StepButton>
                  );
                })}
              </Stack>
            </FixedHeader>
            <CardContent
              style={{
                padding: 0,
                paddingTop: 32,
                paddingBottom: 50,
                background: '#fcfdff',
              }}>
              {currentPage}
              <Stack direction="row" justifyContent="center" spacing={2} sx={{ pt: 7, paddingTop: 10 }}>
                <GreyButton
                  variant="contained"
                  startIcon={<ArrowBackIosIcon />}
                  onClick={() => {
                    handleStepMove(step, step - 1, false);
                  }}>
                  이전으로
                </GreyButton>

                {state && userType !== 'normal' && step === 5 && (
                  <BlueButton
                    variant="contained"
                    endIcon={(userType === 'normal' && step === 5) || (userType !== 'normal' && step === 6) ? <></> : <ArrowForwardIosIcon />}
                    onClick={() => handleStepMove(step, step + 1, false)}>
                    부가정보확인
                  </BlueButton>
                )}
                {step < 5 && (
                  <BlueButton
                    variant="contained"
                    endIcon={(userType === 'normal' && step === 5) || (userType !== 'normal' && step === 6) ? <></> : <ArrowForwardIosIcon />}
                    onClick={() => handleStepMove(step, step + 1, false)}>
                    다음으로
                  </BlueButton>
                )}
                {/* 
                <BlueButton
                  variant="contained"
                  endIcon={
                    (userType === 'normal' && step === 5) || (userType !== 'normal' && step === 6) ? (
                      <></>
                    ) : (
                      <ArrowForwardIosIcon />
                    )
                  }
                  onClick={() => handleStepMove(step, step + 1, false)}
                  //disabled={isNormal ? step === 6 : step === 5 && tab === "air"}
                >
                  {(userType === 'normal' && step === 5) || (userType !== 'normal' && step === 6)
                    ? '처음으로'
                    : '다음으로'}
                </BlueButton> */}

                {step === 5 ? (
                  userType === 'admin' ? (
                    <BlueButton
                      variant="contained"
                      onClick={() => {
                        setConfirmOpen(true);
                      }}>
                      {state ? '입력 취소' : '입력 완료'}
                    </BlueButton>
                  ) : state ? (
                    <></>
                  ) : (
                    <BlueButton
                      variant="contained"
                      onClick={() => {
                        setConfirmOpen(true);
                      }}>
                      입력 완료
                    </BlueButton>
                  )
                ) : (
                  <></>
                )}

                <SelectSpaceDialog />
                <SelectBuildingDialog />
                <ConfirmButtonDialog />
                {(userType === 'normal' && state && step === 5) || (userType === 'expert' && state && step === 6) || (userType === 'admin' && state && step === 6) ? PrintReport() : <></>}
                {/* 
                {userType === "normal" && step === 5 ? PrintReport(pressureType) : <></>}
                {step === 5 || step === 6 ? PrintReport(pressureType) : <></>} */}
              </Stack>
            </CardContent>
          </Card>
        </Scrollbar>
      </Paper>
    </>
  );
}

export default Main;
