import { Button, CardContent, Paper, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import * as React from 'react';
import { UserSelectModal } from '../Modal/UserSelectModal';
import { LoginModal } from '../Modal/LoginModal';
import { SignupModal } from '../Modal/SignupModal';
import { useModalToggle } from '../Modal/Modal';
import { Navigate, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { IndexCard } from '../components/const';
import Scrollbar from '../components/Scrollbar';
import image from '../media/Background.png';
import BGnoLine from '../media/BGnoLine.png';
import moment from 'moment';
import axios from 'axios';
import emailjs from '@emailjs/browser';

import { GetMeasureCount, Test, GetVerificationKey, ExtensionPassword } from '../data/Data';
import SetAuthorizationToken from '../utils/SetAuthorizationToken';

const Footer = styled(Stack)`
  flex: 1;
  height: 72px;
  background-color: #383838;
  border: none;
`;

const PrivacyPolicyButton = styled(Button)`
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
`;

const Copyright = styled(Typography)`
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
`;

const LoginButton = styled(Button)`
  background: #3d4abc;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  padding: 8px 100px;
  opacity: 1 !important;
  &:hover,
  &:active {
    color: #ffffff;
    background-color: #3d4abc;
  }
`;

function IndexPage(props) {
  const { on, open, close } = useModalToggle();
  const [modalScreen, setModalScreen] = React.useState(0);
  const { onSignup, openSignup, closeSignup } = useModalToggle();
  const [measureCount, setMeasureCount] = React.useState(0);
  const [isUpdated, setIsUpdated] = React.useState(true);
  const [validToken, setValidToken] = React.useState(false);

  let navigate = useNavigate();

  React.useEffect(() => {
    var accessToken = axios.defaults.headers.common['AccessToken'];
    if (accessToken === undefined) {
      setValidToken(false);
    } else {
      setValidToken(true);
    }

    GetMeasureCount()
      .then((res) => {
        setMeasureCount(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const HandleLoginSucceed = (userType, userId) => {
    props.setValidToken(true);
    let path = '/main/1/';
    navigate(path, {
      state: { userType: userType, userId: userId, title: '' }, // 테스트를 위한 사용자유형 및 사용자ID 추가 - 이덕현
    });
  };

  const handleSignup = () => {
    console.log('handleSignup');
    openSignup();
  };

  const today = () => {
    return moment().format('YYYY-MM-DD');
  };

  const OnClickPrivacyPolicy = () => {
    window.open('./privacy_policy.html', '_blank');
  };

  const handleLoginModal = () => {
    var accessToken = axios.defaults.headers.common['AccessToken'];
    var userId = sessionStorage.getItem('userId');
    var userType = sessionStorage.getItem('userType');

    if (accessToken === undefined) {
      open();
    } else {
      let path = '/main/1/';
      navigate(path, {
        state: { userType: userType, userId: userId, title: '' },
      });
    }
  };

  return (
    <>
      <Helmet title="Airtightness DB" />
      <Paper
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          height: 'calc(100vh - 72px)',
        }}>
        <Scrollbar>
          <IndexCard
            style={{
              backgroundImage: `url(${BGnoLine})`,
              backgroundSize: 'cover',
            }}>
            <CardContent>
              <Stack direction="row" sx={{ mt: 2 }}>
                <img src={require('../media/logo_new_title.png')} alt="로고" style={{ width: 200 }} />
              </Stack>
              <Stack direction="row" justifyContent="flex-end" sx={{ pt: 26, pr: 9 }}>
                <p style={{ fontSize: '20px', fontWeight: '600' }}>Ver. 1.0</p>
              </Stack>
              <Stack direction="row" justifyContent="flex-end" sx={{ pt: 22, pr: 6 }}>
                <LoginButton onClick={handleLoginModal}>{validToken ? '접속' : '로그인'}</LoginButton>
                <LoginModal
                  on={on}
                  onClose={() => {
                    setModalScreen(0);
                    close();
                  }}
                  modalScreen={modalScreen}
                  setModalScreen={setModalScreen}
                  onSucceed={async (userType, userId) => {
                    await HandleLoginSucceed(userType, userId);
                  }}
                />
              </Stack>
              <Stack direction="row" justifyContent="flex-end" sx={{ pt: 14 }}>
                <p style={{ fontSize: '16px', fontWeight: '200' }}>({today()}, 현재) 건물기밀정보플랫폼에</p>
                <p style={{ fontSize: '20px', fontWeight: '600', color: 'Red', marginTop: '-5px' }}>'{measureCount}'</p>
                <p style={{ fontSize: '16px', fontWeight: '200' }}>개의 데이터가 등록되어 있습니다.</p>
              </Stack>
            </CardContent>
          </IndexCard>
        </Scrollbar>
        <Footer justifyContent="center">
          <Stack justifyContent="center" direction="row" spacing={4}>
            <PrivacyPolicyButton style={{ height: '35px', verticalAlign: 'middle' }} onClick={OnClickPrivacyPolicy}>
              개인정보처리방침
            </PrivacyPolicyButton>
            <Copyright
              align="center"
              style={{
                verticalAlign: 'middle',
                height: '35px',
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '10px',
                paddingRight: '10px',
              }}>
              © BESL 2023 all rights reserved.
            </Copyright>
          </Stack>
        </Footer>
      </Paper>
    </>
  );
}

export default IndexPage;
