import axios from 'axios';

// 운영
const baseURL = '/api/';
const baseURL_Besl = baseURL + 'Besl/';
const baseURL_User = baseURL + 'Users/';

// 운영OLD
//const baseURL = 'https://besl.brdg.kr/api/';
//const baseURL_Besl = baseURL + 'Besl/';
//const baseURL_User = baseURL + 'Users/';

// 로컬
//const baseURL_Besl = '/Besl/';
//const baseURL_User = '/Users/';

export const Test = () => {
  try {
    return axios.get(baseURL_Besl + 'test');
  } catch (error) {
    console.error(error);
  }
};

export const GetBuildings = (user_id) => {
  try {
    return axios.post(baseURL_Besl + 'buildings', null, {
      params: {
        user_id,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const AddBuilding = (buildingModel) => {
  try {
    let formData = new FormData();
    Object.keys(buildingModel).forEach(function (key) {
      formData.append(key, buildingModel[key]);
    });
    return axios.post(baseURL_Besl + 'add-building', formData);
  } catch (error) {
    console.error(error);
  }
};

export const EditBuilding = (buildingModel) => {
  try {
    let formData = new FormData();
    Object.keys(buildingModel).forEach(function (key) {
      formData.append(key, buildingModel[key]);
    });
    return axios.post(baseURL_Besl + 'edit-building', formData);
  } catch (error) {
    console.error(error);
  }
};

export const DeleteBuilding = (seq) => {
  try {
    return axios.post(baseURL_Besl + 'del-building', null, {
      params: {
        seq,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const GetSpaces = (building_id) => {
  try {
    return axios.post(baseURL_Besl + 'spaces', null, {
      params: {
        building_id,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const AddSpace = (spaceModel) => {
  try {
    let formData = new FormData();
    Object.keys(spaceModel).forEach(function (key) {
      formData.append(key, spaceModel[key]);
    });
    return axios.post(baseURL_Besl + 'add-space', formData);
  } catch (error) {
    console.error(error);
  }
};

export const EditSpace = (spaceModel) => {
  try {
    let formData = new FormData();
    Object.keys(spaceModel).forEach(function (key) {
      formData.append(key, spaceModel[key]);
    });
    return axios.post(baseURL_Besl + 'edit-space', formData);
  } catch (error) {
    console.error(error);
  }
};

export const DeleteSpace = (seq) => {
  try {
    return axios.post(baseURL_Besl + 'del-space', null, {
      params: {
        seq,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const GetMeasureCount = () => {
  try {
    return axios.get(baseURL_Besl + 'get-measure-count');
  } catch (error) {
    console.error(error);
  }
};

export const SetCurrentBuilding = (seq, user_id) => {
  try {
    return axios.post(baseURL_Besl + 'set-current-building', null, {
      params: {
        seq,
        user_id,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const GetCurrentBuildingId = (user_id) => {
  try {
    return axios.post(baseURL_Besl + 'current-building-id', null, {
      params: {
        user_id,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const GetCurrentBuilding = (user_id) => {
  try {
    return axios.post(baseURL_Besl + 'current-building', null, {
      params: {
        user_id,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const GetCurrentSpaceId = (building_id) => {
  try {
    return axios.post(baseURL_Besl + 'current-space-id', null, {
      params: {
        building_id,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const GetCurrentSpace = (building_id) => {
  try {
    return axios.post(baseURL_Besl + 'current-space', null, {
      params: {
        building_id,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const SetCurrentSpace = (seq, building_id) => {
  try {
    return axios.post(baseURL_Besl + 'set-current-space', null, {
      params: {
        seq,
        building_id,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const SetCurrentEquipment = (set_type, user_id, space_id, equipment_id) => {
  try {
    return axios.post(baseURL_Besl + 'set-equipment', null, {
      params: {
        set_type,
        user_id,
        space_id,
        equipment_id,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const GetMeasureEnvCondition = (space_id) => {
  try {
    return axios.post(baseURL_Besl + 'measure-envcondition', null, {
      params: {
        space_id,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const GetMeasureAirCondition = (space_id) => {
  try {
    return axios.post(baseURL_Besl + 'measure-airtightness', null, {
      params: {
        space_id,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const EditMeasureAirCondition = (airModel) => {
  try {
    let formData = new FormData();
    formData.append('data', JSON.stringify(airModel));
    return axios.post(baseURL_Besl + 'edit-measure-airtightness', formData);
  } catch (error) {
    console.error(error);
  }
};

export const EditMeasureEnvCondition = (envModel) => {
  try {
    let formData = new FormData();
    formData.append('data', JSON.stringify(envModel));
    return axios.post(baseURL_Besl + 'edit-measure-envcondition', formData);
  } catch (error) {
    console.error(error);
  }
};

export const GetEquipments = (user_id, space_id) => {
  try {
    return axios.post(baseURL_Besl + 'equipments', null, {
      params: {
        user_id,
        space_id,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const EditEquipments = (user_id, space_id) => {
  try {
    return axios.post(baseURL_Besl + 'equipments', null, {
      params: {
        user_id,
        space_id,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const AddEquipment = (equipmentModel) => {
  try {
    let formData = new FormData();
    Object.keys(equipmentModel).forEach(function (key) {
      formData.append(key, equipmentModel[key]);
    });
    return axios.post(baseURL_Besl + 'add-equipment', formData);
  } catch (error) {
    console.error(error);
  }
};

export const EditEquipment = (equipmentModel) => {
  try {
    let formData = new FormData();
    Object.keys(equipmentModel).forEach(function (key) {
      formData.append(key, equipmentModel[key]);
    });
    return axios.post(baseURL_Besl + 'edit-equipment', formData);
  } catch (error) {
    console.error(error);
  }
};

export const DeleteEquipment = (equipment_id) => {
  try {
    return axios.post(baseURL_Besl + 'del-equipment', null, {
      params: {
        equipment_id,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const SetConfirmed = (space_id) => {
  try {
    return axios.post(baseURL_Besl + 'set-confirmed', null, {
      params: {
        space_id,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const SetPreviewNote = (space_id, note) => {
  return axios.post(baseURL_Besl + 'set-preview-note', null, {
    params: {
      space_id: space_id,
      note: note,
    },
  });
};

export const GetAirtightnessPerformCriteria = (user_type) => {
  return axios.post(baseURL_Besl + 'airtightness-perform-criteria', null, {
    params: {
      user_type: user_type,
    },
  });
};

export const AddAirtightnessPerformCriteria = (group, color, title, value) => {
  return axios.post(baseURL_Besl + 'add-airtightness-perform-criteria', null, {
    params: {
      group: group,
      color: color,
      title: title,
      value: value,
    },
  });
};

export const SetAirtightnessPerformCriteria = (criteriaModel) => {
  try {
    let formData = new FormData();
    Object.keys(criteriaModel).forEach(function (key) {
      formData.append(key, criteriaModel[key]);
    });
    return axios.post(baseURL_Besl + 'set-airtightness-perform-criteria', formData);
  } catch (error) {
    console.error(error);
  }
};

export const DeleteAirtightnessPerformCriteria = (seq) => {
  return axios.post(baseURL_Besl + 'del-airtightness-perform-criteria', null, {
    params: {
      seq: seq,
    },
  });
};

export const GetAirtightnessPerformCriteriaChart = (user_type) => {
  return axios.post(baseURL_Besl + 'airtightness-perform-criteria-chart', null, {
    params: {
      user_type: user_type,
    },
  });
};

export const GetAvgAirtightness = (use_type, space_id) => {
  return axios.post(baseURL_Besl + 'avg-measure-airtightness', null, {
    params: {
      use_type: use_type,
      space_id: space_id,
    },
  });
};

export const GetAirtightnessPercentile = (use_type) => {
  return axios.post(baseURL_Besl + 'get-airtightness-percentile', null, {
    params: {
      use_type: use_type,
    },
  });
};

export const GetAirtightnessPercentiles = (use_types, yearStart, yearEnd, space_id) => {
  return axios.post(baseURL_Besl + 'get-airtightness-percentiles', null, {
    params: {
      values: use_types,
      yearStart: yearStart,
      yearEnd: yearEnd,
      space_id: space_id,
    },
  });
};

export const UploadExcelFile = (file) => {
  try {
    let formData = new FormData();
    formData.append('File', file);
    return axios.post(baseURL_Besl + 'upload-excel', formData);
  } catch (error) {
    console.error(error);
  }
};

export const CopyBuilding = (seq, withSpaceCopy) => {
  return axios.post(baseURL_Besl + 'copy-building', null, {
    params: {
      seq: seq,
      withSpaceCopy: withSpaceCopy,
    },
  });
};

export const CopySpace = (seq, building_id) => {
  return axios.post(baseURL_Besl + 'copy-space', null, {
    params: {
      seq: seq,
      building_id: building_id,
    },
  });
};

//preventDuplicate.split(",")
export const GetAch50CompareChart = () => {
  return axios.post(baseURL_Besl + 'get-ach50-compare-chart', null, {});
};

export const SetAch50CompareChart = (chart_number, uses, start_year, end_year) => {
  return axios.post(baseURL_Besl + 'set-ach50-compare-chart', null, {
    params: {
      chart_number: chart_number,
      uses: uses,
      start_year: start_year,
      end_year: end_year,
    },
  });
};

export const Signin = (id, password) => {
  let ctlName = 'Users/';
  let formData = new FormData();
  formData.append('email', id);
  formData.append('password', password);
  return axios.put(baseURL_User + 'signin', formData);
};

export const Signup = (params) => {
  let ctlName = 'Users/';
  let formData = new FormData();
  Object.keys(params).forEach(function (key) {
    formData.append(key, params[key]);
  });
  return axios.post(baseURL_User, formData);
};

export const Renewal = () => {
  return axios.get(baseURL_User + 'token');
};

export const GetVerificationKey = (email) => {
  return axios.get(baseURL_User + `verification?email=${email}`, {
    headers: {
      Authorization: 'Bearer ' + axios.defaults.headers.common['AccessToken'],
    },
  });
};

export const UserChange = (params) => {
  let formData = new FormData();
  Object.keys(params).forEach(function (key) {
    formData.append(key, params[key]);
  });
  return axios.put(baseURL_User, formData);
};

export const GetUser = (email) => {
  return axios.get(baseURL_User + `?email=${email}`);
};

export const ExtensionPassword = (email) => {
  let formData = new FormData();
  formData.append('email', email);
  return axios.put(baseURL_User + 'password', formData);
};

export const UserWithdrawal = (email) => {
  return axios.delete(baseURL_User + `withdrawal?email=${email}`);
};

export const GetExpertNumber = () => {
  try {
    return axios.get(baseURL_Besl + 'get-expert-number');
  } catch (error) {
    console.error(error);
  }
};
