import * as React from 'react';

import dayjs from 'dayjs';
import moment from 'moment';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

import {
  LableText,
  LableTextCenter,
  LableDescriptionTextCenter,
  LableDescriptionTextLeft,
  LableDescriptionBoldTextCenter,
  LableDescriptionBoldTextLeft,
  ModalCard,
  SpaceHeadExpert,
  BorderTable,
} from '../../components/const';
import { AddButton, BlueButton, DarkGreyButton, GreyButton } from '../../components/button';
import { CardHeader, CenterModal, ModalHeadText, ModalDatePicker, ModalInput, ModalLableText, ModalTextField, useModalToggle } from '../../Modal/Modal';
import { Close } from '@mui/icons-material';
import { CardContent, Grid, IconButton, InputAdornment, Stack, Dialog, DialogActions, DialogContent, DialogTitle, Button, Select, FormControl, TableCell, TableHead, TableRow } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SpaceTable } from '../../tables/SpaceTable';
import { GetSpaces, AddSpace, GetCurrentBuilding, CopySpace } from '../../data/Data';
import _ from 'lodash';

function StepTwo(props) {
  const [spaces, setSpaces] = React.useState([]);
  const [isInit, setIsInit] = React.useState(true);
  const [isUpdated, setIsUpdated] = React.useState(true);
  const [currentBuilding, setCurrentBuiliding] = React.useState({});
  const [currentSpace, setCurrentSpace] = React.useState({});

  const loadData = () => {
    GetCurrentBuilding(props.userId).then((building) => {
      GetSpaces(building.data[0].seq).then((spaces) => {
        setCurrentBuiliding(building.data[0]);
        setSpaces(spaces.data);
        setIsUpdated(false);
        setIsInit(false);

        spaces.data.find((space) => {
          if (space.is_current === true) {
            setCurrentSpace(space);
            props.setCurrentSpace(space);

            let buildingName = building.data[0].name;
            let spaceDongName = space.name_dong;
            let spaceDongNumber = space.name_number;
            if (buildingName === null || buildingName === undefined || buildingName === '') {
              buildingName = '-';
            }
            if (spaceDongName === null || spaceDongName === undefined || spaceDongName === '') {
              spaceDongName = '-';
            }
            if (spaceDongNumber === null || spaceDongNumber === undefined || spaceDongNumber === '') {
              spaceDongNumber = '-';
            }

            props.setTitle(buildingName + ' ' + spaceDongName + ' ' + spaceDongNumber);
            props.setState(space.state);
            return space;
          }
        });
      });
    });
  };
  React.useEffect(() => {
    //처음 시작할때 currentBuildingId 설정
    if (isUpdated && isInit) {
      loadData();
    }
    //측정 정보만 업데이트 할때
    if (isUpdated && !isInit) {
      GetSpaces(currentBuilding.seq).then((spaces) => {
        setSpaces(spaces.data);
        setIsUpdated(false);
        spaces.data.find((space) => {
          if (space.is_current === true) {
            setCurrentSpace(space);
            props.setCurrentSpace(space);

            let buildingName = currentBuilding.name;
            let spaceDongName = space.name_dong;
            let spaceDongNumber = space.name_number;
            if (buildingName === null || buildingName === undefined || buildingName === '') {
              buildingName = '-';
            }
            if (spaceDongName === null || spaceDongName === undefined || spaceDongName === '') {
              spaceDongName = '-';
            }
            if (spaceDongNumber === null || spaceDongNumber === undefined || spaceDongNumber === '') {
              spaceDongNumber = '-';
            }

            props.setTitle(buildingName + ' ' + spaceDongName + ' ' + spaceDongNumber);
            return space;
          }
        });
      });
    }
  }, [isUpdated]);

  return (
    <Stack sx={{ mt: 1, ml: 10, mr: 10 }} style={{ height: 510 }}>
      <Stack direction="row" justifyContent="center">
        <Stack direction="column" justifyContent="center">
          <LableTextCenter sx={{ paddingBottom: 1 }}>2. 측정 공간 정보</LableTextCenter>
          <LableDescriptionTextLeft sx={{ paddingBottom: 1 }}>대상 건물에서 측정하는 공간에 대한 정보를 입력합니다.</LableDescriptionTextLeft>
          <LableDescriptionTextLeft sx={{ paddingBottom: 1 }}>측정 공간 정보는 측정 감독자, 측정 목적, 측정 표준, 측정 공간에 대한 크기 정보를 입력하게 됩니다.</LableDescriptionTextLeft>
          <LableDescriptionTextLeft sx={{ paddingBottom: 2 }}># 새 측정공간을 추가하거나 측정 리스트를 복사하여 입력할 수 있으며 입력 완료 후에는 수정이 불가능 합니다.</LableDescriptionTextLeft>
        </Stack>
      </Stack>
      <Stack sx={{ pb: 2, paddingBottom: 2 }} direction="row" justifyContent="space-between">
        <OpenModalButton buildingId={currentBuilding.seq} setIsUpdated={setIsUpdated} spaces={spaces} setSpaces={setSpaces} currentSpace={currentSpace} />
      </Stack>
      <BorderTable style={{ paddingRight: '11px' }}>
        <TableHead>
          <TableRow>
            {SpaceHeadExpert.map((it) => (
              <TableCell key={it.id} align={it.alignment} width={it.width}>
                {it.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </BorderTable>
      <Stack style={{ overflow: 'auto' }}>
        <SpaceTable
          userType={props.userType}
          spaces={spaces}
          setSpaces={setSpaces}
          currentBuilding={currentBuilding}
          buildingId={currentBuilding.seq}
          currentSpace={currentSpace}
          setTitle={props.setTitle}
          setIsUpdated={setIsUpdated}
          setState={props.setState}
          isResult={false}
        />
      </Stack>
    </Stack>
  );
}

function OpenModalButton(props) {
  const { on, open, close } = useModalToggle();
  const [date, setDate] = React.useState(moment().format('YYYY-MM-DD'));
  const [newSpace, setNewSpace] = React.useState(CreateSpaceData(props.buildingId));
  const [copyOpen, setCopyOpen] = React.useState(false);
  const [measurePoint, setMeasurePoint] = React.useState('준공 전');
  const [measurePurpose, setMeasurePurpose] = React.useState('법규, 제도, 기준, 인증');
  const [measureStandard, setMeasureStandard] = React.useState('ISO 9972(KS L ISO 9972)');
  const [floorArea, setFloorArea] = React.useState('');
  const [floorHeight, setFloorHeight] = React.useState('');
  const [volume, setVolume] = React.useState('');
  const [outsideArea, setOutsideArea] = React.useState('');
  const [confirmOpen, setConfirmOpen] = React.useState(false);

  const selectedImageRef = React.useRef(null);
  const [selectedImage, setSelectedImage] = React.useState();
  const [selectedFile, setSelectedFile] = React.useState({});

  const isNumber = (number) => {
    const regex = /^(\d+)?(\.\d*)?$/;
    return number !== '' && regex.test(number);
  };

  const nameDongChange = (e) => {
    newSpace.name_dong = e.target.value;
  };
  const nameNumberChange = (e) => {
    newSpace.name_number = e.target.value;
  };
  const supervisorChange = (e) => {
    newSpace.supervisor = e.target.value;
  };
  // const measurePurposeChange = (e) => {
  //   newSpace.measure_purpose = e.target.value;
  // };
  const measureStandardChange = (e) => {
    newSpace.measure_standard = e.target.value;
  };
  const floorAreaChange = (e) => {
    if (isNumber(e.target.value)) {
      newSpace.floor_area = e.target.value;
      setFloorArea(e.target.value);
    }
  };
  const floorHeightChange = (e) => {
    if (isNumber(e.target.value)) {
      newSpace.floor_height = e.target.value;
      setFloorHeight(e.target.value);
    }
  };
  const volumeChange = (e) => {
    if (isNumber(e.target.value)) {
      newSpace.volume = e.target.value;
      setVolume(e.target.value);
    }
  };
  const outsideAreaChange = (e) => {
    if (isNumber(e.target.value)) {
      newSpace.outside_area = e.target.value;
      setOutsideArea(e.target.value);
    }
  };
  const handleSaveButtonClick = (spaces) => {
    if (newSpace.supervisor === '' || measureStandard === '' || newSpace.volume === '' || newSpace.outside_area === '') {
      setConfirmOpen(true);
    } else {
      newSpace.building_id = props.buildingId;
      newSpace.measure_date = dayjs(date).format('YYYY-MM-DD');

      newSpace.floor_area = newSpace.floor_area ?? '';
      newSpace.floor_height = newSpace.floor_height ?? '';
      newSpace.volume = newSpace.volume ?? '';
      newSpace.outside_area = newSpace.outside_area ?? '';

      newSpace.measure_point = measurePoint;
      newSpace.measure_purpose = measurePurpose;
      newSpace.measure_standard = measureStandard;
      newSpace.file = selectedFile;
      newSpace.filename = selectedFile.name;

      AddSpace(newSpace).then((res) => {
        setSelectedImage('');
        setSelectedFile({});
        setMeasurePoint('준공 전');
        setMeasurePurpose('법규, 제도, 기준, 인증');
        setMeasureStandard('ISO 9972(KS L ISO 9972)');
        setFloorArea('');
        setFloorHeight('');
        setVolume('');
        setOutsideArea('');

        props.setIsUpdated(true);
        close();
      });
    }
  };

  const handleCopyDialogOpen = () => {
    if (props.currentSpace.constructor === Object && _.isEmpty(props.currentSpace)) {
      return;
    }
    setCopyOpen(true);
  };

  const handleCopyClick = (seq, building_id) => {
    CopySpace(seq, building_id).then((res) => {
      props.setIsUpdated(true);
      setCopyOpen(false);
    });
  };

  const handleSelectImageClick = (e) => {
    selectedImageRef.current.click();
  };

  const handleSelectedImageChange = (e) => {
    let url = URL.createObjectURL(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    setSelectedImage(url);
  };

  const deleteImage = () => {
    setSelectedFile({});
    setSelectedImage();
  };

  return (
    <>
      <Stack direction="row" spacing={2}>
        <AddButton startIcon={<img src={require('../../media/add.png')} style={{ width: 18, height: 17, marginBottom: 3 }} alt="add building img" />} onClick={open}>
          새 측정공간 추가
        </AddButton>
        <AddButton
          sx={{ width: 80 }}
          onClick={() => {
            // buildings 가 있고 선택한 대상이 있을 때 복사버튼 눌림
            if (props.spaces.length > 0 && props.currentSpace !== null && props.currentSpace !== undefined) {
              handleCopyDialogOpen();
            }
          }}>
          복사
        </AddButton>
        <Dialog
          open={copyOpen}
          onClose={() => {
            setCopyOpen(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{'선택한 측정정보를 복사 하시겠습니까??'}</DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleCopyClick(props.currentSpace.seq, props.buildingId);
              }}>
              예
            </Button>
            <Button
              onClick={() => {
                setCopyOpen(false);
              }}>
              취소
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>

      <CenterModal open={on} onClose={close}>
        <ModalCard>
          <CardHeader direction="row" justifyContent="space-between">
            <ModalHeadText sx={{ pt: 1 }}>측정공간정보 입력</ModalHeadText>
            <IconButton sx={{ mr: 5 }} size="small" onClick={close}>
              <Close />
            </IconButton>
          </CardHeader>
          <CardContent style={{ padding: 20 }}>
            <Stack sx={{ p: 4, pr: 1 }}>
              <Grid container spacing={3} sx={{ backgroundColor: '#F8FAFB' }}>
                <Grid item xs={6}>
                  <Stack direction="row" spacing={3}>
                    <Stack sx={{ flex: 1 }}>
                      <ModalLableText>건물명</ModalLableText>
                      <ModalInput onChange={nameDongChange} endAdornment={<InputAdornment position="end">동</InputAdornment>} size="small"></ModalInput>
                    </Stack>
                    <Stack sx={{ flex: 1 }}>
                      <ModalLableText>측정공간</ModalLableText>
                      <ModalInput onChange={nameNumberChange} endAdornment={<InputAdornment position="end">호</InputAdornment>} size="small"></ModalInput>
                    </Stack>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <img src={require('../../media/Vector.png')} style={{ width: 14, height: 10 }} alt="check img" />
                    <ModalLableText>측정 감독자</ModalLableText>
                  </Stack>
                  <ModalInput size="small" onChange={supervisorChange}></ModalInput>
                  <ModalLableText>측정 목적</ModalLableText>
                  <Stack sx={{ flex: 1, mb: 2 }}>
                    <FormControl
                      fullWidth
                      style={{
                        background: '#FFFFFF',
                        borderRadius: 4,
                        flex: 1,
                      }}>
                      <Select
                        defaultValue={'법규, 제도, 기준, 인증'}
                        onChange={(e) => {
                          setMeasurePurpose(e.target.value);
                        }}
                        size="small">
                        <MenuItem value={'법규, 제도, 기준, 인증'}>법규, 제도, 기준, 인증</MenuItem>
                        <MenuItem value={'건축물 시공 품질 시험'}>건축물 시공 품질 시험</MenuItem>
                        <MenuItem value={'연구 및 업무 데이터'}>연구 및 업무 데이터</MenuItem>
                        <MenuItem value={'기타'}>기타</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <img src={require('../../media/Vector.png')} style={{ width: 14, height: 10 }} alt="check img" />
                    <ModalLableText>측정 표준</ModalLableText>
                  </Stack>
                  <Stack sx={{ flex: 1, mb: 2 }}>
                    <FormControl
                      fullWidth
                      style={{
                        background: '#FFFFFF',
                        borderRadius: 4,
                        flex: 1,
                      }}>
                      <Select
                        defaultValue={'ISO 9972(KS L ISO 9972)'}
                        onChange={(e) => {
                          setMeasureStandard(e.target.value);
                        }}
                        size="small">
                        <MenuItem value={'ISO 9972(KS L ISO 9972)'}>ISO 9972(KS L ISO 9972)</MenuItem>
                        <MenuItem value={'EN13829'}>EN13829</MenuItem>
                        <MenuItem value={'ASTM E779'}>ASTM E779</MenuItem>
                        <MenuItem value={'기타'}>기타</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                  <ModalLableText>측정 시점</ModalLableText>
                  <Stack sx={{ flex: 1, mb: 2 }}>
                    <FormControl
                      fullWidth
                      style={{
                        background: '#FFFFFF',
                        borderRadius: 4,
                        flex: 1,
                      }}>
                      <Select
                        defaultValue={'준공 전'}
                        onChange={(e) => {
                          setMeasurePoint(e.target.value);
                        }}
                        size="small">
                        <MenuItem value={'준공 전'}>준공 전</MenuItem>
                        <MenuItem value={'준공 후'}>준공 후</MenuItem>
                        <MenuItem value={'실사용시'}>실사용시</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                  <ModalLableText>측정 일자</ModalLableText>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <ModalDatePicker
                      value={date}
                      inputFormat={'YYYY-MM-DD'}
                      onChange={(newDate) => {
                        setDate(newDate);
                      }}
                      renderInput={(params) => <ModalTextField size="small" {...params} style={{ paddingBottom: 0 }} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6} sx={{ pr: 3 }}>
                  <ModalLableText>측정 대상공간의 총 바닥면적</ModalLableText>
                  <ModalInput value={floorArea} onChange={floorAreaChange} endAdornment={<InputAdornment position="end">㎡</InputAdornment>} size="small"></ModalInput>
                  <ModalLableText>측정 대상공간의 총 높이</ModalLableText>
                  <ModalInput value={floorHeight} onChange={floorHeightChange} endAdornment={<InputAdornment position="end">m</InputAdornment>} size="small"></ModalInput>
                  <Stack direction="row" spacing={1}>
                    <img src={require('../../media/Vector.png')} style={{ width: 14, height: 10 }} alt="check img" />
                    <ModalLableText>측정 대상공간의 총 체적</ModalLableText>
                  </Stack>
                  <ModalInput value={volume} onChange={volumeChange} endAdornment={<InputAdornment position="end">㎥</InputAdornment>} size="small"></ModalInput>
                  <Stack direction="row" spacing={1}>
                    <img src={require('../../media/Vector.png')} style={{ width: 14, height: 10 }} alt="check img" />
                    <ModalLableText>측정 대상공간의 총 외피면적</ModalLableText>
                  </Stack>
                  <ModalInput value={outsideArea} onChange={outsideAreaChange} endAdornment={<InputAdornment position="end">㎡</InputAdornment>} size="small"></ModalInput>
                  <Stack direction="row" justifyContent="space-between">
                    <ModalLableText>측정 사진</ModalLableText>
                    <Stack direction="row" spacing={1}>
                      <DarkGreyButton
                        onClick={(e) => {
                          handleSelectImageClick(e);
                        }}>
                        사진 선택
                      </DarkGreyButton>
                      <DarkGreyButton
                        onClick={() => {
                          deleteImage();
                        }}>
                        삭제
                      </DarkGreyButton>
                      <input
                        type="file"
                        ref={selectedImageRef}
                        style={{ display: 'none' }}
                        onChange={(e) => {
                          handleSelectedImageChange(e);
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Box
                    style={{
                      border: '1px dashed #DDDDDD',
                      minHeight: 124,
                    }}>
                    <img src={selectedImage} />
                  </Box>
                </Grid>
              </Grid>
            </Stack>
            <Stack direction="row" spacing={2} justifyContent="center">
              <BlueButton
                onClick={() => {
                  handleSaveButtonClick(props.spaces);
                }}>
                저장
              </BlueButton>
              <Dialog
                open={confirmOpen}
                onClose={() => {
                  setConfirmOpen(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{'필수 값이 입력되지 않았습니다.'}</DialogTitle>
                <DialogContent></DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setConfirmOpen(false);
                    }}>
                    확인
                  </Button>
                </DialogActions>
              </Dialog>
              <GreyButton onClick={close}>닫기</GreyButton>
            </Stack>
          </CardContent>
        </ModalCard>
      </CenterModal>
    </>
  );
}

function CreateSpaceData(
  building_id = '',
  name_dong = '',
  name_number = '',
  photo = '',
  file = null,
  filename = '',
  supervisor = '',
  measure_purpose = '',
  measure_date = '',
  measure_standard = '',
  floor_area = '',
  floor_height = '',
  volume = '',
  outside_area = '',
  measure_point = '',
  state = false
) {
  return {
    building_id: building_id,
    name_dong: name_dong,
    name_number: name_number,
    photo: photo,
    file: file,
    filename: filename,
    supervisor: supervisor,
    measure_purpose: measure_purpose,
    measure_date: measure_date,
    measure_standard: measure_standard,
    floor_area: floor_area,
    floor_height: floor_height,
    volume: volume,
    outside_area: outside_area,
    measure_point: measure_point,
    state: state,
  };
}

export default StepTwo;
