import { toImage } from 'plotly.js';
import React, { useRef } from 'react';
import Plot from 'react-plotly.js';
import { GetAirtightnessPercentile, GetAirtightnessPercentiles } from '../data/Data';
import randomColor from 'randomcolor';
import dayjs from 'dayjs';

function AchBoxPlot(props) {
  const [chartData, setChartData] = React.useState([]);
  const [chartYData, setChartYData] = React.useState([]);
  const [maxY, setMaxY] = React.useState(0);
  //////

  //const colors = ["#b80000", "#f06292", "#1976d2", "#388e3c", "#ffa000"];

  const colors = [
    {
      id: 1,
      name: '공동주택',
      color: '#f7a996',
    },
    {
      id: 2,
      name: '단독주택',
      color: '#f2e394',
    },
    {
      id: 3,
      name: '교육시설',
      color: '#a2d5c6',
    },
    {
      id: 4,
      name: '업무시설',
      color: '#92b6d5',
    },
    {
      id: 5,
      name: '제1종 근린생활시설',
      color: '#c0a5c9',
    },
    {
      id: 6,
      name: '제2종 근린생활시설',
      color: '#e7a6b8',
    },
    {
      id: 7,
      name: '문화 및 집회시설',
      color: '#bfa387',
    },
    {
      id: 8,
      name: '노유자시설',
      color: '#d3d3d3',
    },
    {
      id: 9,
      name: '기타',
      color: '#8de8d6',
    },
    {
      id: 10,
      name: '측정 값',
      color: '#e95c4b',
    },
  ];

  // const colors = [
  //   {
  //     id: 1,
  //     name: '공동주택',
  //     color: '#b80000',
  //   },
  //   {
  //     id: 2,
  //     name: '단독주택',
  //     color: '#f06292',
  //   },
  //   {
  //     id: 3,
  //     name: '교육시설',
  //     color: '#1976d2',
  //   },
  //   {
  //     id: 4,
  //     name: '업무시설',
  //     color: '#388e3c',
  //   },
  //   {
  //     id: 5,
  //     name: '제1종 근린생활시설',
  //     color: '#388e3c',
  //   },
  //   {
  //     id: 6,
  //     name: '제2종 근린생활시설',
  //     color: '#388e3c',
  //   },
  //   {
  //     id: 7,
  //     name: '문화 및 집회시설',
  //     color: '#388e3c',
  //   },
  //   {
  //     id: 8,
  //     name: '노유자시설',
  //     color: '#388e3c',
  //   },
  //   {
  //     id: 9,
  //     name: '기타',
  //     color: '#ffa000',
  //   },
  //   {
  //     id: 10,
  //     name: '측정 값',
  //     color: '#b800ff',
  //   },
  // ];

  React.useEffect(() => {
    if (props.useTypes === undefined) {
    } else {
      let data = [];
      for (let index = 0; index < props.useTypes.length; index++) {
        const useType = props.useTypes[index];
        data.push(useType.name);
      }

      let values = data.map((x) => x).join(',');

      let start = props.yearStart === null || isNaN(props.yearStart.$y) ? 1900 : props.yearStart.$y;
      let end = props.yearEnd === null || isNaN(props.yearEnd.$y) ? dayjs().$y : props.yearEnd.$y;

      let maxY = 20;
      GetAirtightnessPercentiles(values, start, end, props.space_id)
        .then((res) => {
          const data = [];
          //debugger;
          let maxY = 0;
          for (let index = 0; index < props.useTypes.length; index++) {
            const useType = props.useTypes[index];

            let ach50s = [];
            ach50s.push(
              res.data.map((data) => {
                if (data.building_type === useType.name) return data.ACH50;
              })
            );

            let temp_maxY = max(ach50s[0]);
            if (maxY < temp_maxY) {
              maxY = temp_maxY;
            }

            data.push(trace(useType.name, ach50s[0], colors.find((x) => x.name === useType.name).color));
          }

          let ach50s_current = [];
          ach50s_current.push(
            res.data.map((data) => {
              if (data.building_type === '측정 값') return data.ACH50;
            })
          );
          data.push(trace('측정 값', ach50s_current[0], colors.find((x) => x.name === '측정 값').color));

          var number = Math.round(maxY) + 2;
          setMaxY(number);

          setChartData(data);
        })
        .catch((err) => console.log(err));
    }
  }, [props.useTypes, props.yearStart, props.yearEnd]);

  function max(numbers) {
    return Math.max.apply(
      Math,
      numbers.filter(function (val) {
        return !isNaN(val);
      })
    );
  }

  const trace = (name, values, color) => {
    return {
      y: values,
      type: 'box',
      name: name,
      marker: {
        color: color,
      },
    };
  };

  const ref = useRef();
  return (
    <Plot
      divId="ach-box-plot"
      ref={ref}
      data={chartData}
      layout={{
        height: '450px',
        showlegend: false,
        yaxis: {
          title: 'ACH50',
          zeroline: false,
          dtick: 2,
          range: [0, maxY],
        },
        paper_bgcolor: '#F4F4F4',
        plot_bgcolor: '#F4F4F4',
      }}
      style={{ border: '1px solid #DDDDDD' }}
      onAfterPlot={async () => {
        const res = await toImage(ref.current.el, { format: 'svg' });
        return res;
      }}
    />
  );
}

export default AchBoxPlot;
